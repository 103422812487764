const dateFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

/**
 * Format the given ISO8601 date to be human-readable
 * @type {(date: string) => string}
 */
export const formatDate = (date) => {
  return dateFormatter.format(new Date(date));
};
