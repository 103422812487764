import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const onClose = useCallback((notificationId) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== notificationId)
    );
  }, []);

  const onOpen = useCallback(
    (content, timeout) => {
      const notificationId = uuid();

      setNotifications((notifications) => [
        ...notifications,
        {
          id: notificationId,
          content,
        },
      ]);

      if (timeout !== undefined) {
        setTimeout(() => {
          onClose(notificationId);
        }, timeout);
      }

      return notificationId;
    },
    [onClose]
  );

  const onUpdate = useCallback(
    (notificationId, content, timeout) => {
      setNotifications((notifications) =>
        notifications.map((notification) =>
          notification.id === notificationId
            ? {
                ...notification,
                content,
              }
            : notification
        )
      );

      if (timeout !== undefined) {
        setTimeout(() => {
          onClose(notificationId);
        }, timeout);
      }
    },
    [onClose]
  );

  const context = useMemo(() => {
    return {
      notifications,
      onOpen,
      onClose,
      onUpdate,
    };
  }, [notifications, onOpen, onClose, onUpdate]);
  return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>;
};

/**
 * @type {() => {
 *    notifications: { id: string, content: JSX.Element }[],
 *    onOpen: (content: JSX.Element, timeout?: number) => string,
 *    onClose: (notificationId: string) => void,
 *    onUpdate: (notificationId: string, content: JSX.Element, timeout?: number) => void,
 * }}
 */
export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const useNotifications = () => {
  return useNotificationContext().notifications;
};
