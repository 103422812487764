/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import "twin.macro";
import tw from "twin.macro";
import { Spinner } from "../shared/Spinner";
import { Transition } from "../shared/Transition";


export const Notification = ({ isFixed, onDismiss, children }) => {
  return (
    <Transition
      show
      appear
      as={Fragment}
      css={[
        isFixed && tw`fixed bottom[22px] right[25px]`
      ]}
      enter={tw`transform ease-out duration-300 transition`}
      enterFrom={tw`translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2`}
      enterTo={tw`translate-y-0 opacity-100 sm:translate-x-0`}
      leave={tw`transition ease-in duration-100`}
      leaveFrom={tw`opacity-100`}
      leaveTo={tw`opacity-0`}
    >
      <div tw="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div tw="p-4">
          <div tw="flex items-start">
            {children}
            <div tw="ml-4 flex-shrink-0 flex">
              <button
                tw="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onDismiss}
              >
                <span tw="sr-only">Close</span>
                <XIcon tw="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const NotificationContent = styled("div")(tw`ml-3 w-0 flex-1 pt-0.5`);
export const NotificationTitle = styled("p")(tw`text-sm font-medium text-gray-900`);
export const NotificationDescription = styled("p")(tw`mt-1 text-sm text-gray-500`);
export const NotificationIcon = styled("div")(tw`flex-shrink-0`);
export const NotificationSuccessIcon = () => {
  return (
    <NotificationIcon>
      <CheckCircleIcon tw="h-6 w-6 text-green-400" aria-hidden="true" />
    </NotificationIcon>
  );
};
export const NotificationSpinner = () => {
  return (
    <NotificationIcon>
      <Spinner tw="text-gray-400 h-5 w-5" />
    </NotificationIcon>
  );
};
