/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "./Button";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "./EmptyState";
import { ServerDownIllustration } from "./Illustrations";
import { SEO } from "./SEO";

/** @type {React.FC<{ error: Error }>} */
export const ErrorPage = ({ error }) => {
  const [isErrorExpanded, setIsErrorExpanded] = useState(false);
  const toggleErrorExpanded = useCallback(() => {
    setIsErrorExpanded((isErrorExpanded) => !isErrorExpanded);
  }, []);
  return (
    <>
      <SEO title="Error" />
      <EmptyState>
        <EmptyStateIllustration as={ServerDownIllustration} />
        <EmptyStateTitle>Whoops: there was an error.</EmptyStateTitle>
        <EmptyStateDescription>
          We encountered an issue while trying to load a page or perform an action. You can try
          again later or send the following technical information to the support team.
        </EmptyStateDescription>

        <pre tw="relative max-w-4xl w-full text-xs text-gray-500 font-semibold mt-4 font-mono bg-gray-200 rounded-lg px-4 py-3">
          <button
            tw="absolute top-2.5 right-4 bg-gray-300 text-gray-800 font-bold bg-opacity-75 py-0.5 px-2 rounded-md"
            onClick={toggleErrorExpanded}
          >
            {isErrorExpanded ? "View less" : "View more"}
          </button>
          {isErrorExpanded ? error.stack : error.message}
        </pre>

        <PrimaryButton as={Link} to="/" tw="mt-8">
          Go back to dashboard
        </PrimaryButton>
      </EmptyState>
    </>
  );
};
