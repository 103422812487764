// @ts-check

/** @type {(tireTest: import("./TireTestQueries").TireTest) => string} */
const formatTireTestName = (tireTest) => {
  const dto = tireTest.meta?.dto;
  const parts = [
    tireTest.developer?.name,
    dto?.value,
    dto?.casingReference,
    dto?.tireDimension,
    tireTest.tireRim || dto?.tireRim,
  ];
  return parts.map((part) => part || "n.c.").join(" ꞏ ");
};

/** @type {(tireTest: import("./TireTestQueries").TireTest | null) => string} */
export const useTireTestName = (tireTest) => {
  return tireTest ? formatTireTestName(tireTest) : null;
};
