/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { SearchBar } from "../search/SearchBar";
import { useSearch } from "../search/useSearch";
import { Badge, BadgeDot } from "../shared/Badge";
import { PrimaryButton, PrimaryLinkButton } from "../shared/Button";
import { CountBadge } from "../shared/CountBadge";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "../shared/EmptyState";
import { ErrorPage } from "../shared/ErrorPage";
import {
  ArchiveSolidIcon,
  ReplySolidIcon,
  RimOutlineIcon,
  TireOutlineIcon,
  XSolidIcon,
} from "../shared/Icons";
import { NotFoundIllustration } from "../shared/Illustrations";
import {
  List,
  ListCheckbox,
  ListItem,
  ListItemContent,
  ListItemEmptyMetaTag,
  ListItemMeta,
  ListItemMetaTag,
  ListItemRow,
  ListPagination,
} from "../shared/List";
import {
  Menu,
  MenuButton,
  MenuButtonDropdownIcon,
  MenuItemButton,
  MenuItems,
  MenuSection,
} from "../shared/Menu";
import { Page, PageContent, PageHeader, PageTitle } from "../shared/Page";
import { Panel } from "../shared/Panel";
import { Filter } from "../shared/QueryHelpers";
import { SEO } from "../shared/SEO";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import { Tabs } from "../shared/Tabs";
import { useItemSelection } from "../shared/useItemSelection";
import { useSearchQuery } from "../shared/useSearchQuery";
import { DTOCurrentStock } from "./DTOCurrentStock";
import { useDTOsQuery, useDTOsWithStockQuery, useUpsertManyDTOMutation } from "./DTOQueries";

/** @type {React.FC<{ dto: import("./DTOQueries").DTO }>} */
export const DTOListItem = ({ dto, leading }) => {
  return (
    <ListItem>
      {leading && <div tw="hidden absolute sm:(block -left-6 top-10)">{leading}</div>}
      <ListItemContent>
        <ListItemRow>
          <PrimaryLinkButton as={Link} to={`/dtos/${dto._id}`} tw="truncate">
            {dto.value}
            {dto.casingReference ? " ꞏ " + dto.casingReference : ""}
          </PrimaryLinkButton>
          {dto.status === "active" && (
            <Badge color="green">
              <BadgeDot />
              Active
            </Badge>
          )}
          {dto.status === "archived" && (
            <Badge color="gray">
              <BadgeDot />
              Archived
            </Badge>
          )}
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <TireOutlineIcon />
              <span tw="truncate">{dto.tireDimension || <ListItemEmptyMetaTag />}</span>
            </ListItemMetaTag>
            <ListItemMetaTag>
              <RimOutlineIcon />
              <span tw="truncate">{dto.tireRim || <ListItemEmptyMetaTag />}</span>
            </ListItemMetaTag>
          </ListItemMeta>
          <DTOCurrentStock dto={dto.value} />
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const DTOListItemSkeleton = () => {
  return (
    <ListItem>
      <ListItemContent>
        <ListItemRow>
          <Skeleton />
          <BadgeSkeleton />
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <TireOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <RimOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
          </ListItemMeta>
          <Skeleton />
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const DTOCountBadge = ({ status: filteredStatus }) => {
  const { status, data } = useDTOsQuery({
    status: filteredStatus,
    limit: 1,
  });
  return status === "success" ? <CountBadge>{data.totalCount}</CountBadge> : null;
};

const DTOList = () => {
  const { page = 1, status: selectedStatus, sort } = useSearchQuery();
  const { search, debouncedSearch, setSearch } = useSearch();
  const pageSize = 10;
  const { status, data, error } = useDTOsWithStockQuery({
    skip: (Number(page) - 1) * pageSize,
    limit: pageSize,
    sort,
    ...Filter.from(
      selectedStatus && { status: selectedStatus },
      debouncedSearch && {
        $or: [
          { value: Filter.regex(debouncedSearch) },
          { tireDimension: Filter.regex(debouncedSearch) },
          { tireRim: Filter.regex(debouncedSearch) },
          { casingReference: Filter.regex(debouncedSearch) },
        ],
      }
    ),
  });

  const { selectedItems, isSelected, onClearSelection, onToggle } = useItemSelection();

  const { mutateAsync: upsertManyDTO } = useUpsertManyDTOMutation();

  return (
    <>
      {status === "error" && <ErrorPage error={error} />}
      {status !== "error" && (
        <>
          <Page>
            <SEO title="DTOs" />
            <PageHeader
              title={<PageTitle>DTOs</PageTitle>}
              actions={
                <>
                  <SearchBar value={search} onChange={setSearch} />
                  <Menu css={selectedItems.length === 0 && tw`hidden`}>
                    <MenuButton>
                      More
                      <MenuButtonDropdownIcon />
                    </MenuButton>
                    <MenuItems>
                      <MenuSection>
                        <MenuItemButton
                          onClick={() => {
                            onClearSelection();
                            upsertManyDTO(
                              selectedItems.map((item) => ({
                                _id: item._id,
                                status: "archived",
                              }))
                            );
                          }}
                        >
                          <ArchiveSolidIcon />
                          Archive
                        </MenuItemButton>
                        <MenuItemButton
                          onClick={() => {
                            onClearSelection();
                            upsertManyDTO(
                              selectedItems.map((item) => ({
                                _id: item._id,
                                status: "active",
                              }))
                            );
                          }}
                        >
                          <ReplySolidIcon />
                          Restore
                        </MenuItemButton>
                      </MenuSection>
                      <MenuSection>
                        <MenuItemButton onClick={onClearSelection}>
                          <XSolidIcon />
                          Clear selection
                        </MenuItemButton>
                      </MenuSection>
                    </MenuItems>
                  </Menu>
                  <PrimaryButton as={Link} to="/dtos/new">
                    Create
                  </PrimaryButton>
                </>
              }
            />
            <PageContent>
              <Tabs
                options={[
                  { label: "All", to: "/dtos", count: <DTOCountBadge /> },
                  {
                    label: "Active",
                    to: "/dtos?status=active",
                    count: <DTOCountBadge status="active" />,
                  },
                  {
                    label: "Archived",
                    to: "/dtos?status=archived",
                    count: <DTOCountBadge status="archived" />,
                  },
                ]}
              />
              {status === "loading" && (
                <Panel tw="mt-4">
                  <List>
                    {Array.from({ length: 5 }, (_, index) => (
                      <DTOListItemSkeleton key={index} />
                    ))}
                  </List>
                </Panel>
              )}
              {status === "success" && data.totalCount === 0 && (
                <EmptyState>
                  <EmptyStateIllustration as={NotFoundIllustration} />
                  {selectedStatus || debouncedSearch ? (
                    <>
                      <EmptyStateTitle as="h3">
                        There are no DTOs matching this search
                      </EmptyStateTitle>
                      <EmptyStateDescription>
                        Try changing your search parameters or clearing your search filters
                      </EmptyStateDescription>
                      <PrimaryButton as={Link} to="/dtos" tw="mt-8">
                        Clear filters
                      </PrimaryButton>
                    </>
                  ) : (
                    <>
                      <EmptyStateTitle as="h3">You don't have any DTOs</EmptyStateTitle>
                      <EmptyStateDescription>
                        You can create your first DTO by clicking on the button below
                      </EmptyStateDescription>
                      <PrimaryButton as={Link} to="/dtos/new" tw="mt-8">
                        Create your first DTO
                      </PrimaryButton>
                    </>
                  )}
                </EmptyState>
              )}
              {status === "success" && data.totalCount !== 0 && (
                <>
                  <Panel tw="mt-4">
                    <List>
                      {data.list.map((dto) => (
                        <DTOListItem
                          key={dto._id}
                          dto={dto}
                          leading={
                            <ListCheckbox checked={isSelected(dto)} onChange={onToggle(dto)} />
                          }
                        />
                      ))}
                    </List>
                  </Panel>

                  <ListPagination
                    page={Number(page)}
                    pageSize={pageSize}
                    totalCount={data.totalCount}
                  />
                </>
              )}
            </PageContent>
          </Page>
        </>
      )}
    </>
  );
};

export default DTOList;
