import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "../shared/useDebounce";
import { useSearchQuery } from "../shared/useSearchQuery";

export const useSearch = (key = "search", delay = 300) => {
  const { [key]: search = "", ...searchQuery } = useSearchQuery();
  const history = useHistory();
  const location = useLocation();

  const setSearch = (value) => {
    history.replace(
      `${location.pathname}${qs.stringify(
        {
          ...searchQuery,
          [key]: value || undefined,
        },
        { addQueryPrefix: true }
      )}`
    );
  };

  const debouncedSearch = useDebounce(search, delay);

  return { search, debouncedSearch, setSearch };
};
