/** @jsxImportSource @emotion/react */
import { Accordion } from "@reach/accordion";
import "@reach/accordion/styles.css";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { Badge, BadgeDot } from "../shared/Badge";
import { PrimaryLinkButton } from "../shared/Button";
import { formatDate } from "../shared/formatDate";
import {
  BookmarkOutlineIcon,
  CalendarOutlineIcon,
  ChartBarOutlineIcon,
  ClipboardCheckOutlineIcon,
  LockClosedIcon,
  VehiculeIcon,
} from "../shared/Icons";
import {
  ListItem,
  ListItemContent,
  ListItemEmptyMetaTag,
  ListItemMeta,
  ListItemMetaTag,
  ListItemRow,
} from "../shared/List";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import {
  useRiskAnalysisSubTitle,
  useRiskAnalysisTitleWithoutDevName,
} from "./useRiskAnalysisName";

/** @typedef {{ id: import("./TireTestQueries").TireTestStatus, name: string, color: string }} Status */
/** @type {{ [index: string]: Status} */
export const statusesOfRiskAnalysis = {
  archived: { id: "archived", name: "Archived", color: "gray" },
  draft: { id: "draft", name: "Draft", color: "indigo" },
  filled: { id: "filled", name: "In progress", color: "red" },
  analysed: { id: "analysed", name: "In progress", color: "red" },
  decided: { id: "decided", name: "In progress", color: "red" },
  closed: { id: "closed", name: "Closed", color: "green" },
};

export const StatusBadge = ({ status }) => {
  const foundStatus = statusesOfRiskAnalysis[status];
  if (!foundStatus) {
    return null;
  }
  return (
    <Badge color={foundStatus.color}>
      <BadgeDot />
      {foundStatus.name}
    </Badge>
  );
};

/** @type {(tireTest: import("./TireTestQueries").TireTest) => string} */
const RiskAnalysisDateLabel = ({ riskAnalysis }) => {
  return riskAnalysis.date ? `${formatDate(riskAnalysis.date)}` : "No date";
};

export const RiskAnalysisListItemSkeleton = ({ as: Component = ListItem }) => {
  return (
    <Component>
      <ListItemContent>
        <ListItemRow>
          <Skeleton />
          <BadgeSkeleton />
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <BookmarkOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <ChartBarOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <VehiculeIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <ClipboardCheckOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
          </ListItemMeta>

          <ListItemMetaTag as="p">
            <CalendarOutlineIcon />
            <Skeleton />
          </ListItemMetaTag>
        </ListItemRow>
      </ListItemContent>
    </Component>
  );
};

export const RiskAnalysisMetaTags = ({ riskAnalysis, MetaTag, EmptyMetaTag }) => {
  return (
    <>
      <MetaTag>
        <BookmarkOutlineIcon width={24} height={24} />
        <span tw="truncate">{riskAnalysis?.category || <EmptyMetaTag />}</span>
      </MetaTag>
      <MetaTag>
        <ChartBarOutlineIcon width={24} height={24} />
        <span tw="truncate">{riskAnalysis?.level || <EmptyMetaTag />}</span>
      </MetaTag>
      <MetaTag>
        <VehiculeIcon width={24} height={24} />
        <span tw="truncate">{riskAnalysis?.type || <EmptyMetaTag />}</span>
      </MetaTag>
      <MetaTag>
        <ClipboardCheckOutlineIcon width={24} height={24} />
        <span tw="truncate">
          {riskAnalysis?.analysisCategory || <EmptyMetaTag />}
          {" ꞏ "}
          {riskAnalysis?.analysisType || <EmptyMetaTag />}
        </span>
      </MetaTag>
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
const RiskAnalysisStatusBadge = ({ riskAnalysis }) => {
  const status = riskAnalysis.status;
  const statusName = statusesOfRiskAnalysis[status] ? statusesOfRiskAnalysis[status].name : status;
  const statusColor = statusesOfRiskAnalysis[status]
    ? statusesOfRiskAnalysis[status].color
    : "gray";
  return (
    <Badge color={statusColor} title={`This test has status "${statusName}".`}>
      <BadgeDot />
      {statusName}
    </Badge>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const RiskAnalysisListItemSummary = ({ riskAnalysis }) => {
  const riskAnalysisTitle = useRiskAnalysisTitleWithoutDevName(riskAnalysis);
  const riskAnalysisSubTitle = useRiskAnalysisSubTitle(riskAnalysis);
  return (
    <>
      <ListItemRow>
        <div tw="flex items-center space-x-2 truncate">
          <PrimaryLinkButton as={Link} to={`/risks-analysis/${riskAnalysis._id}`} tw="truncate">
            {riskAnalysisTitle}
          </PrimaryLinkButton>
          {riskAnalysis.isLocked && (
            <LockClosedIcon tw="-mr-1 ml-2 h-3 w-3 text-gray-500 flex-shrink-0" />
          )}
        </div>
        <RiskAnalysisStatusBadge riskAnalysis={riskAnalysis} />
      </ListItemRow>
      <ListItemRow>
        <div tw="text-xs text-gray-500">
          {riskAnalysis.developer?.name ? riskAnalysis.developer?.name : "n.c."} -{" "}
          {riskAnalysisSubTitle ? riskAnalysisSubTitle : "n.c."}
        </div>
      </ListItemRow>
      <ListItemRow>
        <ListItemMeta>
          <RiskAnalysisMetaTags
            riskAnalysis={riskAnalysis}
            MetaTag={ListItemMetaTag}
            EmptyMetaTag={ListItemEmptyMetaTag}
          />
        </ListItemMeta>

        <ListItemMetaTag as="p">
          <CalendarOutlineIcon width={24} height={24} />
          <span>
            <RiskAnalysisDateLabel riskAnalysis={riskAnalysis} />
          </span>
        </ListItemMetaTag>
      </ListItemRow>
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const RiskAnalysisListItem = ({ riskAnalysis }) => {
  return (
    <ListItem>
      <ListItemContent>
        <RiskAnalysisListItemSummary riskAnalysis={riskAnalysis} />
      </ListItemContent>
    </ListItem>
  );
};

export const RiskAnalysisAccordion = forwardRef((props, ref) => {
  return <Accordion ref={ref} as="ul" multiple collapsible {...props} />;
});
