/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import tw from "twin.macro";

export const DescriptionList = styled("dl")(tw`sm:divide-y sm:divide-gray-200`);
export const Description = styled("div")(tw`py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`);
export const DescriptionLabel = styled("dt")(tw`text-sm font-medium text-gray-500`);
export const DescriptionValue = styled("dd")(tw`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2`);

export const DenseDescriptionList = styled("dl")(
  tw`grid gap-x-6 gap-y-1 py-2 px-4 sm:(py-3 px-6) grid-cols-1 sm:(grid-cols-2)`
);
export const DenseDescription = styled("div")(tw`flex items-baseline justify-between space-x-2`);
export const DenseDescriptionLabel = styled("dt")(
  tw`text-sm text-gray-500 font-medium whitespace-nowrap`
);
export const DenseDescriptionValue = styled("dd")(tw`text-sm font-medium`);
