import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useSearchQuery = () => {
  const location = useLocation();
  return useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  }, [location.search]);
};

export const useLocalStorageSearchQuery = (key) => {
  const location = useLocation();
  const storageSearch = localStorage.getItem(key);
  return useMemo(() => {
    return qs.parse(storageSearch?.length > 0 ? storageSearch : location.search, { ignoreQueryPrefix: true });
  }, [location.search, storageSearch]);
};
