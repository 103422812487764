/** @jsxImportSource @emotion/react */
import { SkipNavContent } from "@reach/skip-nav";
import "twin.macro";
import { Container } from "./Container";

export const Page = ({ children }) => {
  return <main tw="flex-1 relative overflow-y-auto">{children}</main>;
};

export const PageHeader = ({ breadcrumb, title, meta, actions, ...props }) => {
  return (
    <Container as="header" tw="pt-6" {...props}>
      {breadcrumb}
      <div tw="md:(flex items-center justify-between)">
        <SkipNavContent />
        <div tw="flex-1 min-w-0">{title}</div>
        {actions && (
          <div tw="mt-4 flex-shrink-0 flex flex-wrap gap-3 flex-row-reverse md:mt-0 md:ml-4 justify-end sm:(flex-row) self-start">
            {actions}
          </div>
        )}
      </div>
      <div>{meta}</div>
    </Container>
  );
};

export const PageMeta = ({ children }) => {
  return <div tw="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">{children}</div>;
};

export const PageMetaTag = (props) => {
  return (
    <div
      tw="mt-2 flex items-center text-sm text-gray-500 sm:mr-6 svg:(flex-shrink-0 h-5 w-5 text-gray-400) space-x-1.5"
      {...props}
    />
  );
};

export const PageEmptyMetaTag = ({ children = "n.c.", ...props }) => (
  <span tw="text-gray-400" {...props}>
    {children}
  </span>
);

export const PageContent = (props) => {
  return <Container tw="mt-4 pb-6" {...props} />;
};

export const PageTitle = ({ as: Component = "h1", ...props }) => {
  return <Component tw="text-xl font-bold text-gray-900 sm:text-2xl" {...props} />;
};

export const PageSkeleton = () => {
  return null;
};
