import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { createRIM, useRIMsQuery, useUpsertRIMMutation } from "../rim/RimQueries";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";

export const RimAutocomplete = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();

  const { isLoading, data } = useRIMsQuery();
  const { mutateAsync: upsertRIM } = useUpsertRIMMutation();

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  const uniqDataList =
    data && data.list
      ? [
          ...new Set(
            data.list.map((rim) => {
              return rim.value;
            })
          ),
        ].sort((a, b) => a.localeCompare(b))
      : [];

  // Resolve option even when entries are not loaded from the Dimension collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = uniqDataList
    ? uniqDataList
        .filter((tireRim) => tireRim && tireRim.length > 0)
        .map((tireRim) => ({ _id: tireRim, label: tireRim, value: tireRim }))
    : [];

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.value : null);

  // Pass the input value to the modal and open it
  const onCreateOption = async (value) => {
    const rim = createRIM(value);
    onChange(value);
    await upsertRIM(rim);
  };

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <>
      <BaseCreatableSelect
        inputId={inputId}
        value={selectedOption}
        onChange={handleChange}
        styles={styles}
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        onCreateOption={onCreateOption}
        {...controllerProps}
        {...props}
      />
    </>
  );
};
