/** @jsxImportSource @emotion/react */
import { XCircleIcon } from "@heroicons/react/solid";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import FileInput from "../files/FileInput";
import { NotificationDisplay } from "../notifications/NotificationDisplay";
import { PrimaryButton } from "../shared/Button";
import {
  DateInput,
  FieldHelperText,
  FieldsetLegend,
  FormGroup,
  HelperText,
  Label,
} from "../shared/Form";
import { Panel, PanelContent } from "../shared/Panel";
import { Spinner } from "../shared/Spinner";
import TinyEditorComponent from "../shared/TextEditor";
import { empty } from "./RiskAnalysisDescriptionTerms";
import { RISKS_ANALYSIS, useUpsertRiskAnalysisMutation } from "./RiskAnalysisQueries";
import { riskAnalysisStatus } from "./RiskAnalysisStatus";

export const FeedbackNotation = ({ notation, clickCallback, selectedNotation, ...props }) => {
  const bgColors = {
    red: tw`bg-red-500 hover:bg-red-400`,
    orange: tw`bg-yellow-500 hover:bg-yellow-400`,
    green: tw`bg-green-600 hover:bg-green-500`,
  };

  const ringColors = {
    red: tw`ring-offset-2 ring-4 ring-red-500`,
    orange: tw`ring-offset-2 ring-4 ring-yellow-500`,
    green: tw`ring-offset-2 ring-4 ring-green-600`,
  };

  return (
    <div
      tw="h-6 w-6 rounded-full cursor-pointer"
      onClick={() => {
        if (clickCallback) {
          clickCallback(notation);
        }
      }}
      css={[selectedNotation === notation && ringColors[notation], bgColors[notation]]}
      {...props}
    ></div>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const RiskResultsForm = ({ riskAnalysis }) => {
  const defaultValues = {
    ...riskAnalysis,
  };

  const history = useHistory();
  const queryClient = useQueryClient();

  const formProps = useForm({ defaultValues });
  const { handleSubmit, getValues } = formProps;
  const { status, mutateAsync: upsertRiskAnalysis } = useUpsertRiskAnalysisMutation();

  const resultActionsRef = useRef(null);
  const mspActionsRef = useRef(null);
  const [feedbackNotation, setFeedbackNotation] = useState(
    riskAnalysis?.feedbackNotation ? riskAnalysis.feedbackNotation : null
  );
  const [isEmptyResults, setIsEmptyResults] = useState(false);

  const onValid = async (data) => {
    let resultActions;
    if (resultActionsRef.current) {
      resultActions = resultActionsRef.current.getContent();
    }
    let mspActions;
    if (mspActionsRef.current) {
      mspActions = mspActionsRef.current.getContent();
    }

    if (resultActions?.length > 0) {
      await upsertRiskAnalysis(
        {
          _id: riskAnalysis._id,
          ...data,
          resultActions,
          mspActions,
          isLocked: true,
          feedbackNotation,
          ...(riskAnalysis.status === riskAnalysisStatus.decided && {
            status: riskAnalysisStatus.closed,
          }),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(RISKS_ANALYSIS);
            queryClient.removeQueries(RISKS_ANALYSIS);
            queryClient.removeQueries([RISKS_ANALYSIS, riskAnalysis._id]);
            history.push(`/risks-analysis/${riskAnalysis._id}/synthesis`);
          },
        }
      );
    } else {
      setIsEmptyResults(true);
      setTimeout(() => {
        setIsEmptyResults(false);
      }, 3000);
    }
  };

  const onInvalid = async () => {
    await upsertRiskAnalysis({ _id: riskAnalysis._id, ...getValues() });
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onValid, onInvalid)} tw="space-y-6">
        <Panel>
          <PanelContent>
            <fieldset>
              <FieldsetLegend>Recap : Analysis comments</FieldsetLegend>
              <HelperText>Analysis comments retrieve from "Analysis" step</HelperText>
              {riskAnalysis.analysisComments?.length > 0 ? (
                <div
                  dangerouslySetInnerHTML={{ __html: riskAnalysis.analysisComments }}
                  className="styledElement"
                  tw="mt-6 background[#f3f4f6 !important] border-t border-b border-gray-200 -mx-6 py-2 px-6"
                  css={{"& p": tw`background[#f3f4f6 !important] font-size[0.875rem !important]`}}
                ></div>
              ) : (
                <div tw="mt-6 background[#f3f4f6 !important] border-t border-b border-gray-200 -mx-6 py-2 px-6">
                  {empty}
                </div>
              )}
            </fieldset>
          </PanelContent>
        </Panel>

        <Panel>
          <PanelContent>
            <fieldset>
              <div tw="flex justify-between gap-x-6">
                <div tw="flex gap-x-6">
                  <div>
                    <FieldsetLegend>Feedback</FieldsetLegend>
                    <HelperText>Give a feedback to the risk analysis</HelperText>
                  </div>

                  <div tw="flex gap-x-3 mt-2 bg-gray-100 rounded-full py-2 px-2 height[min-content]">
                    <FeedbackNotation
                      notation="red"
                      selectedNotation={feedbackNotation}
                      clickCallback={(n) => {
                        setFeedbackNotation(n);
                      }}
                    />
                    <FeedbackNotation
                      notation="orange"
                      selectedNotation={feedbackNotation}
                      clickCallback={(n) => {
                        setFeedbackNotation(n);
                      }}
                    />
                    <FeedbackNotation
                      notation="green"
                      selectedNotation={feedbackNotation}
                      clickCallback={(n) => {
                        setFeedbackNotation(n);
                      }}
                    />
                  </div>
                </div>

                <FormGroup name="feedbackDate">
                  <Label>Feedback date</Label>
                  <DateInput />
                  <FieldHelperText />
                </FormGroup>
              </div>

              <FormGroup name="resultActions" tw="col-span-full">
                <div tw="flex justify-between mt-5">
                  <Label>Feedback</Label>
                </div>
                <TinyEditorComponent
                  ref={resultActionsRef}
                  initialValue={riskAnalysis.resultActions}
                />
              </FormGroup>

              <FormGroup name="resultFiles" tw="col-span-full mt-5">
                <Label>Files</Label>
                <FileInput />
              </FormGroup>

              <FormGroup name="mspActions" tw="col-span-full">
                <div tw="flex justify-between mt-5">
                  <Label>Actions MSP/Q</Label>
                </div>
                <TinyEditorComponent ref={mspActionsRef} initialValue={riskAnalysis.mspActions} />
              </FormGroup>
            </fieldset>
          </PanelContent>
        </Panel>

        <div tw="flex items-center justify-end space-x-3">
          <PrimaryButton type="submit" disabled={status === "loading"}>
            {status === "loading" && <Spinner tw="-ml-1 mr-3 h-5 w-5" />}
            {status === "loading" ? "Saving..." : "Complete"}
          </PrimaryButton>
        </div>

        <NotificationDisplay
          isOpen={isEmptyResults}
          title={'Feedback field is empty'}
          description="Please fill feedback field"
          icon={() => (<XCircleIcon tw="h-6 w-6 text-red-500"/>)}
        />
      </form>
    </FormProvider>
  );
};
