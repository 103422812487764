/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import qs from "qs";
import { Link, useLocation } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "../shared/Icons";
import { useSearchQuery } from "./useSearchQuery";

export const List = styled("ul")(tw`divide-y divide-gray-200`);

export const ListItem = styled("li")(tw`relative`);

export const ListItemContent = styled("div")(tw`flex-1 px-4 py-4 sm:px-6 space-y-2 truncate`);

export const ListItemRow = styled("div")(tw`flex items-center justify-between space-x-2 truncate`);

export const ListItemLink = styled(Link)(tw`focus:outline-none hocus:bg-gray-50`);

export const ListItemMeta = styled("ul")(tw`hidden sm:flex space-x-6 truncate`);

export const ListItemMetaTag = styled("li")(
  tw`flex items-center text-sm text-gray-500 whitespace-nowrap truncate svg:(flex-shrink-0 h-5 w-5 text-gray-400) space-x-1.5`
);

export const ListItemEmptyMetaTag = ({ children = "n.c.", ...props }) => (
  <span tw="text-gray-300" {...props}>
    {children}
  </span>
);

const ListPaginationEllipsis = () => {
  return (
    <span tw="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
      ...
    </span>
  );
};

const createPaginationWithEllipsis = ({ page, pageCount }) => {
  const delta = 2;
  const left = page - delta;
  const right = page + delta + 1;

  const range = [];
  for (let i = 1; i <= pageCount; i++) {
    if (i === 1 || i === pageCount || (i >= left && i < right)) {
      range.push(i);
    }
  }

  const rangeWithDots = [];
  let previousPageInRange;
  for (let i of range) {
    if (previousPageInRange) {
      if (i - previousPageInRange === 2) {
        rangeWithDots.push(previousPageInRange + 1);
      } else if (i - previousPageInRange !== 1) {
        rangeWithDots.push(null);
      }
    }
    rangeWithDots.push(i);
    previousPageInRange = i;
  }

  return rangeWithDots;
};

export const PaginationComponent = ({ page: currentPage, pageSize, totalCount, getPageUrl, pageChangeCallback }) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  const pagination = createPaginationWithEllipsis({ page: currentPage, pageCount });

  return (
    <nav tw="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mt-4">
      <div tw="w-0 flex-1 flex">
        <Link
          to={getPageUrl(currentPage - 1)}
          tw="-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
          css={currentPage <= 1 && tw`opacity-50 pointer-events-none`}
          onClick={() => {
            if (pageChangeCallback) {
              pageChangeCallback(currentPage - 1);
            }
          }}
        >
          <ArrowNarrowLeftIcon tw="mr-3 h-5 w-5 text-gray-400" />
          Previous
        </Link>
      </div>
      <div tw="hidden md:flex">
        {pagination.map((page, index) =>
          typeof page === "number" ? (
            <Link
              key={index}
              to={getPageUrl(page)}
              tw="-mt-px border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
              css={
                page === currentPage
                  ? tw`border-indigo-500 pointer-events-none`
                  : tw`border-transparent`
              }
              onClick={() => {
                if (pageChangeCallback) {
                  pageChangeCallback(page);
                }
              }}
            >
              {page}
            </Link>
          ) : (
            <ListPaginationEllipsis key={index} />
          )
        )}
      </div>
      <div tw="w-0 flex-1 flex justify-end">
        <Link
          to={getPageUrl(currentPage + 1)}
          tw="-mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
          css={currentPage >= pageCount && tw`opacity-50 pointer-events-none`}
          onClick={() => {
            if (pageChangeCallback) {
              pageChangeCallback(currentPage + 1);
            }
          }}
        >
          Next
          <ArrowNarrowRightIcon tw="ml-3 h-5 w-5 text-gray-400" />
        </Link>
      </div>
    </nav>
  );
};

/** @type {React.FC<{ page: number, pageSize: number, totalCount: number }>} */
export const ListPagination = ({ page, pageSize, totalCount }) => {
  const searchQuery = useSearchQuery();
  const location = useLocation();
  const getPageUrl = (page) => {
    return `${location.pathname}${qs.stringify(
      { ...searchQuery, page },
      { addQueryPrefix: true }
    )}`;
  };

  return (
    <PaginationComponent
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      getPageUrl={getPageUrl}
    />
  );
};

export const ListCheckbox = (props) => {
  return (
    <input
      type="checkbox"
      tw="absolute left-0 top-0 -mt-2 -ml-2 bg-gray-100 ring ring-transparent ring-offset-4 ring-offset-gray-100 focus:(ring-indigo-500) not-checked:hover:border-gray-400 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      {...props}
    />
  );
};

export const ListHeaderCheckbox = ({ indeterminate, ...props }) => {
  return (
    <input
      type="checkbox"
      ref={(el) => {
        if (el) {
          el.indeterminate = el.checked && indeterminate;
        }
      }}
      tw="flex-shrink-0 ml-0 sm:ml-4 xl:-ml-8 bg-gray-100 ring ring-transparent ring-offset-4 ring-offset-gray-100 focus:(ring-indigo-500) h-4 w-4 text-indigo-600 border-gray-300 rounded not-checked:hover:border-gray-400"
      {...props}
    />
  );
};
