/** @jsxImportSource @emotion/react */
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { SearchBar } from "../search/SearchBar";
import { useSearch } from "../search/useSearch";
import { Badge, BadgeDot } from "../shared/Badge";
import { PrimaryButton, PrimaryLinkButton } from "../shared/Button";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "../shared/EmptyState";
import { ErrorPage } from "../shared/ErrorPage";
import { InlineFormGroup, Input, Label } from "../shared/Form";
import { ArchiveSolidIcon, ReplySolidIcon, XSolidIcon } from "../shared/Icons";
import { NotFoundIllustration } from "../shared/Illustrations";
import {
  List,
  ListCheckbox,
  ListItem,
  ListItemContent,
  ListItemRow,
  ListPagination,
} from "../shared/List";
import {
  Menu,
  MenuButton,
  MenuButtonDropdownIcon,
  MenuItemButton,
  MenuItems,
  MenuSection,
} from "../shared/Menu";
import { Page, PageContent, PageHeader, PageTitle } from "../shared/Page";
import { Panel } from "../shared/Panel";
import { Filter } from "../shared/QueryHelpers";
import { SEO } from "../shared/SEO";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import { Tabs } from "../shared/Tabs";
import { useItemSelection } from "../shared/useItemSelection";
import { useSearchQuery } from "../shared/useSearchQuery";
import {
  createRIM,
  useFetchRIMs,
  useRIMsQuery,
  useUpsertManyRIMMutation,
  useUpsertRIMMutation,
} from "./RimQueries";

const RIMCreationForm = (props) => {
  const { mutateAsync: upsertRIM, status } = useUpsertRIMMutation();
  const formProps = useForm({
    mode: "onSubmit",
    defaultValues: {
      value: "",
    },
  });
  const { handleSubmit, reset, control } = formProps;
  const onSubmit = (data) => {
    const entry = createRIM(data.value);
    reset();
    upsertRIM(entry);
  };
  const fetchRIMs = useFetchRIMs();
  const id = useWatch({ name: "_id", control });

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} tw="flex items-center space-x-3" {...props}>
        <InlineFormGroup name="value">
          <Label tw="sr-only" hidden>
            Entry value
          </Label>
          <Input
            placeholder="Entry value"
            rules={{
              required: true,
              validate: async (value) => {
                const rims = await fetchRIMs({
                  value,
                  ...(id && { _id: { $ne: id } }),
                });
                return rims.length === 0 ? null : "This RIM already exists";
              },
            }}
          />
        </InlineFormGroup>
        <PrimaryButton type="submit" disabled={status === "loading"}>
          Create
        </PrimaryButton>
      </form>
    </FormProvider>
  );
};

/** @type {React.FC<{ rim: import("./RimQueries").RIM }>} */
export const RIMListItem = ({ rim, leading }) => {
  return (
    <ListItem>
      {leading && <div tw="hidden absolute sm:(block -left-6 top-6 mt-px)">{leading}</div>}
      <ListItemContent>
        <ListItemRow>
          <PrimaryLinkButton as={Link} to={`/rims/${rim._id}`} tw="truncate">
            {rim.value}
          </PrimaryLinkButton>
          {rim.status === "active" && (
            <Badge color="green">
              <BadgeDot />
              Active
            </Badge>
          )}
          {rim.status === "archived" && (
            <Badge color="gray">
              <BadgeDot />
              Archived
            </Badge>
          )}
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const RIMListItemSkeleton = () => {
  return (
    <ListItem>
      <ListItemContent>
        <ListItemRow>
          <Skeleton />
          <BadgeSkeleton />
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const RIMList = () => {
  const { page = 1, status: selectedStatus } = useSearchQuery();
  const { search, debouncedSearch, setSearch } = useSearch();

  const pageSize = 10;
  const { status, data, error } = useRIMsQuery({
    skip: (Number(page) - 1) * pageSize,
    limit: pageSize,
    ...Filter.from(
      selectedStatus && { status: selectedStatus },
      debouncedSearch && { value: Filter.regex(debouncedSearch) }
    ),
  });

  const { selectedItems, isSelected, onClearSelection, onToggle } = useItemSelection();

  const { mutateAsync: upsertManyRIMs } = useUpsertManyRIMMutation();

  return (
    <>
      {status === "error" && <ErrorPage error={error} />}
      {status !== "error" && (
        <>
          <SEO title="RIMs" />
          <Page>
            <PageHeader
              title={<PageTitle>RIMs</PageTitle>}
              actions={
                <>
                  <SearchBar value={search} onChange={setSearch} />
                  <Menu css={selectedItems.length === 0 && tw`hidden`}>
                    <MenuButton>
                      More
                      <MenuButtonDropdownIcon />
                    </MenuButton>
                    <MenuItems>
                      <MenuSection>
                        <MenuItemButton
                          onClick={() => {
                            onClearSelection();
                            upsertManyRIMs(
                              selectedItems.map((item) => ({
                                _id: item._id,
                                status: "archived",
                              }))
                            );
                          }}
                        >
                          <ArchiveSolidIcon />
                          Archive
                        </MenuItemButton>
                        <MenuItemButton
                          onClick={() => {
                            onClearSelection();
                            upsertManyRIMs(
                              selectedItems.map((item) => ({
                                _id: item._id,
                                status: "active",
                              }))
                            );
                          }}
                        >
                          <ReplySolidIcon />
                          Activate
                        </MenuItemButton>
                      </MenuSection>
                      <MenuSection>
                        <MenuItemButton onClick={onClearSelection}>
                          <XSolidIcon />
                          Clear selection
                        </MenuItemButton>
                      </MenuSection>
                    </MenuItems>
                  </Menu>
                  <RIMCreationForm />
                </>
              }
            />
            <PageContent>
              <Tabs
                options={[
                  { label: "All", to: `/rims` },
                  { label: "Active", to: `/rims?status=active` },
                  { label: "Archived", to: `/rims?status=archived` },
                ]}
              />
              {status === "loading" && (
                <Panel tw="mt-4">
                  <List>
                    {Array.from({ length: 5 }, (_, index) => (
                      <RIMListItemSkeleton key={index} />
                    ))}
                  </List>
                </Panel>
              )}
              {status === "success" && data.totalCount === 0 && (
                <EmptyState>
                  <EmptyStateIllustration as={NotFoundIllustration} />
                  {selectedStatus || debouncedSearch ? (
                    <>
                      <EmptyStateTitle as="h3">
                        There are no RIM matching this search
                      </EmptyStateTitle>
                      <EmptyStateDescription>
                        Try changing your search parameters or clearing your search filters
                      </EmptyStateDescription>
                      <PrimaryButton as={Link} to={`/rims`} tw="mt-8">
                        Clear filters
                      </PrimaryButton>
                    </>
                  ) : (
                    <>
                      <EmptyStateTitle as="h3">You don't have any RIM yet</EmptyStateTitle>
                      <EmptyStateDescription>
                        You can create your first RIM by using the form below
                      </EmptyStateDescription>
                      <RIMCreationForm tw="mt-8" />
                    </>
                  )}
                </EmptyState>
              )}
              {status === "success" && data.totalCount !== 0 && (
                <>
                  <Panel tw="mt-4">
                    <List>
                      {data.list.map((rim) => (
                        <RIMListItem
                          key={rim._id}
                          rim={rim}
                          leading={
                            <ListCheckbox checked={isSelected(rim)} onChange={onToggle(rim)} />
                          }
                        />
                      ))}
                    </List>
                  </Panel>
                  <ListPagination
                    page={Number(page)}
                    pageSize={pageSize}
                    totalCount={data.totalCount}
                  />
                </>
              )}
            </PageContent>
          </Page>
        </>
      )}
    </>
  );
};

export default RIMList;
