/** @jsxImportSource @emotion/react */
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { Redirect } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "../shared/Button";
import { FieldHelperText, FormGroup, Input, Label, NumberInput } from "../shared/Form";
import { Modal, ModalActions, ModalButton } from "../shared/Modal";
import { Panel, PanelContent, PanelFooter } from "../shared/Panel";
import { useFetchCircuits, useUpsertCircuitMutation } from "./CircuitQueries";

const CircuitFormFields = (props) => {
  const { register } = useFormContext();
  const fetchCircuits = useFetchCircuits();
  const id = useWatch({ name: "_id" });

  return (
    <div tw="grid grid-cols-1 gap-6" {...props}>
      <input type="hidden" name="_id" ref={register()} />
      <input type="hidden" name="status" ref={register()} defaultValue="active" />
      <FormGroup name="label">
        <Label>Label</Label>
        <Input
          rules={{
            required: "This field is required",
            validate: async (value) => {
              const circuits = await fetchCircuits({
                value,
                ...(id && { _id: { $ne: id } }),
              });
              return circuits.length === 0 ? null : "This circuit already exists";
            },
          }}
        />
        <FieldHelperText />
      </FormGroup>

      <FormGroup name="country">
        <Label>Country</Label>
        <Input />
        <FieldHelperText />
      </FormGroup>

      <FormGroup name="distance">
        <Label>Distance</Label>
        <NumberInput />
        <FieldHelperText />
      </FormGroup>
    </div>
  );
};

/**
 * Defect creation or update form wrapped within a Panel.
 * If the given defect has an `oid`, the defect will be created, otherwise, it will be updated.
 * @type {React.FC<{ defect?: import("./CircuitQueries").Circuit }>}
 */
export const CircuitForm = ({ circuit = {} }) => {
  const formProps = useForm({
    defaultValues: circuit,
  });
  const { mutateAsync: upsertCircuit, status, data } = useUpsertCircuitMutation();
  const { handleSubmit } = formProps;

  return (
    <FormProvider {...formProps}>
      {data && <Redirect to="/circuits" />}
      <form onSubmit={handleSubmit(upsertCircuit)}>
        <Panel>
          <PanelContent>
            <CircuitFormFields tw="sm:grid-cols-3" />
          </PanelContent>
          <PanelFooter>
            <PrimaryButton type="submit" disabled={status === "loading"}>
              Save
            </PrimaryButton>
          </PanelFooter>
        </Panel>
      </form>
    </FormProvider>
  );
};

const CircuitCreationModalForm = ({ circuit, onDismiss, onCreated }) => {
  const formProps = useForm({
    mode: "onSubmit",
    defaultValues: circuit,
  });
  const { handleSubmit } = formProps;

  const { mutateAsync: upsertCircuit, status } = useUpsertCircuitMutation();
  const onCreate = async (data) => {
    const createdCircuit = await upsertCircuit(data);
    onCreated(createdCircuit);
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onCreate)}>
        <CircuitFormFields tw="sm:grid-cols-2" />
        <ModalActions>
          <ModalButton as={PrimaryButton} type="submit" disabled={status === "loading"}>
            Save
          </ModalButton>
          <ModalButton onClick={onDismiss} type="button">
            Cancel
          </ModalButton>
        </ModalActions>
      </form>
    </FormProvider>
  );
};

export const CircuitCreationModal = ({ isOpen, circuit, onDismiss, onCreated }) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} aria-label="DTO creation modal">
      {isOpen && (
        <CircuitCreationModalForm circuit={circuit} onDismiss={onDismiss} onCreated={onCreated} />
      )}
    </Modal>
  );
};
