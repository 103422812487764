/** @jsxImportSource @emotion/react */
import "twin.macro";
import { MenuItemButton } from "../shared/Menu";
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import importTiresTemplate from "./template-imports/import-tires-template.xlsx";
import destroyedTiresTemplate from  "./template-imports/destroyed-tires-template.xlsx";

const TEMPLATES = {
  "import-tires-template": importTiresTemplate,
  "import-force-and-moments-template": importTiresTemplate,
  "destroyed-tires-template": destroyedTiresTemplate,
  "sent-back-tires-template": destroyedTiresTemplate,
}

const TemplateDownloadBtn = ({btnLabel = "Button label", templateName = "" }) => {

  const downloadTemplate = async () => {
    const { saveAs } = await import("file-saver");
    const template = await (await fetch(TEMPLATES[templateName])).blob();
    if (template) {
      saveAs(template, `${templateName}.xlsx`);
    }
  }

  return (
    <>
      <MenuItemButton onClick={downloadTemplate}>
        <DocumentDownloadIcon tw="text-indigo-600!" />
        {btnLabel}
      </MenuItemButton>
    </>
  );
};

export default TemplateDownloadBtn;
