/** @jsxImportSource @emotion/react */
import "twin.macro";
import { theme } from "twin.macro";
import { SearchSolidIcon } from "../shared/Icons";

export const SearchBar = ({ value, onChange, ...props }) => {
  return (
    <div tw="relative rounded-md shadow-sm" {...props}>
      <div tw="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchSolidIcon tw="h-5 w-5 text-gray-400" />
      </div>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="search"
        tw="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 border-gray-300 text-sm"
        css={{
          "&::-webkit-search-cancel-button": {
            WebkitAppearance: "none",
            color: theme`colors.gray.400`,
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%239ca3af'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E")`,
            width: theme`space.4`,
            height: theme`space.4`,
            cursor: "pointer",
          },
        }}
        placeholder="Search"
        aria-label="Search"
      />
    </div>
  );
};
