/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext, useWatch } from "react-hook-form";
import "twin.macro";
import { v4 as uuid } from "uuid";
import { useFormId, useFormName } from "../shared/Form";
import { UploadFileIcon } from "../shared/Icons";
import { FileLoadingIndicator, FilePreview, FilePreviewList } from "./FilePreview";
import { useUploadFileMutation } from "./FileQueries";
import { humanSize } from "./humanSize";

const FileUpload = ({ file, onUpload }) => {
  const { mutateAsync: uploadFile, status } = useUploadFileMutation();
  useEffect(() => {
    uploadFile(file);
  }, [file, uploadFile]);

  useEffect(() => {
    if (status === "success") {
      onUpload(file);
    }
  }, [file, onUpload, status]);

  return (
    <li tw="relative">
      <div tw="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
        <div tw="bg-gray-200 object-cover animate-pulse" />
      </div>

      {/* File upload status */}
      <FileLoadingIndicator />

      {/* File name */}
      <p tw="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
        {file.name}
      </p>
      {/* File size */}
      <p tw="block text-sm font-medium text-gray-500 pointer-events-none">{humanSize(file.size)}</p>
    </li>
  );
};

const FileInput = ({ rules, defaultValue = [], onFocus, ...props }) => {
  const name = useFormName();
  const id = useFormId();
  const { setValue, register, unregister } = useFormContext();
  const files = useWatch({ name });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);

  const [pendingUploads, setPendingUploads] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const newUploads = acceptedFiles.map((file) =>
        Object.assign(file, {
          oid: uuid(),
          preview: URL.createObjectURL(file),
        })
      );
      setPendingUploads((pendingUploads) => [...pendingUploads, ...newUploads]);
    },
  });

  const onDelete = (fileToDelete) => {
    // Remove the file to delete from the field value
    const value = files
      ? files.filter(Boolean).filter((file) => file.oid !== fileToDelete.oid)
      : files;
    setValue(name, value);
  };

  const onUpload = async (file) => {
    // If the file is a .dat file, compute the time
    let computedTime = 0;
    if (isDatFile(file.name)) {
      const text = (await file.text()).trim();
      // Split file content in lines
      const lines = text.split("\n");
      // Get the last line of the file
      const lastLine = lines[lines.length - 1];
      // Get the first column of the last line (the time)
      const firstColumn = lastLine.split("\t")[0];
      computedTime = parseFloat(firstColumn);

      const tireTestFile = {
        oid: file.oid,
        name: file.name,
        type: file.type,
        size: file.size,
        time: computedTime,
      };
      const value = files
        ? [...files.filter((file) => file.oid !== tireTestFile.oid), tireTestFile]
        : [tireTestFile];
      setValue(name, value);
      // Remove file from pending uploads
      setPendingUploads((pendingUploads) =>
        pendingUploads.filter((otherFile) => file.oid !== otherFile.oid)
      );
    } else {
      const tireTestFile = {
        oid: file.oid,
        name: file.name,
        type: file.type,
        size: file.size,
      };
      const value = files
        ? [...files.filter((file) => file.oid !== tireTestFile.oid), tireTestFile]
        : [tireTestFile];
      setValue(name, value);
      // Remove file from pending uploads
      setPendingUploads((pendingUploads) =>
        pendingUploads.filter((otherFile) => file.oid !== otherFile.oid)
      );
    }
  };

  return (
    <FilePreviewList>
      {files?.filter(Boolean).map((file) => (
        <FilePreview key={file.oid} file={file} onDelete={onDelete} />
      ))}
      {pendingUploads.map((file) => (
        <FileUpload key={file.oid} file={file} onUpload={onUpload} />
      ))}
      <li tw="relative">
        <input type="file" id={id} tw="sr-only" {...getInputProps()} {...props} />
        <div
          className="group"
          tw="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden border-2 border-gray-300 border-dashed cursor-pointer hover:(opacity-75) focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)"
          {...getRootProps()}
        >
          <div tw="flex items-center justify-center">
            <UploadFileIcon tw="h-12 w-12 text-gray-300" />
          </div>
        </div>
      </li>
    </FilePreviewList>
  );
};

export default FileInput;

const isDatFile = (fileName) => {
  // Get the file extension
  const fileExtension = fileName?.split(".").pop().toLowerCase();
  // Check if the file extension is 'dat'
  return fileExtension === "dat";
};
