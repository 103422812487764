// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CURRENT_STOCK } from "../dtos/DTOCurrentStock";
import { useApi } from "../shared/useApi";

export const TIRES = "tires";

/** @typedef {("used" | "unused" | "usedByForceAndMoment" | "destroyed")} TireStatus */
export const tireStatuses = [
  { value: "unused", label: "Unused" },
  { value: "used", label: "Used" },
  { value: "usedByForceAndMoment", label: "Used by force and moment" },
  { value: "destroyed", label: "Destroyed" },
  { value: "sendedback", label: "Sent back" },
];

/**
 * @typedef TireProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {TireStatus} status
 * @property {string} dto
 * @property {string} serialNumber
 * @property {string} tirf
 * @property {string} statusUpdatedAt
 * @property {boolean} isWitnessTire
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & TireProps} Tire */

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<Tire>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<Tire>>} */
export const useTiresQuery = (searchParams = {}) => {
  const api = useApi();
  return useQuery([TIRES, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "dto", ...query } = searchParams;
    const response = await api.get(`v1/tires`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {() => (searchParams?: import("../shared/QueryHelpers").SearchParams<Tire>) => Promise<Tire[]>} */
export const useFetchTires = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/tires`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {(tireId: string) => import("react-query").UseQueryResult<Tire>} */
export const useTireByIdQuery = (tireId) => {
  const api = useApi();
  return useQuery([TIRES, tireId], async () => {
    return api.get(`v1/tires/${tireId}`).json();
  });
};

/** @type {() => (serialNumber: string) => Promise<Tire | null>} */
export const useFetchTireBySerialNumber = () => {
  const api = useApi();
  return async (serialNumber) => {
    const tires = await api.get(`v1/tires`, { searchParams: { serialNumber } }).json();
    if (tires.length === 0) {
      return null;
    }
    return tires[0];
  };
};

/** @type {() => import("react-query").UseMutationResult<Tire[], unknown, Tire[]>} */
export const useUpsertManyTireMutation = () => {
  const { mutateAsync: upsertTire } = useUpsertTireMutation();
  return useMutation(async (tires) => {
    return Promise.all(tires.map((tire) => upsertTire(tire)));
  });
};

/** @type {() => import("react-query").UseMutationResult<Tire, unknown, Tire>} */
export const useUpsertTireMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    async (tire) => {
      if (tire._id) {
        return api.patch(`v1/tires/${tire._id}`, { json: tire }).json();
      }
      return api.post(`v1/tires`, { json: tire }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TIRES);
        // Invalidate stock as it might change when a tire status changes or a tire is created
        // e.g.: when a tire status changes to usedByForceAndMoment, the dto stock changes.
        queryClient.invalidateQueries(CURRENT_STOCK);
      },
    }
  );
};
