/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useState } from "react";
import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import { useDisclosure } from "../shared/useDisclosure";
import { DTOCreationModal } from "./DTOForm";
import { useDTOsWithStockQuery } from "./DTOQueries";

export const DTOAutocomplete = ({ defaultValue = "", rules, onFocus, displayDimension, initialDimension, dimensionUpdateCallback, ...props }) => {
  const name = useFormName();
  // Load entries from the DTO collection
  const { isLoading, data } = useDTOsWithStockQuery({ limit: 0, skip: 0, status: "active"});

  const [dtoDimension, setDtoDimension] = useState(initialDimension);

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  // Resolve option even when entries are not loaded from the DTO collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = data ? data.list.map((dto) => ({ ...dto, label: dto.value })) : [];

  // Change the value of the field
  const handleChange = (selectedOption) => {
    setDtoDimension(selectedOption?.tireDimension? selectedOption.tireDimension : null);
    onChange(selectedOption ? selectedOption.value : null);
    if (dimensionUpdateCallback) {
      dimensionUpdateCallback(selectedOption?.tireDimension? selectedOption.tireDimension : null);
    }
  };

  // DTO value passed to the creation modal
  const [createdDTOValue, setCreatedDTOValue] = useState("");
  // Modal state helper
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  // Pass the input value to the modal and open it
  const onCreateOption = (value) => {
    setCreatedDTOValue(value);
    onOpen();
  };

  // Retrieve the value of the DTO created in the modal and close it
  const onOptionCreated = (dto) => {
    setDtoDimension(dto.tireDimension? dto.tireDimension : null);
    onChange(dto.value);
    if (dimensionUpdateCallback) {
      dimensionUpdateCallback(dto.tireDimension? dto.tireDimension : null);
    }
    onClose();
  };

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <>
      <BaseCreatableSelect
        inputId={inputId}
        value={selectedOption}
        onChange={handleChange}
        styles={styles}
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        onCreateOption={onCreateOption}
        {...controllerProps}
        {...props}
      />
      <DTOCreationModal
        isOpen={isOpen}
        dto={{ value: createdDTOValue }}
        onDismiss={onClose}
        onCreated={onOptionCreated}
      />
      {
        displayDimension && dtoDimension &&
        <div tw="text-sm">Dimension: {dtoDimension}</div>
      }
    </>
  );
};
