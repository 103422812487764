/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import "twin.macro";
import { useNotifications } from "./NotificationProvider";

export const NotificationLiveRegion = () => {
  const notifications = useNotifications();

  /* Global notification live region, render this permanently at the end of the document */
  return (
    <div
      aria-live="assertive"
      tw="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6"
    >
      <div tw="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        {notifications.map((notification) => (
          <Fragment key={notification.id}>{notification.content}</Fragment>
        ))}
      </div>
    </div>
  );
};
