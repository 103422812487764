import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import { Filter } from "../shared/QueryHelpers";
import {
  createRepositoryEntry,
  useRepositoryEntriesQuery,
  useUpsertRepositoryEntryMutation,
} from "./RepositoryEntryQueries";

export const RepositoryAutocomplete = ({
  defaultValue = "",
  rules,
  onFocus,
  repositoryKey,
  ...props
}) => {
  const name = useFormName();
  // Load entries from the repository entry collection
  const { isLoading, data } = useRepositoryEntriesQuery({
    ...Filter.from({ key: repositoryKey, status: "active" }),
  });

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  // Resolve option even when entries are not loaded from the repository entry collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = data ? data.list.map((entry) => ({ ...entry, label: entry.value })) : [];

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.value : null);

  // Upsert a new entry in the repository and select it.
  const {
    mutateAsync: upsertRepositoryEntry,
    isLoading: isUpserting,
  } = useUpsertRepositoryEntryMutation();
  const onCreateOption = async (value) => {
    const entry = createRepositoryEntry(value, repositoryKey);
    onChange(value);
    await upsertRepositoryEntry(entry);
  };

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <BaseCreatableSelect
      inputId={inputId}
      name={name}
      value={selectedOption}
      onChange={handleChange}
      styles={styles}
      isClearable
      isLoading={isLoading || isUpserting}
      isDisabled={isLoading || isUpserting}
      options={options}
      onCreateOption={onCreateOption}
      {...controllerProps}
      {...props}
    />
  );
};
