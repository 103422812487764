/** @jsxImportSource @emotion/react */
import { useController } from "react-hook-form";
import BaseSelect from "react-select";
import "twin.macro";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import {
  getSessionDescription,
  getSessionTitle,
  useSessionByIdQuery,
  useSessionsQuery,
} from "./SessionQueries";
import { Badge } from "../shared/Badge";

export const SessionAutocomplete = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();
  // Load entries from the session collection
  const { isLoading: areOptionsLoading, data } = useSessionsQuery();

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  // Resolve option even when entries are not loaded from the session collection.
  // Use the value as a label. If no value is defined, no element is selected
  const { isLoading: isSelectedOptionLoading, data: selectedOption } = useSessionByIdQuery(value);
  const options = data ? data.list : [];

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.oid : null);

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  const isLoading = isSelectedOptionLoading || areOptionsLoading;

  return (
    <BaseSelect
      inputId={inputId}
      value={selectedOption}
      onChange={handleChange}
      styles={styles}
      isLoading={isLoading}
      isDisabled={isLoading}
      options={options}
      getOptionValue={(session) => session.oid}
      getOptionLabel={getSessionTitle}
      formatOptionLabel={(session, labelMeta) => {
        if (labelMeta.context === "menu") {
          return (
            <div tw="flex flex-col space-y-2">
              <div tw="flex items-center space-x-2">
                <span>{getSessionTitle(session)}</span>
                {session?.isForceAndMoment && <Badge color="blue">F/M</Badge>}
              </div>
              <span tw="opacity-80 text-xs">{getSessionDescription(session)}</span>
            </div>
          );
        } else {
          return (
            <span tw="space-x-2">
              <span>{getSessionTitle(session)}</span>
              {session?.isForceAndMoment && <Badge color="blue">F/M</Badge>}
              <span>{getSessionDescription(session)}</span>
            </span>
          );
        }
      }}
      {...controllerProps}
      {...props}
    />
  );
};
