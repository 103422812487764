/** @jsxImportSource @emotion/react */
import {
  SliderHandle as ReachSliderHandle,
  SliderInput as ReachSliderInput,
  SliderMarker as ReachSliderMarker,
  SliderRange as ReachSliderRange,
  SliderTrack as ReachSliderTrack
} from "@reach/slider";
import "@reach/slider/styles.css";
import { forwardRef } from "react";
import tw from "twin.macro";

const clamp = (val, min, max) => {
  return val > max ? max : val < min ? min : val;
};

export const SliderMarker = ({ value, children, ...props }) => {
  return (
    <ReachSliderMarker
      value={value}
      css={{
        '&[data-reach-slider-marker][data-orientation="horizontal"]': tw`relative h-1 w-1 rounded-full border-none`,
        '&[data-reach-slider-marker][data-state="at-value"]': tw`bg-white`,
        '&[data-reach-slider-marker][data-state="under-value"]': tw`bg-white`,
        '&[data-reach-slider-marker][data-state="over-value"]': tw`bg-white`,
      }}
    >
      <span
        tw="absolute mt-5 text-xs text-gray-500 font-medium whitespace-nowrap px-0.5"
        {...props}
      >
        {children}
      </span>
    </ReachSliderMarker>
  );
};

export const SliderTooltip = ({ children }) => {
  return (
    <div tw="absolute transform -translate-x-1/2 ml-2.5 left-0 min-w-full scale-0 transition duration-150 ease-in-out group-hocus:scale-100 origin-bottom">
      <div tw="relative shadow-md">
        <div tw="bg-gray-900 -mt-8 text-gray-200 truncate text-xs font-semibold rounded py-1 px-2 select-none text-center">
          {children}
        </div>
        <svg
          tw="absolute text-gray-900 w-full h-2"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          space="preserve"
        >
          <polygon tw="fill-current" points="0,0 127.5,127.5 255,0"></polygon>
        </svg>
      </div>
    </div>
  );
};

export const SliderHandle = ({ children }) => {
  return (
    <ReachSliderHandle
      className="group"
      css={{
        "&[data-reach-slider-handle]": tw`relative h-5 w-5 border border-gray-200 rounded-full bg-white focus:(outline-none ring ring-indigo-500 ring-opacity-25 border-indigo-500)`,
      }}
    >
      {children}
    </ReachSliderHandle>
  );
};

export const Slider = forwardRef(({ step = 1, min, max, value, onChange, children }, ref) => {
  const margin = (max - min) * 0.025;
  return (
    <ReachSliderInput
      ref={ref}
      value={value}
      onChange={(newValue) => {
        onChange(clamp(newValue, min, max));
      }}
      min={min - margin}
      max={max + margin}
      step={step}
      css={{
        '&[data-reach-slider-input][data-orientation="horizontal"]': tw`h-2 focus:outline-none w-full py-4 sm:max-w-md`,
      }}
    >
      <ReachSliderTrack
        css={{
          "&[data-reach-slider-track]": tw`rounded-full bg-gray-300`,
        }}
      >
        <ReachSliderRange
          css={{
            "&[data-reach-slider-range]": tw`bg-indigo-600`,
          }}
        />
        {children}
      </ReachSliderTrack>
    </ReachSliderInput>
  );
});
