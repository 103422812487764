import { useController } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
import { useAutocompleteFormStyles } from "../shared/Autocomplete";
import { useFormName } from "../shared/Form";
import { useUserQuery } from "./UserQueries";
import "twin.macro";

export const UserMultiSelect = ({ defaultValue = "", rules, onFocus, disabled, ...props }) => {
  const name = useFormName();

  // Load entries from the DTO collection
  const { isLoading, data, refetch } = useUserQuery({});

  const styles = useAutocompleteFormStyles();

  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  const options = data
    ? data.list.map((entry) => ({ ...entry, label: entry.name, value: entry.name }))
    : [];

  const selectedOptions = value ? value.map((v) => {return {...v, value: v.name, label: v.name}}) : null;

  const onCreateOption = (valueCreated) => {
    const values = value;
    values.push({name: valueCreated});
    onChange(values);
    refetch();
  };


  return (
    <>
      <CreatableSelect
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        value={selectedOptions}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={isLoading || disabled}
        onCreateOption={onCreateOption}
        styles={styles}
        options={options}
        {...controllerProps}
        {...props}
      />
    </>
  );
};
