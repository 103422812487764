/** @jsxImportSource @emotion/react */
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "twin.macro";
import { usePermissions } from "./PermissionsContext";
import RIMDetails from "./rim/RIMDetails";
import RIMList from "./rim/RimList";
import { Layout } from "./shared/Layout";
import { PageSkeleton } from "./shared/Page";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const TireTestList = lazy(() => import("./tire-tests/TireTestList"));
const TireTestCreation = lazy(() => import("./tire-tests/TireTestCreation"));
const TireTestDetails = lazy(() => import("./tire-tests/TireTestDetails"));
const TireTestSynthesis = lazy(() => import("./tire-tests/TireTestSynthesis"));
const TireTestDuplication = lazy(() => import("./tire-tests/TireTestDuplication"));
const TireTestDuplicationAsChild = lazy(() => import("./tire-tests/TireTestDuplicationAsChild"));

const TireTestSearchPage = lazy(() => import("./tire-test-search/TireTestSearchPage"));
const RiskAnalysisSearch = lazy(() => import("./risk-analysis-search/RiskAnalysisSearch"));

const DTOList = lazy(() => import("./dtos/DTOList"));
const DTOCreation = lazy(() => import("./dtos/DTOCreation"));
const DTODetails = lazy(() => import("./dtos/DTODetails"));

const TireList = lazy(() => import("./tires/TireList"));
const TireCreation = lazy(() => import("./tires/TireCreation"));
const TireDetails = lazy(() => import("./tires/TireDetails"));

const DefectList = lazy(() => import("./defects/DefectList"));
const DefectCreation = lazy(() => import("./defects/DefectCreation"));
const DefectDetails = lazy(() => import("./defects/DefectDetails"));

const CircuitList = lazy(() => import("./circuits/CircuitList"));
const CircuitCreation = lazy(() => import("./circuits/CircuitCreation"));
const CircuitDetails = lazy(() => import("./circuits/CircuitDetails"));

const TypeRiskAnalysisList = lazy(() => import("./types-risk-analysis/TypeRiskAnalysisList"));
const TypeRiskAnalysisCreation = lazy(() =>
  import("./types-risk-analysis/TypeRiskAnalysisCreation")
);
const TypeRiskAnalysisDetails = lazy(() => import("./types-risk-analysis/TypeRiskAnalysisDetails"));

const RepositoryEntryList = lazy(() => import("./repositories/RepositoryEntryList"));

const DXPList = lazy(() => import("./dxps/DXPList"));
const DXPDetails = lazy(() => import("./dxps/DXPDetails"));

const SessionList = lazy(() => import("./sessions/SessionList"));
const SessionCreation = lazy(() => import("./sessions/SessionCreation"));
const SessionDetails = lazy(() => import("./sessions/SessionDetails"));

const RisksAnalysisList = lazy(() => import("./risks-analysis/RisksAnalysisList"));
const RisksAnalysisCreation = lazy(() => import("./risks-analysis/RisksAnalysisCreation"));
const RisksAnalysisDetails = lazy(() => import("./risks-analysis/RisksAnalysisDetails"));
const RiskAnalysisSynthesis = lazy(() => import("./risks-analysis/RiskAnalysisSynthesis"));
const RiskAnalysisDuplication = lazy(() => import("./risks-analysis/RiskAnalysisDuplication"));

const NotFound = lazy(() => import("./not-found/NotFound"));

const AuthenticatedRoutes = withAuthenticationRequired(() => {
  const location = useLocation();

  const { hasPermission } = usePermissions();
  return (
    <Switch>
      <Route path="/" component={Dashboard} exact />

      <Route path="/tire-tests" component={TireTestList} exact />
      <Route path="/tire-tests/new" component={TireTestCreation} exact />
      <Route
        path={[
          "/tire-tests/:tireTestId",
          "/tire-tests/:tireTestId/request",
          "/tire-tests/:tireTestId/preparation",
          "/tire-tests/:tireTestId/execution",
          "/tire-tests/:tireTestId/analysis",
        ]}
        component={TireTestDetails}
        exact
      />
      <Route path="/tire-tests/:tireTestId/synthesis" component={TireTestSynthesis} exact />
      <Route path="/tire-tests/:tireTestId/duplicate" component={TireTestDuplication} exact />
      <Route
        path="/tire-tests/:tireTestId/duplicateAsChild"
        component={TireTestDuplicationAsChild}
        exact
      />

      <Route path="/tire-test-search" component={TireTestSearchPage} key={location.search} exact />
      <Route
        path="/risk-analysis-search"
        component={RiskAnalysisSearch}
        key={location.search}
        exact
      />

      <Route path="/dtos" component={DTOList} exact />
      <Route path="/dtos/new" component={DTOCreation} exact />
      <Route path="/dtos/:dtoId" component={DTODetails} exact />

      <Route path="/tires" component={TireList} exact />
      <Route path="/tires/new" component={TireCreation} exact />
      <Route path="/tires/:tireId" component={TireDetails} exact />

      <Route path="/rims" component={RIMList} exact />
      <Route path="/rims/:rimId" component={RIMDetails} exact />

      <Route path="/defects" component={DefectList} exact />
      <Route path="/defects/new" component={DefectCreation} exact />
      <Route path="/defects/:defectId" component={DefectDetails} exact />

      <Route path="/circuits" component={CircuitList} exact />
      <Route path="/circuits/new" component={CircuitCreation} exact />
      <Route path="/circuits/:circuitId" component={CircuitDetails} exact />

      <Route path="/types-risk-analysis" component={TypeRiskAnalysisList} exact />
      <Route path="/types-risk-analysis/new" component={TypeRiskAnalysisCreation} exact />
      <Route
        path="/types-risk-analysis/:typeRiskAnalysisId"
        component={TypeRiskAnalysisDetails}
        exact
      />

      <Route path="/repositories/:repositoryKey" component={RepositoryEntryList} exact />

      <Route path="/dxps" component={DXPList} exact />
      {hasPermission("admin") && <Route path="/dxps/:DXPId" component={DXPDetails} exact />}

      <Route path="/dxps/:DXPId" component={DXPDetails} exact />

      <Route path="/sessions" component={SessionList} exact />
      <Route path="/sessions/new" component={SessionCreation} exact />
      <Route path="/sessions/:sessionId" component={SessionDetails} exact />

      <Route path="/risks-analysis" component={RisksAnalysisList} exact />
      <Route path="/risks-analysis/new" component={RisksAnalysisCreation} exact />
      <Route path="/risks-analysis/:riskAnalysisId" component={RisksAnalysisDetails} exact />
      <Route
        path="/risks-analysis/:riskAnalysisId/duplicate"
        component={RiskAnalysisDuplication}
        exact
      />
      <Route
        path="/risks-analysis/:riskAnalysisId/synthesis"
        component={RiskAnalysisSynthesis}
        exact
      />

      <Route
        path={[
          "/risks-analysis/:riskAnalysisId",
          "/risks-analysis/:riskAnalysisId/context",
          "/risks-analysis/:riskAnalysisId/analysis",
          "/risks-analysis/:riskAnalysisId/decision",
          "/risks-analysis/:riskAnalysisId/feedback",
        ]}
        component={RisksAnalysisDetails}
        exact
      />

      <Route path="*" component={NotFound} exact />
    </Switch>
  );
});

export const Routes = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <Layout>
        <PageSkeleton />
      </Layout>
    );
  }
  return (
    <Layout>
      <Suspense fallback={<PageSkeleton />}>
        <AuthenticatedRoutes />
      </Suspense>
    </Layout>
  );
};
