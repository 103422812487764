/** @jsxImportSource @emotion/react */
import { Accordion, useAccordionItemContext } from "@reach/accordion";
import "@reach/accordion/styles.css";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { Badge, BadgeDot } from "../shared/Badge";
import { PrimaryLinkButton } from "../shared/Button";
import { formatDate } from "../shared/formatDate";
import {
  AdjustmentsOutlineIcon,
  ArrowsExpandOutlineIcon,
  BarCodeIcon,
  BookmarkOutlineIcon,
  CalendarOutlineIcon,
  LocationMarkerOutlineIcon,
  LockClosedIcon,
  SettingsOutlineIcon,
} from "../shared/Icons";
import {
  ListItem,
  ListItemContent,
  ListItemEmptyMetaTag,
  ListItemMeta,
  ListItemMetaTag,
  ListItemRow,
} from "../shared/List";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import { upperFirst } from "../shared/upperFirst";
import { PositionOnVehicleShortLabel } from "./PositionOnVehicle";
import {
  TireTestCalulTest,
  TireTestDefects,
  TireTestDeltaPressure,
  TireTestDXPName,
  TireTestFirstRunReference,
  TireTestFxMax,
  TireTestFxMean,
  TireTestFyMax,
  TireTestFyMean,
  TireTestFzMax,
  TireTestFzMean,
  TireTestNothingToDeclare,
  TireTestPeakZ,
  TireTestQuantity,
  TireTestSerialNumber,
  TireTestVMax,
  TireTestVMean,
  TireTestWeightAfterTest,
  TireTestWeightBeforeTest,
} from "./TireTestDescriptionTerms";
import { CamberFormatter, NumberFormatter, PressureFormatter } from "./UnitFormatters";
import { useTireTestName } from "./useTireTestName";

/** @typedef {{ id: import("./TireTestQueries").TireTestStatus, name: string, color: string }} Status */
/** @type {{ [index: string]: Status} */
export const statuses = {
  archived: { id: "archived", name: "Archived", color: "gray" },
  draft: { id: "draft", name: "Draft", color: "gray" },
  ready: { id: "ready", name: "Requested", color: "yellow" },
  planned: { id: "planned", name: "Planned", color: "yellow" },
  tested: { id: "tested", name: "Tested", color: "green" },
  analysed: { id: "analysed", name: "Analysed", color: "green" },
};

export const StatusBadge = ({ status }) => {
  const foundStatus = statuses[status];
  if (!foundStatus) {
    return null;
  }
  return (
    <Badge color={foundStatus.color}>
      <BadgeDot />
      {foundStatus.name}
    </Badge>
  );
};

export const RegderBadge = ({ isREGDER, regdersCount }) => {
  if (!isREGDER) {
    return null;
  }
  return (
    <Badge color="indigo">
      REGDER {`${regdersCount !== undefined ? `(${regdersCount})` : ""}`}
    </Badge>
  );
};

/** @type {(tireTest: import("./TireTestQueries").TireTest) => string} */
const TireTestDateLabel = ({ tireTest }) => {
  switch (tireTest.status) {
    case "tested":
    case "analysed":
      return tireTest.testedDate
        ? `Tested on ${formatDate(tireTest.testedDate)}`
        : "Not tested yet";
    case "planned":
      return tireTest.meta?.session
        ? `Planned on ${formatDate(tireTest.meta.session.from)}`
        : "Not planned yet";
    default:
      return tireTest.dueDate ? `Due on ${formatDate(tireTest.dueDate)}` : "Not due yet";
  }
};

/** @type {(stages: import("./TireTestQueries").TireTest) => number} */
const getTotalCompletion = (tireTest) => {
  if (!tireTest || !tireTest.stages) {
    // If test is not executed, stages might not be filled yet.
    return 0;
  }
  // Sum all stage completions, e.g.:
  // - stage 1: 100%
  // - stage 2: 100%
  // - stage 3: 42%
  // - stage 4: undefined
  // total completion will be 242
  const totalCompletion = tireTest.stages
    .map((stage) => stage?.completion)
    .filter((completion) => completion !== undefined && !Number.isNaN(completion))
    .reduce((totalCompletion, completion) => totalCompletion + completion, 0);
  // Divide by 100 to display as stage completion (e.g.: 2.42 / 4)
  return totalCompletion / 100;
};

/** @type {(stages: import("./TireTestQueries").TireTest) => number} */
const getRequestedStageCount = (tireTest) => {
  if (!tireTest || !tireTest.requestedStageCount) {
    return 0;
  }
  return tireTest.requestedStageCount;
};

/** @type {(tireTest: import("./TireTestQueries").TireTest) => boolean} */
const isTireTestInError = (tireTest) => {
  return (
    tireTest.stages?.find((stage) => stage.status === "failed") ||
    tireTest.defects?.find((defect) => defect.checked === true) ||
    tireTest.regders[tireTest.regders.length - 1]?.status === "failed"
  );
};

export const TireTestListItemSkeleton = ({ as: Component = ListItem }) => {
  return (
    <Component>
      <ListItemContent>
        <ListItemRow>
          <Skeleton />
          <BadgeSkeleton />
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <BookmarkOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <LocationMarkerOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <AdjustmentsOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
          </ListItemMeta>

          <ListItemMetaTag as="p">
            <CalendarOutlineIcon />
            <Skeleton />
          </ListItemMetaTag>
        </ListItemRow>
      </ListItemContent>
    </Component>
  );
};

export const TireTestMetaTags = ({ tireTest, MetaTag, EmptyMetaTag }) => {
  return (
    <>
      <MetaTag>
        <BookmarkOutlineIcon width={24} height={24} />
        <span tw="truncate">{tireTest.category || <EmptyMetaTag />}</span>
      </MetaTag>
      <MetaTag>
        <LocationMarkerOutlineIcon width={24} height={24} />
        <span tw="truncate">{tireTest.circuit || <EmptyMetaTag />}</span>
      </MetaTag>
      <MetaTag>
        <AdjustmentsOutlineIcon width={24} height={24} />
        <span tw="truncate">
          <PressureFormatter>{tireTest.pressureInBars}</PressureFormatter>
          {" ꞏ "}
          <CamberFormatter>{tireTest.staticCamberInDegrees}</CamberFormatter>
        </span>
      </MetaTag>
      <MetaTag>
        <ArrowsExpandOutlineIcon width={24} height={24} />
        <span tw="truncate">
          {PositionOnVehicleShortLabel[tireTest.positionOnVehicle] || <EmptyMetaTag />}
        </span>
      </MetaTag>

      <MetaTag>
        <BarCodeIcon width={24} height={24} />
        <span tw="truncate">{tireTest.meta?.tire?.tirf || <EmptyMetaTag />}</span>
      </MetaTag>

      <MetaTag>
        <SettingsOutlineIcon width={24} height={24} />
        <span tw="truncate">
          {(tireTest.machinePosition && upperFirst(tireTest.machinePosition)) || <EmptyMetaTag />}
        </span>
      </MetaTag>
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
const TireTestStatusBadge = ({ tireTest }) => {
  const status = tireTest.status;
  const statusName = statuses[status] ? statuses[status].name : status;
  const statusColor = statuses[status] ? statuses[status].color : "gray";
  const totalCompletion = getTotalCompletion(tireTest);
  const requestedStageCount = getRequestedStageCount(tireTest);
  switch (tireTest.status) {
    case "tested":
    case "analysed":
      return (
        <Badge
          color={isTireTestInError(tireTest) ? "red" : "green"}
          title={`This test has status "${statusName}" with ${totalCompletion} stages completed out of ${requestedStageCount} requested.`}
        >
          <BadgeDot />
          <span>
            {statusName} (
            <NumberFormatter maximumFractionDigits={2}>{totalCompletion}</NumberFormatter>/
            {requestedStageCount})
          </span>
        </Badge>
      );
    case "ready":
    case "planned":
      return (
        <Badge
          color={statusColor}
          title={`This test has status "${statusName}" with ${totalCompletion} stages requested.`}
        >
          <BadgeDot />
          <span>
            {statusName} ({requestedStageCount})
          </span>
        </Badge>
      );
    default:
      return (
        <Badge color={statusColor} title={`This test has status "${statusName}".`}>
          <BadgeDot />
          {statusName}
        </Badge>
      );
  }
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestListItemSummary = ({ tireTest }) => {
  const tireTestName = useTireTestName(tireTest);
  return (
    <>
      <ListItemRow>
        <div tw="flex items-center space-x-2 truncate">
          <PrimaryLinkButton as={Link} to={`/tire-tests/${tireTest._id}`} tw="truncate">
            {tireTestName}
          </PrimaryLinkButton>
          <RegderBadge isREGDER={tireTest.isREGDER} regdersCount={tireTest.regders?.length} />
          {tireTest.isLocked && (
            <LockClosedIcon tw="-mr-1 ml-2 h-3 w-3 text-gray-500 flex-shrink-0" />
          )}
        </div>
        <TireTestStatusBadge tireTest={tireTest} />
      </ListItemRow>
      <ListItemRow>
        <ListItemMeta>
          <TireTestMetaTags
            tireTest={tireTest}
            MetaTag={ListItemMetaTag}
            EmptyMetaTag={ListItemEmptyMetaTag}
          />
        </ListItemMeta>

        <ListItemMetaTag as="p">
          <CalendarOutlineIcon width={24} height={24} />
          <span>
            <TireTestDateLabel tireTest={tireTest} />
          </span>
        </ListItemMetaTag>
      </ListItemRow>
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestListItem = ({ tireTest }) => {
  return (
    <ListItem>
      <ListItemContent>
        <TireTestListItemSummary tireTest={tireTest} />
      </ListItemContent>
    </ListItem>
  );
};

export const TireTestAccordion = forwardRef((props, ref) => {
  return <Accordion ref={ref} as="ul" multiple collapsible {...props} />;
});

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestAccordionPanelContent = ({ tireTest }) => {
  const { isExpanded } = useAccordionItemContext();
  return isExpanded ? (
    <div tw="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-2">
      <dl tw="space-y-1">
        <TireTestVMean tireTest={tireTest} />
        <TireTestVMax tireTest={tireTest} />
        <TireTestFzMean tireTest={tireTest} />
        <TireTestFzMax tireTest={tireTest} />
        <TireTestFyMean tireTest={tireTest} />
        <TireTestFyMax tireTest={tireTest} />
        <TireTestFxMean tireTest={tireTest} />
        <TireTestFxMax tireTest={tireTest} />
        <TireTestPeakZ tireTest={tireTest} />
        <TireTestDXPName tireTest={tireTest} />
      </dl>
      <div tw="space-y-1">
        <TireTestFirstRunReference tireTest={tireTest} />
        <TireTestSerialNumber tireTest={tireTest} />
        <TireTestNothingToDeclare tireTest={tireTest} />
        <TireTestDefects tireTest={tireTest} />
        <TireTestQuantity tireTest={tireTest} />
        <TireTestDeltaPressure tireTest={tireTest} />
        {tireTest.weightBeforeTest && <TireTestWeightBeforeTest tireTest={tireTest} />}
        {tireTest.weightAfterTest && <TireTestWeightAfterTest tireTest={tireTest} />}
        <TireTestCalulTest tireTest={tireTest} />
      </div>
    </div>
  ) : null;
};
