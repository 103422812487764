// @ts-check
import { useQuery } from "react-query";
import { useApi } from "../shared/useApi";

export const USER = "user";

export const useUserQuery = (searchParams = {}) => {
  const api = useApi();
  return useQuery([USER, searchParams], async () => {
    const response = await api.get(`v1/users`);
    const list = await response.json();
    return {
      list,
    };
  });
};
