/** @jsxImportSource @emotion/react */
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { CircuitAutocomplete } from "../dtos/CircuitAutocomplete";
import { DTOAutocomplete } from "../dtos/DTOAutocomplete";
import FileInput from "../files/FileInput";
import { NotificationDisplay } from "../notifications/NotificationDisplay";
import { RepositoryAutocomplete } from "../repositories/RepositoryAutocomplete";
import { Button, PrimaryButton } from "../shared/Button";
import { CustomSelect } from "../shared/CustomSelect";
import {
  DateInput,
  FieldHelperText,
  FieldsetLegend,
  FormGroup,
  HelperText,
  Input,
  Label,
  MaskedInput,
  NumberInput,
  OptionalLabel,
} from "../shared/Form";
import { Panel, PanelContent } from "../shared/Panel";
import SlideOver from "../shared/SlideOver";
import { Spinner } from "../shared/Spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeading,
  TableRow,
  TableRowLabel,
} from "../shared/Table";
import TinyEditorComponent from "../shared/TextEditor";
import { useTypesRiskAnalysisQuery } from "../types-risk-analysis/TypeRiskAnalysisQueries";
import { UserAutocomplete } from "../user/UserAutocomplete";
import { UserMultiSelect } from "../user/UserMultiSelect";
import { RISKS_ANALYSIS, useUpsertRiskAnalysisMutation } from "./RiskAnalysisQueries";
import { v4 as uuid } from "uuid";
import { empty } from "./RiskAnalysisDescriptionTerms";
import { riskAnalysisStatus } from "./RiskAnalysisStatus";

/* SIZE_LIMIT
 ** 524288  === 0.5Mb (0.524288)
 */
const SIZE_LIMIT = 524288;

export const DimensionsTargets = ({
  riskAnalysisContextFormContext,
  deleteDimension,
  editDimension,
  setSlideOverTarget,
  editTarget,
  deleteTarget,
  customNoDimensionsMsg,
  customCls,
  displayActions = false,
}) => {
  return riskAnalysisContextFormContext.dimensionsStudies?.length > 0 ? (
    <div tw="flex flex-col" className={customCls ? customCls : ""}>
      {riskAnalysisContextFormContext.dimensionsStudies.map((ds, index) => {
        return (
          <div
            tw="relative mb-6 flex flex-col bg-gray-100 rounded-lg shadow-lg"
            className="dimension-studies-child"
            key={`dimensionsStudies-${index}`}
          >
            {displayActions && (
              <TrashIcon
                tw="bg-gray-300 p-1 text-gray-600 absolute top-0.5 right-0.5 w-7 h-7 rounded-lg cursor-pointer"
                onClick={() => {
                  if (deleteDimension) {
                    deleteDimension(ds);
                  }
                }}
              />
            )}
            <div
              tw="flex w-full my-4 justify-between py-4 pl-8 pr-16"
              className="dimensions-header"
            >
              <div tw="flex flex-col">
                <div tw="font-size[13px] font-medium">Dimension</div>
                <div tw="text-gray-600 mt-1">{ds.dimension}</div>
              </div>
              <div tw="flex flex-col">
                <div tw="font-size[13px] font-medium">Spec</div>
                <div tw="text-gray-600 mt-1">{ds.spec || empty}</div>
              </div>
              <div tw="flex flex-col">
                <div tw="font-size[13px] font-medium">DTO</div>
                <div tw="flex items-end mt-1">
                  <div tw="text-gray-600 ">{ds.dto}</div>
                  <div tw="text-sm text-gray-600 ml-1.5">{ds.dtoComplement}</div>
                </div>
              </div>
              <div tw="flex flex-col">
                <div tw="font-size[13px] font-medium">Volumes</div>
                <div tw="text-gray-600 mt-1">{ds.volume || empty}</div>
              </div>
              <div tw="flex flex-col">
                <div tw="font-size[13px] font-medium">CAI</div>
                <div tw="text-gray-600 mt-1">{ds.cai || empty}</div>
              </div>
              <div tw="flex items-center ">
                <div tw="flex flex-col">
                  <div tw="font-size[13px] font-medium">Factory</div>
                  <div tw="text-gray-600 mt-1">{ds.factory}</div>
                </div>
                {displayActions && (
                  <PencilIcon
                    tw="ml-8 w-4 h-4 cursor-pointer"
                    onClick={() => {
                      if (editDimension) {
                        editDimension(ds);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div tw="w-full h-full rounded-b-lg p-4 bg-white" className="dimensions-targets">
              {displayActions && (
                <div
                  tw="flex items-center justify-center"
                  css={ds.targets?.length > 0 && tw`justify-end`}
                >
                  {(!ds.targets || ds.targets?.length === 0) && (
                    <div tw="text-center text-sm font-normal text-gray-600">
                      There isn't any target. You can add target by clicking on "Add" button
                    </div>
                  )}
                  <div tw="flex justify-end">
                    <Button
                      tw="ml-4 mb-2"
                      type="button"
                      onClick={() => {
                        if (setSlideOverTarget) {
                          setSlideOverTarget({
                            dimension: ds,
                            data: null,
                            display: true,
                          });
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}

              {ds.targets?.length > 0 && (
                <Table tw="w-10/12 margin[0 auto]">
                  <TableHead>
                    <TableRow tw="bg-gray-50 block md:(display[table-row]) border-t border-l border-r border-gray-200">
                      <TableHeading tw="w-1/4 border-r border-gray-200">Circuit</TableHeading>
                      <TableHeading tw="w-1/4 border-r border-gray-200">Usage type</TableHeading>
                      {displayActions && <TableHeading tw="w-1/12"></TableHeading>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ds.targets.map((target, index) => {
                      return (
                        <TableRow tw="block md:(display[table-row])" key={`targets-${index}`}>
                          <TableRowLabel tw="border-r border-gray-200">
                            {target.circuit}
                          </TableRowLabel>
                          <TableRowLabel css={displayActions && tw`border-r border-gray-200`}>
                            {target.usageType}
                          </TableRowLabel>
                          {displayActions && (
                            <TableCell tw="flex p-4">
                              <PencilIcon
                                tw="w-4 h-4 cursor-pointer"
                                onClick={() => {
                                  if (editTarget) {
                                    editTarget(ds, target);
                                  }
                                }}
                              />
                              <TrashIcon
                                tw="w-4 h-4 ml-4 cursor-pointer"
                                onClick={() => {
                                  if (deleteTarget) {
                                    deleteTarget(ds, target);
                                  }
                                }}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div tw="text-center text-sm font-normal text-gray-600">
      {customNoDimensionsMsg
        ? customNoDimensionsMsg
        : `There isn't any tire dimensions to analyse. You can add dimension by clicking on "Add" button`}
    </div>
  );
};

const DimensionsBlock = () => {
  const [slideOver, setSlideOver] = useState({
    data: null,
    display: false,
  });
  const [slideOverTarget, setSlideOverTarget] = useState({
    dimension: null,
    data: null,
    display: false,
  });

  const formDimensionsProps = useForm({
    defaultValues: {
      volume: 0,
    },
  });

  const formTargetsProps = useForm({});

  const [dtoDimension, setDtoDimension] = useState(null);
  const { handleSubmit: handleSubmitD, setValue: setValueD } = formDimensionsProps;
  const { handleSubmit: handleSubmitT, setValue: setValueT } = formTargetsProps;
  const riskAnalysisContextFormContext = useContext(RiskAnalysisContextFormContext);

  useEffect(() => {
    setValueD("dimension", slideOver.data?.dimension);
    setValueD("dto", slideOver.data?.dto);
    setValueD("dtoComplement", slideOver.data?.dtoComplement);
    setValueD("spec", slideOver.data?.spec);
    setValueD("volume", slideOver.data?.volume);
    setValueD("cai", slideOver.data?.cai);
    setValueD("factory", slideOver.data?.factory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOver]);

  useEffect(() => {
    setValueT("circuit", slideOverTarget.data?.circuit);
    setValueT("usageType", slideOverTarget.data?.usageType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOverTarget]);

  const onValidateSlideOver = () => {
    setSlideOver({
      data: null,
      display: false,
    });
  };

  const onValidDimension = (data) => {
    if (slideOver.data) {
      // is edition
      const editedDsIdx = riskAnalysisContextFormContext.dimensionsStudies.findIndex(
        (e) => e.id === slideOver.data.id
      );
      const ds = [...riskAnalysisContextFormContext.dimensionsStudies];
      ds[editedDsIdx] = {
        ...data,
        id: ds[editedDsIdx].id,
        ...(dtoDimension && { dimension: dtoDimension }),
        ...(slideOver.data.dimension && !dtoDimension && { dimension: slideOver.data.dimension }),
        targets: ds[editedDsIdx].targets,
      };
      riskAnalysisContextFormContext.setDimensionsStudies(ds);
    } else {
      // is creation
      const objToAdd = {
        ...data,
        id: uuid(),
        ...(dtoDimension && { dimension: dtoDimension }),
      };
      const ds = riskAnalysisContextFormContext.dimensionsStudies
        ? [...riskAnalysisContextFormContext.dimensionsStudies, objToAdd]
        : [objToAdd];
      riskAnalysisContextFormContext.setDimensionsStudies(ds);
    }
    onValidateSlideOver();
  };

  const editDimension = (dimension) => {
    setSlideOver({
      data: dimension,
      display: true,
    });
  };

  const deleteDimension = (dimension) => {
    const ds = riskAnalysisContextFormContext.dimensionsStudies.filter((e) => {
      if (e.id === dimension.id) {
        return false;
      }
      return true;
    });
    riskAnalysisContextFormContext.setDimensionsStudies(ds);
    onValidateSlideOver();
  };

  const onValidateSlideOverTarget = () => {
    setSlideOverTarget({
      dimension: null,
      data: null,
      display: false,
    });
  };

  const onValidTarget = (data) => {
    const ds = [...riskAnalysisContextFormContext.dimensionsStudies];
    const editedDsIdx = riskAnalysisContextFormContext.dimensionsStudies.findIndex(
      (e) => e.id === slideOverTarget.dimension?.id
    );
    if (slideOverTarget.data) {
      // is edition
      const editedTargetIdx = riskAnalysisContextFormContext.dimensionsStudies[
        editedDsIdx
      ]?.targets.findIndex((e) => e.id === slideOverTarget.data.id);
      ds[editedDsIdx].targets[editedTargetIdx] = {
        ...ds[editedDsIdx].targets[editedTargetIdx],
        circuit: data.circuit,
        usageType: data.usageType,
      };
      riskAnalysisContextFormContext.setDimensionsStudies(ds);
    } else {
      // is creation
      const targetToAdd = { ...data, id: uuid() };
      if (!ds[editedDsIdx].targets) {
        ds[editedDsIdx].targets = [targetToAdd];
      } else {
        ds[editedDsIdx].targets = [...ds[editedDsIdx].targets, targetToAdd];
      }
      riskAnalysisContextFormContext.setDimensionsStudies(ds);
    }
    onValidateSlideOverTarget();
  };

  const editTarget = (dimension, target) => {
    setSlideOverTarget({
      dimension,
      data: target,
      display: true,
    });
  };

  const deleteTarget = (dimension, target) => {
    const ds = [...riskAnalysisContextFormContext.dimensionsStudies];
    const editedDsIdx = riskAnalysisContextFormContext.dimensionsStudies.findIndex(
      (e) => e.id === dimension.id
    );
    ds[editedDsIdx].targets = ds[editedDsIdx].targets.filter((e) => {
      if (e.id === target.id) {
        return false;
      }
      return true;
    });
    riskAnalysisContextFormContext.setDimensionsStudies(ds);
    onValidateSlideOverTarget();
  };

  return (
    <>
      <div tw="flex justify-between items-center mb-4">
        <div tw="text-lg leading-6 font-medium text-gray-900">Dimensions to analyse</div>
        <Button
          type="button"
          onClick={() => {
            setSlideOver({
              data: null,
              display: true,
            });
            setDtoDimension(null);
          }}
        >
          Add
        </Button>
      </div>

      <DimensionsTargets
        riskAnalysisContextFormContext={riskAnalysisContextFormContext}
        deleteDimension={deleteDimension}
        editDimension={editDimension}
        setSlideOverTarget={setSlideOverTarget}
        editTarget={editTarget}
        deleteTarget={deleteTarget}
        displayActions={true}
      />

      {slideOver.display && (
        <SlideOver
          title={slideOver.data ? "Edit a dimension" : "Add a dimension"}
          subtitle={slideOver.data ? "Edit a dimension to analyse" : "Add a dimension to analyse"}
          closeCallback={() => {
            setSlideOver({
              data: null,
              display: false,
            });
            setDtoDimension(null);
          }}
        >
          <div tw="flex flex-col">
            <FormProvider {...formDimensionsProps}>
              <form tw="space-y-6">
                <div tw="grid grid-cols-3 gap-6">
                  <FormGroup tw="mb-4 col-span-2" name="dto">
                    <Label>DTO</Label>
                    <DTOAutocomplete
                      rules={{ required: true }}
                      initialDimension={slideOver?.data?.dimension}
                      displayDimension={true}
                      dimensionUpdateCallback={(d) => {
                        setDtoDimension(d);
                      }}
                    />
                    <FieldHelperText />
                  </FormGroup>

                  <FormGroup tw="mb-4" name="dtoComplement">
                    <Label>complement</Label>
                    <Input />
                    <FieldHelperText />
                  </FormGroup>
                </div>

                <FormGroup tw="mb-4" name="volume">
                  <div tw="flex justify-between">
                    <Label>Envelop volumes</Label>
                    <OptionalLabel />
                  </div>
                  <NumberInput min={0} />
                </FormGroup>

                <FormGroup tw="mb-4" name="cai">
                  <div tw="flex justify-between">
                    <Label>CAI</Label>
                    <OptionalLabel />
                  </div>
                  <MaskedInput
                    placeholder=""
                    mask="999999"
                    maskChar={null}
                    rules={{
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "Invalid CAI format (999999 expected)",
                      },
                    }}
                  />
                  <FieldHelperText />
                </FormGroup>

                <FormGroup tw="mb-4" name="spec">
                  <div tw="flex justify-between">
                    <Label>Spec</Label>
                    <OptionalLabel />
                  </div>
                  <Input />
                  <FieldHelperText />
                </FormGroup>

                <FormGroup tw="mb-4" name="factory">
                  <Label>Factory</Label>
                  <RepositoryAutocomplete
                    rules={{ required: true }}
                    repositoryKey="factories-analysis-risk"
                  />
                  <FieldHelperText />
                </FormGroup>
              </form>
            </FormProvider>
          </div>

          <PrimaryButton
            tw="float-right mt-4"
            type="button"
            onClick={(e) => {
              // handleSubmit(onValid)(e);
              handleSubmitD(onValidDimension)(e);
            }}
          >
            Validate
          </PrimaryButton>
        </SlideOver>
      )}

      {slideOverTarget.display && (
        <SlideOver
          title={slideOverTarget.data ? "Edit a target" : "Add a target"}
          subtitle={slideOverTarget.data ? "Edit a target to reach" : "Add a target to reach"}
          closeCallback={() => {
            setSlideOverTarget({
              dimension: null,
              data: null,
              display: false,
            });
          }}
        >
          <div tw="flex flex-col">
            <FormProvider {...formTargetsProps}>
              <form tw="space-y-6">
                <FormGroup name="circuit">
                  <Label>Circuit</Label>
                  <CircuitAutocomplete />
                  <FieldHelperText />
                </FormGroup>

                <FormGroup name="usageType">
                  <Label>Usage type</Label>
                  <CustomSelect
                    options={[
                      { label: "Essai", value: "Essai" },
                      { label: "Course", value: "Course" },
                      { label: "Short Run", value: "Short Run" },
                      { label: "Long Run", value: "Long Run" },
                    ]}
                  />
                  <FieldHelperText />
                </FormGroup>
              </form>
            </FormProvider>
          </div>

          <PrimaryButton
            tw="float-right mt-4"
            type="button"
            onClick={(e) => {
              // handleSubmit(onValid)(e);
              handleSubmitT(onValidTarget)(e);
            }}
          >
            Validate
          </PrimaryButton>
        </SlideOver>
      )}
    </>
  );
};

export const RiskAnalysisContextFormContext = React.createContext();

/** @type {React.FC<{ tireTest: import("./RiskAnalysisQueries").TireTest }>} */
export const RiskContextForm = ({ riskAnalysis }) => {
  const defaultValues = {
    ...riskAnalysis,
    date: riskAnalysis.date ? riskAnalysis.date : new Date().toISOString(),
  };

  const history = useHistory();
  const queryClient = useQueryClient();

  const formProps = useForm({ defaultValues });
  const { handleSubmit, control, setValue } = formProps;
  const { status, mutateAsync: upsertRiskAnalysis } = useUpsertRiskAnalysisMutation();

  const level = useWatch({ name: "level", control });
  const analysisCategory = useWatch({ name: "analysisCategory", control });

  let riskAnalysisTypes = [];
  const { data: typeRiskAnalysis } = useTypesRiskAnalysisQuery({
    type: analysisCategory,
  });
  if (typeRiskAnalysis) {
    riskAnalysisTypes = typeRiskAnalysis.list.map((t) => {
      return { label: t.label, value: t.label };
    });
  }

  const editorRef = useRef(null);
  const [sizeExceed, setSizeExceed] = useState(false);

  const [dimensionsStudies, setDimensionsStudies] = useState(riskAnalysis?.dimensionsStudies);

  useEffect(() => {
    if (level === "Niv 1") {
      setValue("experts", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  const onValid = async (data) => {
    let initialSituation;
    if (editorRef.current) {
      initialSituation = editorRef.current.getContent();
    }

    if (initialSituation?.length >= SIZE_LIMIT) {
      if (!sizeExceed) {
        setSizeExceed(true);

        setTimeout(() => {
          setSizeExceed(false);
        }, 3000);
      }
    } else {
      if (sizeExceed) {
        setSizeExceed(false);
      }
      const dataToSend = {
        ...data,
        dimensionsStudies: dimensionsStudies,
        initialSituation: initialSituation,
      };
      await upsertRiskAnalysis(
        {
          _id: riskAnalysis._id,
          ...(riskAnalysis.status === riskAnalysisStatus.draft && {
            status: riskAnalysisStatus.filled,
          }),
          ...dataToSend,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(RISKS_ANALYSIS);
            queryClient.removeQueries(RISKS_ANALYSIS);
            queryClient.removeQueries([RISKS_ANALYSIS, riskAnalysis._id]);
            history.push(`/risks-analysis/${riskAnalysis._id}/analysis`);
          },
        }
      );
    }
  };

  return (
    <>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(onValid)} tw="space-y-6">
          <Panel>
            <PanelContent>
              <fieldset>
                <FieldsetLegend>General information</FieldsetLegend>
                <HelperText>
                  General information about this risk analysis to help you identify it.
                </HelperText>

                <FormGroup name="title">
                  <Label>Title</Label>
                  <Input rules={{ required: true }} />
                  <FieldHelperText />
                </FormGroup>

                <div tw="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-6">
                  <FormGroup name="developer.name">
                    <Label>Developer</Label>
                    <UserAutocomplete rules={{ required: true }} />
                    <FieldHelperText />
                  </FormGroup>

                  <div tw="flex col-span-2">
                    <FormGroup name="level" tw="w-1/4">
                      <Label>Processing level</Label>
                      <RepositoryAutocomplete
                        repositoryKey="levels-analysis-risk"
                        rules={{ required: true }}
                      />
                      <FieldHelperText />
                    </FormGroup>

                    <FormGroup name="experts" tw="ml-6 w-3/4">
                      <Label>Experts</Label>
                      <UserMultiSelect disabled={level === "Niv 1"} />
                      <FieldHelperText />
                    </FormGroup>
                  </div>

                  <FormGroup name="date">
                    <Label>Date</Label>
                    <DateInput rules={{ required: true }} />
                    <FieldHelperText />
                  </FormGroup>

                  <FormGroup name="type">
                    <Label>Type</Label>
                    <RepositoryAutocomplete
                      repositoryKey="types-analysis-risk"
                      rules={{ required: true }}
                    />
                    <FieldHelperText />
                  </FormGroup>

                  <FormGroup name="category">
                    <Label>Category</Label>
                    <RepositoryAutocomplete repositoryKey="categories" rules={{ required: true }} />
                    <FieldHelperText />
                  </FormGroup>

                  <FormGroup name="analysisCategory">
                    <Label>Analyse category</Label>
                    <CustomSelect
                      options={[
                        { label: "Exploitation", value: "Exploitation" },
                        { label: "Fabrication / Stockage", value: "Fabrication / Stockage" },
                        { label: "Conception", value: "Conception" },
                      ]}
                      onChangeCallback={() => {
                        setValue("analysisType", null);
                      }}
                      rules={{ required: true }}
                    />
                    <FieldHelperText />
                  </FormGroup>

                  <FormGroup name="analysisType">
                    <Label>Analyse type</Label>
                    <CustomSelect
                      disabled={!analysisCategory}
                      options={riskAnalysisTypes}
                      rules={{ required: true }}
                    />
                    <FieldHelperText />
                  </FormGroup>
                </div>
              </fieldset>
            </PanelContent>
          </Panel>

          <Panel>
            <PanelContent>
              <fieldset>
                <FieldsetLegend>Context</FieldsetLegend>
                <HelperText>Some information on the risks analysis context</HelperText>

                <div tw="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-6">
                  <FormGroup name="initialSituation" tw="col-span-full">
                    <div tw="flex justify-between">
                      <Label>Initial Situation / Context</Label>
                      <OptionalLabel />
                    </div>
                    <TinyEditorComponent
                      ref={editorRef}
                      initialValue={riskAnalysis.initialSituation}
                    />
                  </FormGroup>

                  <FormGroup name="contextFiles" tw="col-span-full">
                    <Label>Files</Label>
                    <FileInput />
                  </FormGroup>
                </div>
              </fieldset>
            </PanelContent>
          </Panel>

          <RiskAnalysisContextFormContext.Provider
            value={{ dimensionsStudies, setDimensionsStudies }}
          >
            <Panel>
              <PanelContent>
                <DimensionsBlock />
              </PanelContent>
            </Panel>
          </RiskAnalysisContextFormContext.Provider>

          <div tw="flex items-center justify-end space-x-3">
            <PrimaryButton type="submit" disabled={status === "loading"}>
              {status === "loading" && <Spinner tw="-ml-1 mr-3 h-5 w-5" />}
              {status === "loading" ? "Saving..." : "Submit"}
            </PrimaryButton>
          </div>

          <NotificationDisplay
            isOpen={sizeExceed}
            title={'Text size exceeded max size in "Initial Situation / Context"'}
            description="Please remove characters, images, or update image sizes"
          />
        </form>
      </FormProvider>
    </>
  );
};
