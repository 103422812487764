/** @jsxImportSource @emotion/react */
import { PencilIcon } from "@heroicons/react/outline";
import React, { useMemo } from "react";
import tw from "twin.macro";
import { metaDefectsCategories, useDefectsQuery } from "../defects/DefectQueries";
import { getSessionDescription, getSessionTitle } from "../sessions/SessionQueries";
import { Badge, BadgeDot } from "../shared/Badge";
import {
  DenseDescription,
  DenseDescriptionLabel,
  DenseDescriptionValue,
} from "../shared/DescriptionList";
import { formatDate } from "../shared/formatDate";
import { Filter } from "../shared/QueryHelpers";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import { upperFirst } from "../shared/upperFirst";
import { PositionOnVehicle, PositionOnVehicleShortLabel } from "./PositionOnVehicle";
import {
  CamberFormatter,
  DistanceFormatter,
  ForceFormatter,
  NumberFormatter,
  PercentageFormatter,
  PressureFormatter,
  UnitFormatter,
  VelocityFormatter,
} from "./UnitFormatters";
import { TriangleIcon } from "../shared/Icons";
import { CalculWeightBeforeMinusAfter } from "./TestAnalysisForm";

// The empty placeholder if a number is not defined
const empty = <span tw="text-gray-300 text-sm font-normal">n.c.</span>;

/** @type {React.FC<{ value: number }>} */
export const TireTestMargin = ({ value, ...props }) => {
  return (
    <span
      tw="text-xs font-medium"
      css={[
        value >= 0 && tw`text-green-600`,
        value < 0 && tw`text-red-600`,
        (value === undefined || value === null || Number.isNaN(value)) && tw`text-gray-300`,
      ]}
      {...props}
    />
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFzMax = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fz max</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fzMaxBase = tireTest.meta.dxp.fzMax[positionOnVehicle];
  const fzMaxDelta = fzMaxBase * (tireTest.fzUserMarginInPercent / 100);
  const fzMax = fzMaxBase + fzMaxDelta;
  const isCustomValue = Math.round(fzMax) !== tireTest.fzMaxTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fz max</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fzMaxTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fzUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fzUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFzMean = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fz mean</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fzAverageBase = tireTest.meta.dxp.fzAverage[positionOnVehicle];
  const fzAverageDelta = fzAverageBase * (tireTest.fzUserMarginInPercent / 100);
  const fzAverage = fzAverageBase + fzAverageDelta;
  const isCustomValue = Math.round(fzAverage) !== tireTest.fzAverageTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fz mean</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fzAverageTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fzUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fzUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestPeakZ = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Peak Z</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <ForceFormatter>{tireTest.fzPeakInNewtons}</ForceFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFyMax = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fy max</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fyMaxBase = tireTest.meta.dxp.fyMax[positionOnVehicle];
  const fyMaxDelta = fyMaxBase * (tireTest.fyUserMarginInPercent / 100);
  const fyMax = fyMaxBase + fyMaxDelta;
  const isCustomValue = Math.round(fyMax) !== tireTest.fyMaxTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fy max</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fyMaxTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fyUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fyUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFyMean = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fy mean</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fyMeanBase = tireTest.meta.dxp.fyAverage[positionOnVehicle];
  const fyMeanDelta = fyMeanBase * (tireTest.fyUserMarginInPercent / 100);
  const fyMean = fyMeanBase + fyMeanDelta;
  const isCustomValue = Math.round(fyMean) !== tireTest.fyAverageTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fy mean</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fyAverageTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fyUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fyUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFxMean = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fx mean</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fxMeanBase = tireTest.meta.dxp.fxAverage[positionOnVehicle];
  const fxMeanDelta = fxMeanBase * (tireTest.fxUserMarginInPercent / 100);
  const fxMean = fxMeanBase + fxMeanDelta;
  const isCustomValue = Math.round(fxMean) !== tireTest.fxAverageTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fx mean</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fxAverageTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fxUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fxUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFxMax = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fx max</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const fxMaxBase = tireTest.meta.dxp.fxMax[positionOnVehicle];
  const fxMaxDelta = fxMaxBase * (tireTest.fxUserMarginInPercent / 100);
  const fxMax = fxMaxBase + fxMaxDelta;
  const isCustomValue = Math.round(fxMax) !== tireTest.fxMaxTotal;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fx max</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <ForceFormatter>{tireTest.fxMaxTotal}</ForceFormatter>
        </span>
        <TireTestMargin value={tireTest.fxUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fxUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFxMargin = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Fx margin</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Fx margin</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <TireTestMargin value={tireTest.fxUserMarginInPercent}>
          (
          <PercentageFormatter signDisplay="always">
            {tireTest.fxUserMarginInPercent}
          </PercentageFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestVMean = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>V mean</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const averageVelocityBase = Math.round(tireTest.meta.dxp.averageVelocity);
  const averageVelocityDelta = tireTest.speedAverageUserMarginInKilometersPerHour;
  const averageVelocity = averageVelocityBase + averageVelocityDelta;
  const isCustomValue = Math.round(averageVelocity) !== tireTest.totalVelocityAverage;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>V mean</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <VelocityFormatter>{tireTest.totalVelocityAverage}</VelocityFormatter>
        </span>
        <TireTestMargin value={tireTest.speedAverageUserMarginInKilometersPerHour}>
          (
          <VelocityFormatter signDisplay="always">
            {tireTest.speedAverageUserMarginInKilometersPerHour}
          </VelocityFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestVMax = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>V max</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const maxVelocityBase = Math.round(tireTest.meta.dxp.maxVelocity);
  const maxVelocityDelta = tireTest.speedMaxUserMarginInKilometersPerHour;
  const maxVelocity = maxVelocityBase + maxVelocityDelta;
  const isCustomValue = Math.round(maxVelocity) !== tireTest.totalVelocityMax;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>V max</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <VelocityFormatter>{tireTest.totalVelocityMax}</VelocityFormatter>
        </span>
        <TireTestMargin value={tireTest.speedMaxUserMarginInKilometersPerHour}>
          (
          <VelocityFormatter signDisplay="always">
            {tireTest.speedMaxUserMarginInKilometersPerHour}
          </VelocityFormatter>
          )
        </TireTestMargin>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestQuantity = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Distance / stint</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        <span>
          <DistanceFormatter>{tireTest.distanceInKilometers}</DistanceFormatter>
          <span>{tireTest.requestedStageCount ? ` / ${tireTest.requestedStageCount}` : ''}</span>
        </span>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDeltaPressure = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Delta Pressure</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        <div tw="flex items-center gap-2">
          <div tw="flex items-center gap-0.5">
            <TriangleIcon tw="h-3 text-black" />
            <p>P</p>
            <p>=</p>
          </div>
          <PressureFormatter>{tireTest.pressureDeltaInBars}</PressureFormatter>
        </div>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ defect: import("./DefectQueries").Defect }>} */
export const DefectCategoryBadge = ({ defect: tireTestDefect }) => {
  const { status, data: defect } = useDefectsQuery({
    ...Filter.from({ status: "active", title: tireTestDefect.title }),
  });

  return (
    <>
      {status === "loading" && (
        <>
          <BadgeSkeleton />
          <Skeleton tw="w-10" />
        </>
      )}
      {status === "success" && (
        <Badge color={defect.list ? metaDefectsCategories[defect.list[0]?.category] : ""}>
          <BadgeDot />
          {defect.list ? defect.list[0]?.category : ""}
        </Badge>
      )}
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestNothingToDeclare = ({ tireTest }) => {
  return (
    <>
      {tireTest.status === "analysed" && tireTest.nothingToDeclare && (
        <DenseDescription>
          <DenseDescriptionLabel>Analysis</DenseDescriptionLabel>
          <DenseDescriptionValue>Nothing to declare</DenseDescriptionValue>
        </DenseDescription>
      )}
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDefects = ({ tireTest }) => {
  const { status, data: allDefects } = useDefectsQuery({ ...Filter.from({ status: "active" }) });

  const defects = useMemo(() => {
    if (!tireTest || !tireTest.defects) {
      return [];
    }

    const checkedDefects = tireTest.defects.filter((defect) => defect.checked);
    return checkedDefects.map((checkedDefect) => {
      const repositoryDefect =
        status === "success" &&
        allDefects.list.find((defect) => checkedDefect.title === defect.title);
      return {
        ...repositoryDefect,
        ...checkedDefect,
      };
    });
  }, [allDefects, status, tireTest]);

  return (
    <ul tw="space-y-1">
      {defects.map((defect, index) => (
        <li key={index} tw="flex items-center space-x-2 text-sm text-gray-500 truncate">
          <DefectCategoryBadge defect={defect} />
          <span>{defect.title}</span>
        </li>
      ))}
    </ul>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDXPName = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>DXP</DenseDescriptionLabel>
      <DenseDescriptionValue tw="break-all">
        {tireTest.dxp ? tireTest.dxp : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDTO = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>DTO</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.dto || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestRim = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Rim</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {(tireTest.tireRim ? tireTest.tireRim : tireTest.meta?.dto?.tireRim) || empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestPositionOnVehicle = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Position on vehicle</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {tireTest.positionOnVehicle
          ? PositionOnVehicleShortLabel[tireTest.positionOnVehicle]
          : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestRunfileName = ({ tireTest, ...props }) => {
  return (
    <DenseDescription {...props}>
      <DenseDescriptionLabel>Runfile name</DenseDescriptionLabel>
      <DenseDescriptionValue tw="break-all">
        {tireTest.runfileName ? tireTest.runfileName : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestIsREGDER = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Is REGDER?</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.isREGDER ? "Yes" : "No"}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestPressureDelta = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Pressure delta</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <PressureFormatter>{tireTest.pressureDeltaInBars}</PressureFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestPressureEvolution = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Pressure evolution</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {tireTest.pressureEvolutionInBars ? tireTest.pressureEvolutionInBars : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestLapCount = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Lap count</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <NumberFormatter>{tireTest.lapCount}</NumberFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestSerialNumber = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Serial number</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.meta?.tire?.serialNumber || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestWeightBeforeTest = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Weight before test</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <UnitFormatter unit="kilogram" maximumFractionDigits={2} minimumFractionDigits={0}>
          {tireTest.weightBeforeTest || empty}
        </UnitFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestWeightAfterTest = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Weight after test</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <UnitFormatter unit="kilogram" maximumFractionDigits={2} minimumFractionDigits={0}>
          {tireTest.weightAfterTest || empty}
        </UnitFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestCalulTest = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Weight difference</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <CalculWeightBeforeMinusAfter tireTest={tireTest} />
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestFirstRunReference = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>First run reference</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {(tireTest.stages[0] && tireTest.stages[0].runReference) || empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestTIRF = ({
  tireTest,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>TIRF ID</DescriptionLabel>
      <DescriptionValue>{tireTest.meta?.tire?.tirf || empty}</DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestMachinePosition = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Machine position</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {tireTest.machinePosition ? upperFirst(tireTest.machinePosition) : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestRequestedStageCount = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Stint number</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <NumberFormatter>{tireTest.requestedStageCount}</NumberFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestStintCompletionStageCount = ({ tireTest }) => {
  const getTotalCompletion = (tireTest) => {
    if (!tireTest || !tireTest.stages) {
      // If test is not executed, stages might not be filled yet.
      return 0;
    }
    // Sum all stage completions, e.g.:
    // - stage 1: 100%
    // - stage 2: 100%
    // - stage 3: 42%
    // - stage 4: undefined
    // total completion will be 242
    const totalCompletion = tireTest.stages
      .map((stage) => stage?.completion)
      .filter((completion) => completion !== undefined && !Number.isNaN(completion))
      .reduce((totalCompletion, completion) => totalCompletion + completion, 0);
    // Divide by 100 to display as stage completion (e.g.: 2.42 / 4)
    return totalCompletion / 100;
  };
  const totalCompletion = getTotalCompletion(tireTest);
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Stint completion</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <NumberFormatter maximumFractionDigits={2}>{totalCompletion}</NumberFormatter>/
        <NumberFormatter>{tireTest.requestedStageCount}</NumberFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDueDate = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Due date</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {tireTest.dueDate ? formatDate(tireTest.dueDate) : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestTestedDate = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Tested date</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {tireTest.testedDate ? formatDate(tireTest.testedDate) : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ session: import("./SessionQueries").Session }>} */
export const TireTestSession = ({ session }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Session</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {getSessionTitle(session)}
        <span tw="opacity-80 text-xs"> ({getSessionDescription(session)})</span>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ label: string, value?: (1 | 2 | 3 | 4) }>} */
export const TireTestAspect = ({ label, value }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>{label}</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {value ? (
          <>
            {value === 1 && "None"}
            {value === 2 && "Some"}
            {value === 3 && "Many"}
            {value === 4 && "Plenty"}
          </>
        ) : (
          empty
        )}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestType = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Type</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.type || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestTestMachine = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Test machine</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.testMachine || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestChampionship = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Championship</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.championship || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestCategory = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Category</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.category || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestProject = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Project</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.project || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestCircuit = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Circuit</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.circuit || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestTeam = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Team</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.team || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestCircuitSignalReference = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Circuit signal reference</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.circuitSignalReference || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestAlreadyTested = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Already tested</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <input
          type="checkbox"
          checked={tireTest.isAlreadyTested}
          disabled
          tw="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDevelopmentComments = ({ tireTest, ...props }) => {
  return (
    <DenseDescription {...props}>
      <DenseDescriptionLabel>Comments</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.testDevelopmentComments || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestStaticCamber = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Static camber</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <CamberFormatter>{tireTest.staticCamberInDegrees}</CamberFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestPressure = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Pressure</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <PressureFormatter>{tireTest.pressureInBars}</PressureFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestDistance = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Distance</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <DistanceFormatter>{tireTest.distanceInKilometers}</DistanceFormatter>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestTotalStaticCamber = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Static camber</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }
  const isCustomValue = tireTest.staticCamberInDegrees !== tireTest.totalStaticCamber;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Static camber</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        {isCustomValue && <PencilIcon tw="w-4 h-4 text-gray-700" />}
        <span>
          <CamberFormatter>{tireTest.totalStaticCamber}</CamberFormatter>
        </span>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestCamberDelta = ({ tireTest }) => {
  if (!tireTest.meta.dxp) {
    return (
      <DenseDescription>
        <DenseDescriptionLabel>Camber : nominal ref - user margin</DenseDescriptionLabel>
        <DenseDescriptionValue>{empty}</DenseDescriptionValue>
      </DenseDescription>
    );
  }

  const positionOnVehicle = tireTest.positionOnVehicle || PositionOnVehicle.FrontLeft;
  const delta = tireTest.meta.dxp.staticCamber[positionOnVehicle] - tireTest.totalStaticCamber;

  return (
    <DenseDescription>
      <DenseDescriptionLabel>Camber : nominal ref - user margin</DenseDescriptionLabel>
      <DenseDescriptionValue tw="flex items-center space-x-1">
        <span>
          <CamberFormatter>{delta}</CamberFormatter>
        </span>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TireTestREGDERFinalCoefficient = ({ tireTest }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel>Final coefficient</DenseDescriptionLabel>
      <DenseDescriptionValue>{tireTest.regderFinalCoefficient || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ dxp: import("../dxps/DXPQueries").DXP }>} */
export const DXPStaticMass = ({
  dxp,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Static mass</DescriptionLabel>
      <DescriptionValue>
        <UnitFormatter unit="kilogram" maximumFractionDigits={0} minimumFractionDigits={0}>
          {dxp.staticMass}
        </UnitFormatter>
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dxp: import("../dxps/DXPQueries").DXP }>} */
export const DXPWD = ({
  dxp,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>WD</DescriptionLabel>
      <DescriptionValue>
        <NumberFormatter maximumFractionDigits={2} minimumFractionDigits={2}>
          {dxp.wd}
        </NumberFormatter>
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dxp: import("../dxps/DXPQueries").DXP }>} */
export const DXPSCz = ({
  dxp,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>SCz</DescriptionLabel>
      <DescriptionValue>
        <NumberFormatter maximumFractionDigits={2} minimumFractionDigits={2}>
          {dxp.scz}
        </NumberFormatter>
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dxp: import("../dxps/DXPQueries").DXP }>} */
export const DXPAeroBal = ({
  dxp,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Aero Bal</DescriptionLabel>
      <DescriptionValue>
        <NumberFormatter maximumFractionDigits={2} minimumFractionDigits={2}>
          {dxp.aeroBal}
        </NumberFormatter>
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dxp: import("../dxps/DXPQueries").DXP }>} */
export const DXPMechBal = ({
  dxp,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Mech Bal</DescriptionLabel>
      <DescriptionValue>
        <NumberFormatter maximumFractionDigits={2} minimumFractionDigits={2}>
          {dxp.mechBal}
        </NumberFormatter>
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dto: import("../dtos/DTOQueries").DTO }>} */
export const DTODimension = ({
  dto,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Dimension</DescriptionLabel>
      <DescriptionValue>{dto?.tireDimension || empty}</DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dto: import("../dtos/DTOQueries").DTO }>} */
export const DTOComplement = ({
  tireTest,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>DTO complement</DescriptionLabel>
      <DescriptionValue>{tireTest?.dtoComplement || empty}</DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dto: import("../dtos/DTOQueries").DTO }>} */
export const DTOCasingReference = ({
  dto,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Casing reference</DescriptionLabel>
      <DescriptionValue>{dto?.casingReference || empty}</DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dto: import("../dtos/DTOQueries").DTO }>} */
export const DTORainTire = ({
  dto,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Rain tire</DescriptionLabel>
      <DescriptionValue>
        <input
          type="checkbox"
          checked={dto.isRainTire}
          disabled
          tw="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ dto: import("../dtos/DTOQueries").DTO }>} */
export const DTOKMCompound = ({
  dto,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>KM compound</DescriptionLabel>
      <DescriptionValue>{dto?.kmCompound || empty}</DescriptionValue>
    </Description>
  );
};

/** @type {React.FC<{ tire: import("../tires/TireQueries").Tire }>} */
export const TireIsWitness = ({
  tire,
  Description = DenseDescription,
  DescriptionLabel = DenseDescriptionLabel,
  DescriptionValue = DenseDescriptionValue,
}) => {
  return (
    <Description>
      <DescriptionLabel>Witness tire</DescriptionLabel>
      <DescriptionValue>
        <input
          type="checkbox"
          checked={tire?.isWitnessTire}
          disabled
          tw="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </DescriptionValue>
    </Description>
  );
};
