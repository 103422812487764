import {
  BookmarkOutlineIcon,
  ChartBarOutlineIcon,
  ClipboardCheckOutlineIcon,
  CreditCardOutlineIcon,
  FactoryIcon,
  FlagOutlineIcon,
  TagOutlineIcon,
  UsersOutlineIcon,
} from "../shared/Icons";

export const repositories = [
  {
    name: "Test types",
    entryName: "test type",
    key: "test-types",
    type: "tireTest",
    Icon: TagOutlineIcon,
  },
  {
    name: "Championships",
    entryName: "championship",
    key: "championships",
    type: "tireTest",
    Icon: FlagOutlineIcon,
  },
  {
    name: "Categories",
    entryName: "category",
    key: "categories",
    type: "tireTest",
    Icon: BookmarkOutlineIcon,
  },
  {
    name: "Projects",
    entryName: "project",
    key: "projects",
    type: "tireTest",
    Icon: CreditCardOutlineIcon,
  },
  {
    name: "Teams",
    entryName: "team",
    key: "teams",
    type: "tireTest",
    Icon: UsersOutlineIcon,
  },
  {
    name: "Types",
    entryName: "risk analysis types",
    key: "types-analysis-risk",
    type: "riskAnalysis",
    Icon: FlagOutlineIcon,
  },
  {
    name: "Ranges",
    entryName: "risk analysis ranges",
    key: "ranges-analysis-risk",
    type: "riskAnalysis",
    Icon: TagOutlineIcon,
  },
  {
    name: "Usage types",
    entryName: "risk analysis usage types",
    key: "usage-types-analysis-risk",
    type: "riskAnalysis",
    Icon: ClipboardCheckOutlineIcon,
  },
  {
    name: "Levels risk",
    entryName: "risk analysis levels",
    key: "levels-analysis-risk",
    type: "riskAnalysis",
    Icon: ChartBarOutlineIcon,
  },
  {
    name: "Factories",
    entryName: "risk analysis factories",
    key: "factories-analysis-risk",
    type: "riskAnalysis",
    Icon: FactoryIcon,
  },
];
