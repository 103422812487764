import { Auth0Provider } from "@auth0/auth0-react";
import { StrictMode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "twin.macro";
import { queryClient } from "../App";
import "../app.css";
import { NotificationProvider } from "../notifications/NotificationProvider";
import PermissionsProvider from "../PermissionsContext";

export const AppProvider = ({ children }) => {
  return (
    <StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        audience="https://calspan.michelin.com/"
        redirectUri={window.location.origin}
      >
        <PermissionsProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <HelmetProvider>
              <Helmet titleTemplate="%s | Michelin Tire Testing" />
              <NotificationProvider>
                <BrowserRouter>{children}</BrowserRouter>
              </NotificationProvider>
            </HelmetProvider>
          </QueryClientProvider>
        </PermissionsProvider>
      </Auth0Provider>
    </StrictMode>
  );
};
