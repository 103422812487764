export const PositionOnVehicle = {
  FrontLeft: "FrontLeft",
  FrontRight: "FrontRight",
  RearLeft: "RearLeft",
  RearRight: "RearRight",
};

export const PositionOnVehicleShortLabel = {
  [PositionOnVehicle.FrontLeft]: "FL",
  [PositionOnVehicle.FrontRight]: "FR",
  [PositionOnVehicle.RearLeft]: "RL",
  [PositionOnVehicle.RearRight]: "RR",
};

export const PositionOnVehicleLabel = {
  [PositionOnVehicle.FrontLeft]: "Front Left",
  [PositionOnVehicle.FrontRight]: "Front Right",
  [PositionOnVehicle.RearLeft]: "Rear Left",
  [PositionOnVehicle.RearRight]: "Rear Right",
};
