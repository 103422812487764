/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  Notification,
  NotificationContent,
  NotificationDescription,
  NotificationSuccessIcon,
  NotificationTitle
} from "./Notification";

export const NotificationDisplay = ({isOpen, title, description, icon}) => {

  const [notifDisplay, setNotifDisplay] = useState(false)

  useEffect(() => {
    setNotifDisplay(isOpen);
  }, [isOpen])
  
  return (
    <>
      {
        notifDisplay &&
          <Notification onDismiss={() => {setNotifDisplay(false)}} isFixed={true}>
            {
              icon ?
                icon()
              :
                <NotificationSuccessIcon />   
            }
            <NotificationContent>
              <NotificationTitle>{title}</NotificationTitle>
              <NotificationDescription>{description}</NotificationDescription>
            </NotificationContent>
          </Notification>
      }
    </>
  )
};
