/** @jsxImportSource @emotion/react */
import { DocumentAddIcon, DocumentDownloadIcon, XIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import "twin.macro";
import { ExportTireModal } from "../exports/ExportTireModal";
import { SearchBar } from "../search/SearchBar";
import { useSearch } from "../search/useSearch";
import { getSessionShortDescription, getSessionTitle } from "../sessions/SessionQueries";
import { Badge, BadgeDot } from "../shared/Badge";
import { PrimaryButton, PrimaryLinkButton } from "../shared/Button";
import { CountBadge } from "../shared/CountBadge";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "../shared/EmptyState";
import { ErrorPage } from "../shared/ErrorPage";
import { BarCodeIcon, CalendarOutlineIcon, RimOutlineIcon, TireOutlineIcon } from "../shared/Icons";
import { NotFoundIllustration } from "../shared/Illustrations";
import {
  List,
  ListCheckbox,
  ListHeaderCheckbox,
  ListItem,
  ListItemContent,
  ListItemEmptyMetaTag,
  ListItemMeta,
  ListItemMetaTag,
  ListItemRow,
  ListPagination,
} from "../shared/List";
import {
  Menu,
  MenuButton,
  MenuButtonDropdownIcon,
  MenuItemButton,
  MenuItems,
  MenuSection,
} from "../shared/Menu";
import { Page, PageContent, PageHeader, PageTitle } from "../shared/Page";
import { Panel } from "../shared/Panel";
import { Filter } from "../shared/QueryHelpers";
import { SEO } from "../shared/SEO";
import { BadgeSkeleton, Skeleton } from "../shared/Skeleton";
import { Tabs } from "../shared/Tabs";
import { useDisclosure } from "../shared/useDisclosure";
import { useItemSelection } from "../shared/useItemSelection";
import { useSearchQuery } from "../shared/useSearchQuery";
import { ImportTiresModal } from "./ImportTiresModal";
import TemplateDownloadBtn from "./TemplateDownloadBtn";
import { useTiresQuery, useUpsertManyTireMutation } from "./TireQueries";

const TireStatusIcon = (props) => {
  return (
    <svg fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="3" />
      <circle cx="10" cy="10" r="6" fillOpacity={0.3} />
    </svg>
  );
};

const UpdateTiresStatusMenuItem = ({ status, tires, clearSelection, ...props }) => {
  const { mutateAsync: upsertManyTire } = useUpsertManyTireMutation();
  return (
    <MenuItemButton
      onClick={() => {
        clearSelection();
        upsertManyTire(
          tires.map((item) => ({
            _id: item._id,
            status,
          }))
        );
      }}
      {...props}
    />
  );
};

/** @type {React.FC<{ tire: import("./TireQueries").Tire }>} */
export const TireListItem = ({ tire, leading }) => {
  return (
    <ListItem>
      {leading && <div tw="hidden absolute sm:(block -left-6 top-10)">{leading}</div>}
      <ListItemContent>
        <ListItemRow>
          <div tw="flex items-center space-x-2 truncate">
            <PrimaryLinkButton as={Link} to={`/tires/${tire._id}`} tw="truncate">
              {tire.serialNumber}
            </PrimaryLinkButton>
            {tire.isWitnessTire && <Badge color="indigo">WITNESS</Badge>}
          </div>
          {tire.status === "unused" && (
            <Badge color="green">
              <BadgeDot />
              Unused
            </Badge>
          )}
          {tire.status === "used" && (
            <Badge color="gray">
              <BadgeDot />
              Used
            </Badge>
          )}
          {tire.status === "usedByForceAndMoment" && (
            <Badge color="gray">
              <BadgeDot />
              Used by force and moment
            </Badge>
          )}
          {tire.status === "destroyed" && (
            <Badge color="red">
              <BadgeDot />
              Destroyed
            </Badge>
          )}
          {tire.status === "sendedback" && (
            <Badge color="indigo">
              <BadgeDot />
              Sent back
            </Badge>
          )}
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <TireOutlineIcon />
              <span tw="truncate">{tire.meta?.dto?.tireDimension || <ListItemEmptyMetaTag />}</span>
            </ListItemMetaTag>
            <ListItemMetaTag>
              <RimOutlineIcon />
              <span tw="truncate">{tire.meta?.dto?.tireRim || <ListItemEmptyMetaTag />}</span>
            </ListItemMetaTag>
            <ListItemMetaTag>
              <BarCodeIcon />
              <span tw="truncate">{tire.tirf || <ListItemEmptyMetaTag />}</span>
            </ListItemMetaTag>
          </ListItemMeta>
          <ListItemMeta>
            <ListItemMetaTag>
              <CalendarOutlineIcon />
              <span tw="truncate">
                {tire.meta?.session ? (
                  <>
                    {getSessionTitle(tire.meta.session)}{" "}
                    <span tw="text-xs font-medium text-gray-500">
                      {getSessionShortDescription(tire.meta.session)}
                    </span>
                  </>
                ) : (
                  <ListItemEmptyMetaTag />
                )}
              </span>
            </ListItemMetaTag>
          </ListItemMeta>
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const TireListItemSkeleton = () => {
  return (
    <ListItem>
      <ListItemContent>
        <ListItemRow>
          <Skeleton />
          <BadgeSkeleton />
        </ListItemRow>
        <ListItemRow>
          <ListItemMeta>
            <ListItemMetaTag>
              <TireOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
            <ListItemMetaTag>
              <RimOutlineIcon />
              <Skeleton />
            </ListItemMetaTag>
          </ListItemMeta>
          <Skeleton />
        </ListItemRow>
      </ListItemContent>
    </ListItem>
  );
};

const TireCountBadge = ({ status: filteredStatus }) => {
  const { status, data } = useTiresQuery({
    status: filteredStatus,
    limit: 1,
  });
  return status === "success" ? <CountBadge>{data.totalCount}</CountBadge> : null;
};

const TireList = () => {
  const { page = 1, status: selectedStatus } = useSearchQuery();
  const { search, debouncedSearch, setSearch } = useSearch();
  const pageSize = 10;
  const { status, data, error } = useTiresQuery({
    sort: "dto",
    skip: (Number(page) - 1) * pageSize,
    limit: pageSize,
    ...Filter.from(
      selectedStatus && { status: selectedStatus },
      debouncedSearch && {
        $or: [
          { serialNumber: Filter.regex(debouncedSearch) },
          { dto: Filter.regex(debouncedSearch) },
          { tirf: Filter.regex(debouncedSearch) },
        ],
      }
    ),
  });

  const { selectedItems, isSelected, onClearSelection, onToggle, onToggleMany } =
    useItemSelection();

  const {
    isOpen: isTireExportModalOpen,
    onOpen: onTireExportModalOpen,
    onClose: onTireExportModalClose,
  } = useDisclosure();

  const {
    isOpen: isTiresImportModalOpen,
    onOpen: onTiresImportModalOpen,
    onClose: onTiresImportModalClose,
  } = useDisclosure();

  const clearSelection = () => {
    onClearSelection();
  };

  return (
    <>
      {status === "error" && <ErrorPage error={error} />}
      {status !== "error" && (
        <>
          <Page>
            <SEO title="Serial numbers" />
            <PageHeader
              title={<PageTitle>Serial numbers</PageTitle>}
              actions={
                <>
                  <SearchBar value={search} onChange={setSearch} />
                  <Menu>
                    <MenuButton>
                      More
                      <MenuButtonDropdownIcon />
                    </MenuButton>
                    <MenuItems>
                      <MenuSection>
                        <TemplateDownloadBtn
                          btnLabel="Import tires template"
                          templateName="import-tires-template"
                        />
                        <TemplateDownloadBtn
                          btnLabel="Force and moment tires template"
                          templateName="import-force-and-moments-template"
                        />
                        <TemplateDownloadBtn
                          btnLabel="Destroyed tires template"
                          templateName="destroyed-tires-template"
                        />
                        <TemplateDownloadBtn
                          btnLabel="Sent back tires template"
                          templateName="sent-back-tires-template"
                        />
                      </MenuSection>

                      <MenuSection>
                        <MenuItemButton onClick={onTiresImportModalOpen}>
                          <DocumentAddIcon tw="text-gray-400" />
                          Import tires
                        </MenuItemButton>
                      </MenuSection>

                      <MenuSection>
                        <MenuItemButton onClick={onTireExportModalOpen}>
                          <DocumentDownloadIcon tw="text-yellow-400!" />
                          Export tires
                        </MenuItemButton>
                      </MenuSection>
                      {selectedItems.length !== 0 && (
                        <>
                          <MenuSection>
                            <UpdateTiresStatusMenuItem
                              status="unused"
                              tires={selectedItems}
                              clearSelection={clearSelection}
                            >
                              <TireStatusIcon tw="text-green-500!" />
                              Set as unused
                            </UpdateTiresStatusMenuItem>
                            <UpdateTiresStatusMenuItem
                              status="used"
                              tires={selectedItems}
                              clearSelection={clearSelection}
                            >
                              <TireStatusIcon tw="text-gray-500!" />
                              Set as used
                            </UpdateTiresStatusMenuItem>
                            <UpdateTiresStatusMenuItem
                              status="usedByForceAndMoment"
                              tires={selectedItems}
                            >
                              <TireStatusIcon tw="text-gray-500!" />
                              Set as used by force and moment
                            </UpdateTiresStatusMenuItem>
                            <UpdateTiresStatusMenuItem
                              status="destroyed"
                              tires={selectedItems}
                              clearSelection={clearSelection}
                            >
                              <TireStatusIcon tw="text-red-500!" />
                              Set as destroyed
                            </UpdateTiresStatusMenuItem>
                            <UpdateTiresStatusMenuItem
                              status="sendedback"
                              tires={selectedItems}
                              clearSelection={clearSelection}
                            >
                              <TireStatusIcon tw="text-indigo-500!" />
                              Set as sent back
                            </UpdateTiresStatusMenuItem>
                          </MenuSection>
                          <MenuSection>
                            <MenuItemButton onClick={onClearSelection}>
                              <XIcon />
                              Clear selection
                            </MenuItemButton>
                          </MenuSection>
                        </>
                      )}
                    </MenuItems>
                  </Menu>
                  <PrimaryButton as={Link} to="/tires/new">
                    Create
                  </PrimaryButton>
                  <ExportTireModal
                    isOpen={isTireExportModalOpen}
                    onDismiss={onTireExportModalClose}
                    clearSelection={clearSelection}
                  />
                  <ImportTiresModal
                    isOpen={isTiresImportModalOpen}
                    onDismiss={onTiresImportModalClose}
                    clearSelection={clearSelection}
                  />
                </>
              }
            />
            <PageContent>
              <Tabs
                options={[
                  {
                    label: "All",
                    to: "/tires",
                    count: <TireCountBadge />,
                  },
                  {
                    label: "Unused",
                    to: "/tires?status=unused",
                    count: <TireCountBadge status="unused" />,
                  },
                  {
                    label: "Used",
                    to: "/tires?status=used",
                    count: <TireCountBadge status="used" />,
                  },
                  {
                    label: "Used by force and moment",
                    to: "/tires?status=usedByForceAndMoment",
                    count: <TireCountBadge status="usedByForceAndMoment" />,
                  },
                  {
                    label: "Destroyed",
                    to: "/tires?status=destroyed",
                    count: <TireCountBadge status="destroyed" />,
                  },
                  {
                    label: "Sent back",
                    to: "/tires?status=sendedback",
                    count: <TireCountBadge status="sendedback" />,
                  },
                ]}
              />
              {status === "loading" && (
                <>
                  <Skeleton tw="bg-gray-200 mt-8" />
                  <Panel tw="mt-2">
                    <List>
                      {Array.from({ length: 5 }, (_, index) => (
                        <TireListItemSkeleton key={index} />
                      ))}
                    </List>
                  </Panel>
                </>
              )}
              {status === "success" && data.totalCount === 0 && (
                <EmptyState>
                  <EmptyStateIllustration as={NotFoundIllustration} />
                  {selectedStatus || debouncedSearch ? (
                    <>
                      <EmptyStateTitle as="h3">
                        There are no tires matching this search
                      </EmptyStateTitle>
                      <EmptyStateDescription>
                        Try changing your search parameters or clearing your search filters
                      </EmptyStateDescription>
                      <PrimaryButton as={Link} to="/tires" tw="mt-8">
                        Clear filters
                      </PrimaryButton>
                    </>
                  ) : (
                    <>
                      <EmptyStateTitle as="h3">You don't have any tires</EmptyStateTitle>
                      <EmptyStateDescription>
                        You can create your first tire by clicking on the button below
                      </EmptyStateDescription>
                      <PrimaryButton as={Link} to="/tires/new" tw="mt-8">
                        Create your first tire
                      </PrimaryButton>
                    </>
                  )}
                </EmptyState>
              )}
              {status === "success" && data.totalCount !== 0 && (
                <>
                  <h3 tw="text-xs font-semibold text-gray-400 uppercase tracking-wider mt-8">
                    {/* Session selection checkbox to allow selection of all the tire tests in the session */}
                    <ListHeaderCheckbox
                      checked={data.list.some((tireTest) => isSelected(tireTest))}
                      indeterminate={data.list.some((tireTest) => !isSelected(tireTest))}
                      onChange={onToggleMany(data.list)}
                    />
                    <span tw="pl-4 sm:pl-6 xl:pl-4">Select all</span>
                  </h3>
                  <Panel tw="mt-2">
                    <List>
                      {data.list.map((tire) => (
                        <TireListItem
                          key={tire._id}
                          tire={tire}
                          leading={
                            <ListCheckbox checked={isSelected(tire)} onChange={onToggle(tire)} />
                          }
                        />
                      ))}
                    </List>
                  </Panel>

                  <ListPagination
                    page={Number(page)}
                    pageSize={pageSize}
                    totalCount={data.totalCount}
                  />
                </>
              )}
            </PageContent>
          </Page>
        </>
      )}
    </>
  );
};

export default TireList;
