import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import { useUserQuery } from "./UserQueries";

export const UserAutocomplete = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();
  // Load entries from the DTO collection
  const { isLoading, data } = useUserQuery({});

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });


  // Resolve option even when entries are not loaded from the DTO collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = data ? data.list.map((entry) => ({ ...entry, label: entry.name, value: entry.name  })) : [];

  // Change the value of the field
  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.name : null);
  }

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  const onCreateOption = (value) => {
    onChange(value);
  };

  return (
    <>
      <BaseCreatableSelect
        inputId={inputId}
        value={selectedOption}
        onChange={handleChange}
        styles={styles}
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        onCreateOption={onCreateOption}
        {...controllerProps}
        {...props}
      />
    </>
  );
};
