/** @jsxImportSource @emotion/react */

export const Logo = (props) => {
  return (
    <svg fill="none" viewBox="0 0 292 57" {...props}>
      <defs />
      <path
        fill="#FCE500"
        fillRule="evenodd"
        d="M246.4 51.8H12.6L10.7 57h233.8l1.9-5.2z"
        clipRule="evenodd"
      />
      <path
        fill="#27509B"
        fillRule="evenodd"
        d="M136 34.9c0-8.1 8.1-12.6 14.2-13.8a31 31 0 0113.1.1l-3 7.3c-.9-2-3.1-2-4-2-1.9 0-3.7.3-5.4 1-3 1.5-5.7 6-1.4 7.7 2 .8 4.6.4 7.2 0l1.4-.3-2.4 6.3c-2.5.4-5 .7-7.5.8-6.5.2-12.1-2.4-12.2-7.1zm44.2-6.6L183 21h9.5l-7.6 20.6h-9.5l2.6-7h-5l-2.7 7h-9.5l7.6-20.6h9.5l-2.7 7.4h5zm-54-7.4l-7.5 20.6h10l7.6-20.6h-10zm-27 11l7.2-11h14.1L113 41.5h-9l5.3-14.6-9.9 14.6h-8l1.2-14.9-5.5 15h-9l7.5-20.7h11.6c2.6 0 2.7 2.8 2.6 3.9l-.6 7.2zm183.5-11l-5.2 14.2.1-3.1.4-7.2c0-1.4-.2-2.4-.8-3-.5-.6-1.2-.9-1.9-1h-11.6l-7.6 20.7h8.5L270 27l-.7 14.6h14.1L291 21h-8.3zm-42.5 20.6l7.6-20.6h10l-7.6 20.6h-10zm-34.7-15.2H217l2-5.4h-20.8l-7.6 20.6h21l2.1-5.5H202l.9-2.3h10.8l1.9-5.1h-10.9l.8-2.3zm19-5.4h9.7l-5 13.7h9.7l-2.5 6.9h-19.5l7.6-20.6z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M2 13.6v.4a43.5 43.5 0 003.7 6.6 8 8 0 00-1.2 2.7c-.3 1.4-.2 3 .4 4.3.2.6.4 1.2.8 1.8v.1a8.4 8.4 0 004.6 8.7 6.5 6.5 0 002.2 3 9 9 0 002.5 1.4c1 1.5 2.6 2.5 4.3 3l1.4.2a8 8 0 003.4 2c-.2 1.3 0 2.7.4 4H74a8.9 8.9 0 00-1.4-4.8 9.4 9.4 0 00-8.3-10.6 20.5 20.5 0 00-6.2-4l.1-.5h.1c2.7-1.2 4.3-3.1 4.6-5.7.3-2-.3-4.2-1.5-5.9.3-2.8-.1-5.2-1.2-7.2a8.5 8.5 0 00-3.8-3.7C55.9 8 54 7 52 7c-1.5 0-3.3.8-4 2-2.4.9-6.1 3.1-7.2 8.8a7.6 7.6 0 00-1 11.4c-2.4 0-4.8.2-7 .7h-.1a9.5 9.5 0 00-7.8-1.5l-.3.1a14 14 0 00-2.5-.5c-.4-1.2-1-2.3-2-3.2l-.1-.1-.5-2.2a7.1 7.1 0 00-3.9-4.4 5.2 5.2 0 00.3-1l.6-4.3v-.1l1-5.8c0-.5-.2-1-.6-1.4-.4-.5-1.1-.7-1.8-.7-1 0-2 .5-2.4 1.4-.4.5-.6 1-.8 1.7l-3-6A2.3 2.3 0 006 .8C5.6.4 5 .2 4.5.3l-.9.1C2.6.7 2 1.6 2.1 2.7c-.8.2-1.3.9-1.5 1.6l.1 1.3v.2c-.6 1-.9 2.5 1.2 7.8"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M72.4 51.8h-2c.7-.7 1.2-1.6 1.6-2.5.2.8.4 1.7.4 2.5M40.2 30.6c.4 0 .7.1 1.1.3.8.2 2.2 1.6 3 1.8.1 0 .5-.1 0-.4 0 0-1-.9-.6-1.3l.5-.2a28.5 28.5 0 002.8.5l-.2-.2c-1.8-.5-3.7-1.2-5-2.2a6.3 6.3 0 01-2.6-5.8 6 6 0 012.4-4c.5-.4 1-.6 1.6-.8.6-.2-.4-.4-.5-.4-.2 0-.3-.3 0-1.2a9.4 9.4 0 017.5-6.7c.6 0 .3-.4-.3-.4 0 0-.3 0-.2-.2l.4-.3a4 4 0 012-.6c1.4 0 2.7.8 2.8 1.5l-.1.1c-.3 0-.5.2-.1.4l1 .4c1.1.5 4.5 2.6 4.3 8.3v.6l-.1.4c-.3 0-.5.1-.3.4l.8 1a7 7 0 011 4.4 5.5 5.5 0 01-2.8 4.1c-1.2.6-2.3 1-3.6 1.4-.3 0-.2.5 1.4.4.1 0 .4 0 .5.2.2.4-.1 1-.8 1.4-.6.4-.1.4.7.2l1 .3c2.7 1.2 5.2 3.2 7 5.6 1.8 2.5 2.4 4.5 1.9 5.7-.5 1.2-2 1.4-2.1 1.4a66.1 66.1 0 01-28.7-4.6c1.6-3.6 1-7.8-1.6-10.8l-.1-.1c2-.4 4-.5 6-.5"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M69.8 41.4a7.8 7.8 0 01-1.4 10.3c0-1.4-.2-3.1-1.3-4.4.5-.4.8-.9 1-1.4.9-1.8.2-4.2-1.9-7.3l-.1-.2c1.5.6 2.8 1.7 3.7 3M33.1 32.3a8.6 8.6 0 01-8.3 14l-1.4-.5 1-.2c3.4-1 6-4.1 6.3-7.9a9 9 0 00-1.8-6.5c-.5-.6-1.1-1.2-1.8-1.6 2.3 0 4.5 1 6 2.7"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M27.6 32.2a7.5 7.5 0 011.5 5.4A7.4 7.4 0 0124 44c-2 .6-4.3.3-6.1-.9a8.5 8.5 0 007.7-5.8 8 8 0 00-1.2-7.3c1.3.4 2.4 1.1 3.3 2.2"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.3 32.3l.2-2a6.3 6.3 0 011.6 6.5 6.7 6.7 0 01-7.7 4.6 7 7 0 01-3.9-2.4 8.3 8.3 0 005-.5 8.4 8.4 0 004.8-6.2"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20 27c.8 1.5 1 3.3.7 5A6.6 6.6 0 0110 36a7 7 0 01-2.8-4.8l.5.4C9 32.6 10.5 33 12 33a8 8 0 002.1-.2A7.8 7.8 0 0020 27"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2 6.7l.3-.2h.4c.5.2.8.8 1 1.6.1 1.4.6 2.7 1.4 4a.3.3 0 10.5-.5C5 10.6 4.5 9.3 4.3 8 4.2 7 3.7 6.2 3 6h-.6a4 4 0 01-.2-.7c0-.3-.2-.9.5-1l.1-.1c.5 0 1.2.1 1.8 2.4.5 1.4 1.1 2.8 2 4.1a.3.3 0 00.5-.4C6.3 9 5.7 7.7 5.3 6.3c-.3-1-.7-2-1.3-2.5l-.3-.6c0-.4-.2-1 .4-1.3h.4c.4 0 .8.3.9.7v.1l2.9 6.8v.1a.3.3 0 00.6-.3L6 2.7c0-.2.2-.3.3-.3l.5-.2c.2 0 .5.2.6.4l4.3 8.4a7 7 0 00-.5 1c0 .3 0 .4.2.2a10.7 10.7 0 01.7-1l1-2.1c.1-.8.4-1.5.8-2.2.2-.4.6-.6 1-.6.5 0 .8.2.8.4l-.9 5.7a55 55 0 00-.6 4.4V17.1c-.2.5-.3 1-.6 1.4L14 20l.3 1.3c.2.7 0 1.2.1 1.3.2.3.7-.7.8-1.3 0-.6 0-1.2-.2-1.8l1.5 1a5 5 0 011.5 2.4l.5 2.7a6 6 0 01-4.7 5.7 6.4 6.4 0 01-5.2-.8c-2.2-1.6-3-5-2.6-6.7.2-.7.5-1.4.9-2l.6.3.1.6s.4 1.8 1 2.3c.4.2.9.4 1.3.3.2 0 .7 0 .7-.2v-.2l-.2-.1c-.3-.2-1-.5-1-1.1l-.6-2.2c.7-.5.8-1-.1-.9-.5 0-1 0-1.4-.4-1.7-1.6-3.6-6.3-4-7C2 9.1 1.7 7.1 2 6.5M25.7 48l1 .1c3.2 0 6.4-1.7 8.5-4.7 1.6.8 3.4 1.4 5.1 2a85.1 85.1 0 0020.2 3.1 28.4 28.4 0 005.1-.5c1 1 1.2 2.6 1.1 3.8H26.2a6.5 6.5 0 01-.5-3.7"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M47.3 23L47 23h-.5c-.4.2-.6.6-.7 1v.1l.2.1.2-.1.1-.4c.2-.2.5-.3.8-.3.1 0 .2-.1.2-.3"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M57.1 24.3l.1-.2c.2 0 .4.1.4.3.1.1.1.3 0 .4 0 .2 0 .4-.2.4v-.3l-1 .5-.3.1-.6.4-.9.6c-.4.3-.9.5-1.4.5a4.5 4.5 0 01-4-1.4l-1-.9-1.3-.5a.4.4 0 01-.3-.4.3.3 0 01.5-.3h.1v.1l.5.2c1.6.7 3.3 1 5 1.2 1 .1 2 0 3-.2.5 0 1-.2 1.3-.4h.2l-.1-.1zm-4.8 2.1l1.3-.1c.5-.1.9-.4 1.2-.7a15 15 0 01-5-.4c.3.4.7.7 1.1.9.4.2.9.3 1.4.3zM48.7 21.2l-.7-.5c-.3-.5-.5-1-.6-1.6l.1-1.6.6-1.5a2 2 0 011.1-1 2.2 2.2 0 011.7.3c1 .6 1.1 1.6 1.1 3 0 1.5-1 3-2 3a6 6 0 01-1.3-.1zm1.2-5.4c-1.6-.2-2 1.8-2 2.5v.8c0 .4.2 1 .5 1.3l.1.1c-.4-.5-.5-1.1-.4-1.8v-.6c.1.3.3.5.6.5a.7.7 0 000-1.4c.2-.3.6-.5 1-.5.8 0 1.4 1 1.3 2.3 0 .7-.4 1.5-1 1.9.4-.1.8-.4 1-.8l.3-.6c.2-.5.3-1.2.2-1.8 0-.9-.4-1.8-1.6-2zM57.4 16.2c.4.3.7.7.8 1.2.2.5.2 1 .2 1.6 0 .5-.2 1-.4 1.5s-.5 1-1 1.4l-.7.2h-.7L55 22c-.9-.3-1.4-2-1-3.4.3-1.3.7-2.3 1.8-2.6l.3-.1c.5 0 1 0 1.3.3zm.2 4.2l.3-.8c0-.6.2-2.6-1.3-2.9-1.2-.2-1.7.6-2 1.3v.2a3.7 3.7 0 00-.2 2.4c.1.4.3.7.6 1-.4-.6-.6-1.3-.5-2l.1-.6c0 .2.3.4.5.4.4 0 .6-.4.5-.7 0-.3-.2-.6-.5-.7.2-.3.6-.5 1-.5.8.1 1.4 1.1 1.3 2.3 0 .8-.4 1.5-1 1.9.1 0 .3 0 .4-.2.4-.3.7-.7.8-1.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
