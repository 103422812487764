/** @jsxImportSource @emotion/react */
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import "twin.macro";
import { SessionAutocomplete } from "../sessions/SessionAutocomplete";
import { PrimaryButton } from "../shared/Button";
import { DateInput, FormGroup, Label, OptionalLabel, Select } from "../shared/Form";
import { Modal, ModalActions, ModalButton } from "../shared/Modal";
import { Filter } from "../shared/QueryHelpers";
import { tireStatuses, useFetchTires } from "../tires/TireQueries";

/** @type {() => import("react-query").UseMutationResult<void, unknown, { status?: import("../tires/TireQueries").TireStatus, from?: Date, to?: Date }>} */
const useExportTiresToXSLXMutation = () => {
  const fetchTires = useFetchTires();
  const ExcelJS = require('exceljs');
  return useMutation(async ({ session, status, from, to }) => {
    const tires = await fetchTires({
      limit: 0,
      ...Filter.and(
        session && { session },
        status && { status },
        from && { statusUpdatedAt: { $gte: from } },
        to && { statusUpdatedAt: { $lte: to } }
      ),
    });
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Serial numbers");
    worksheet.addTable({
      name: "SerialNumbers",
      ref: "A1",
      columns: [{ name: "Serial number" }, { name: "Status" }, { name: "Status updated at" }, { name: "Tirf Id" }],
      rows: tires.map((tire) => {
        return [tire.serialNumber, tire.status, tire.statusUpdatedAt, tire.tirf];
      }),
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const { saveAs } = await import("file-saver");
    const blob = new Blob([buffer]);
    let fileName = `tires`;
    if (status) {
      fileName += `-status[${status}]`;
    }
    if (from) {
      fileName += `-from[${from}]`;
    }
    if (to) {
      fileName += `-to[${to}]`;
    }
    saveAs(blob, `${fileName}.xlsx`);
  });
};

export const ExportTireModal = ({ isOpen, onDismiss, clearSelection }) => {
  /** Plan selected tire tests */
  const formProps = useForm({
    mode: "onSubmit",
    defaultValues: {
      session: null,
      status: null,
      from: null,
      to: null,
    },
  });
  const { handleSubmit, reset } = formProps;

  const { status, mutate: exportTiresToXLSX } = useExportTiresToXSLXMutation();
  const onExport = async (data) => {
    reset();
    clearSelection();
    exportTiresToXLSX(data);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} aria-label="Export tires modal">
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(onExport)} tw="space-y-6">
          <FormGroup name="session">
            <Label>Session</Label>
            <div tw="absolute top[17px] right[22px]">
              <OptionalLabel/>
            </div>
            <SessionAutocomplete/>
          </FormGroup>
          <FormGroup name="status">
            <div tw="flex justify-between">
              <Label>Status</Label>
              <OptionalLabel />
            </div>
            <Select>
              <option></option>
              {tireStatuses.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup name="from">
            <div tw="flex justify-between">
              <Label>From</Label>
              <OptionalLabel />
            </div>
            <DateInput />
          </FormGroup>
          <FormGroup name="to">
            <div tw="flex justify-between">
              <Label>To</Label>
              <OptionalLabel />
            </div>
            <DateInput />
          </FormGroup>
          <ModalActions>
            <ModalButton
              as={PrimaryButton}
              onClick={onDismiss}
              type="submit"
              disabled={status === "loading"}
            >
              Export
            </ModalButton>
            <ModalButton onClick={onDismiss} type="button">
              Cancel
            </ModalButton>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
};
