/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import "twin.macro";
import tw from "twin.macro";

const ButtonBase = styled("button")(
  tw`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md`,
  tw`focus:(outline-none ring-2 ring-offset-2 ring-indigo-500)`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`
);

export const Button = styled(ButtonBase)(
  tw`shadow-sm border-gray-300 text-gray-700 bg-white`,
  tw`hover:(bg-gray-50)`
);

export const PrimaryButton = styled(ButtonBase)(
  tw`shadow-sm text-white bg-indigo-600 `,
  tw`hover:(bg-indigo-700)`
);

const LinkButtonBase = styled("button")(
  tw`text-sm font-medium`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`
);

export const LinkButton = styled(LinkButtonBase)(tw`text-gray-600`, tw`hover:(text-gray-500)`);

export const PrimaryLinkButton = styled(LinkButtonBase)(
  tw`text-indigo-600`,
  tw`hover:(text-indigo-500)`
);
