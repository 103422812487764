/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import { Menu as HeadlessUIMenu } from "@headlessui/react";
import {
  CalendarIcon,
  ChartBarIcon,
  DocumentReportIcon,
  HomeIcon,
  LocationMarkerIcon,
  MenuAlt2Icon,
  PresentationChartLineIcon,
  SearchIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import { useCallback, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import tw from "twin.macro";
import { repositories } from "../repositories/repositories";
import { GlobalSearchBar } from "../search/GlobalSearchBar";
import {
  getLimitDatePastSession,
  getSessionShortDescription,
  getSessionTitle,
  useSessionsQuery,
} from "../sessions/SessionQueries";
import { useTireTestsCountQuery } from "../tire-tests/TireTestQueries";
import { Badge } from "./Badge";
import { RimOutlineIcon, TireOutlineIcon } from "./Icons";
import { Logo } from "./Logo";
import { Menu, MenuItemButton, MenuItems, MenuSection } from "./Menu";
import { Filter } from "./QueryHelpers";

const ProfileDropdown = () => {
  const { isLoading, user, logout } = useAuth0();
  return isLoading || !user ? (
    <div tw="h-8 w-8 rounded-full bg-gray-200 animate-pulse" />
  ) : (
    <Menu>
      {/* Profile button */}
      <HeadlessUIMenu.Button
        tw="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        aria-label="Toggle profile menu"
      >
        <img tw="h-8 w-8 rounded-full" src={user.picture} alt="" width={480} height={480} />
      </HeadlessUIMenu.Button>
      {/* Profile dropdown panel, show/hide based on dropdown state. */}
      <MenuItems>
        <div tw="px-4 py-3">
          <p tw="text-sm">Signed in as</p>
          <p tw="text-sm font-medium text-gray-900 truncate">{user.email}</p>
        </div>
        <MenuSection>
          <MenuItemButton onClick={() => logout({ returnTo: window.location.origin })}>
            Sign out
          </MenuItemButton>
        </MenuSection>
      </MenuItems>
    </Menu>
  );
};

const OpenSidebarButton = (props) => {
  return (
    <button
      tw="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600"
      aria-label="Open sidebar"
      {...props}
    >
      <MenuAlt2Icon tw="h-6 w-6" />
    </button>
  );
};

const Navbar = ({ start, center, end }) => {
  return (
    <header tw="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
      {start}
      <nav tw="flex-1 px-4 flex justify-between">
        <div tw="flex-1 flex">{center}</div>
        <div tw="ml-4 flex items-center md:ml-6 space-x-3">{end}</div>
      </nav>
    </header>
  );
};

export const SidebarNavLink = ({ Icon, children, ...props }) => {
  return (
    <NavLink
      className="group"
      tw="py-2 px-4 flex items-center text-sm font-medium text-gray-600 border-l-4 border-transparent hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150"
      css={{
        "&.active": tw`text-indigo-600 bg-indigo-50 border-indigo-600 hocus:text-indigo-600 hocus:bg-indigo-50`,
        "& > svg": tw`text-gray-400 group-focus:text-gray-500`,
        "&.active > svg": tw`text-indigo-500 group-hocus:text-indigo-500`,
      }}
      {...props}
    >
      <Icon tw="mr-4 md:mr-3 h-6 w-6 transition ease-in-out duration-150" />
      {children}
    </NavLink>
  );
};

export const SidebarHeading = ({ children, ...props }) => {
  return (
    <h3 tw="px-5 text-xs font-semibold text-gray-500 uppercase tracking-wider" {...props}>
      {children}
    </h3>
  );
};

const SidebarHeader = () => {
  return (
    <header tw="flex-shrink-0 flex items-center px-4">
      <Logo tw="h-8 w-auto" />
    </header>
  );
};

const CloseSidebarButton = (props) => {
  return (
    <button
      tw="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
      aria-label="Close sidebar"
      {...props}
    >
      <XIcon tw="h-6 w-6 text-white" />
    </button>
  );
};

const AnimatedDialogContent = animated(DialogContent);

const OffCanvasSidebar = ({ isOpen, onDismiss, header, children }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: "translate3d(-125%, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-125%, 0, 0)" },
  });

  return transitions.map(
    ({ item, key, props: styles }) =>
      item && (
        <DialogOverlay key={key} tw="fixed inset-0 flex z-40 bg-transparent" onDismiss={onDismiss}>
          {/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
          <animated.div tw="fixed inset-0" style={{ opacity: styles.opacity }}>
            <div tw="absolute inset-0 bg-gray-600 opacity-75" />
          </animated.div>

          {/* Off-canvas menu, show/hide based on off-canvas menu state. */}
          <AnimatedDialogContent
            tw="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white m-0 px-0"
            style={{ transform: styles.transform }}
            aria-label="Sidebar"
          >
            <div tw="absolute top-0 right-0 -mr-14 p-1 overflow-y-auto">
              <CloseSidebarButton onClick={onDismiss} />
            </div>
            {header}
            <div tw="mt-5 flex-1 h-0 overflow-y-auto">
              {/* Hide sidenav when a menu item is clicked */}
              <nav onClick={onDismiss}>{children}</nav>
            </div>
          </AnimatedDialogContent>
          <div tw="flex-shrink-0 w-14">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </DialogOverlay>
      )
  );
};

const Sidebar = ({ isOpen, onDismiss, header, children }) => {
  return (
    <>
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div tw="md:hidden">
        <OffCanvasSidebar isOpen={isOpen} onDismiss={onDismiss} header={header}>
          {children}
        </OffCanvasSidebar>
      </div>

      {/* Static sidebar for desktop */}
      <div tw="hidden md:flex md:flex-shrink-0">
        <div tw="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div tw="flex flex-col flex-grow pt-5 pb-4 bg-white border-r border-gray-200 overflow-y-auto">
            {header}
            <div tw="mt-5 flex-grow flex flex-col">
              <nav tw="flex-1">{children}</nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SessionNavLink = ({ session, ...props }) => {
  const { status, data } = useTireTestsCountQuery({
    ...Filter.from({ session: session ? session.oid : null }),
    limit: 1,
  });
  return (
    <Link
      className="group"
      tw="py-2 px-4 flex items-center justify-between space-x-2 text-sm font-medium text-gray-600 border-l-4 border-transparent hover:(text-gray-900 bg-gray-50) focus:(outline-none text-gray-900 bg-gray-50)"
      {...props}
    >
      <div tw="flex flex-col">
        <div tw="flex space-x-2">
          <span>{getSessionTitle(session)}</span>
          {session.isForceAndMoment && (
            <Badge color="blue" tw="ml-2">
              F/M
            </Badge>
          )}
        </div>
        <span tw="text-gray-400 text-xs">{getSessionShortDescription(session)}</span>
      </div>

      {/* Session test count */}
      {status === "success" && (
        <span tw="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs font-medium rounded-full">
          {data.totalCount}
        </span>
      )}
    </Link>
  );
};

// A session is considered a "past session" on week after it ended
const limitBetweenPastAndUpcomingSession = getLimitDatePastSession();

const UpcomingSessionNavLinkList = (props) => {
  // Fetch the 3 upcoming sessions
  const { status, data } = useSessionsQuery({
    limit: 3,
    sort: "from",
    ...Filter.from({
      to: { $gte: limitBetweenPastAndUpcomingSession },
    }),
  });

  return (
    <>
      <div tw="mt-8" {...props}>
        <SidebarHeading>Upcoming sessions</SidebarHeading>
        <div tw="mt-2 space-y-1">
          {status === "success" &&
            data.list.map((session) => (
              <SessionNavLink
                key={session.oid}
                session={session}
                to={`/tire-tests?session=${session.oid}`}
              />
            ))}
        </div>
      </div>
    </>
  );
};

const PastSessionNavLinkList = (props) => {
  // Fetch the 3 upcoming sessions
  const { status, data } = useSessionsQuery({
    limit: 3,
    sort: "-from",
    ...Filter.from({
      to: { $lt: limitBetweenPastAndUpcomingSession },
    }),
  });

  return (
    <>
      {status === "success" && data.totalCount !== 0 && (
        <div tw="mt-8" {...props}>
          <SidebarHeading>Past sessions</SidebarHeading>
          <div tw="mt-2 space-y-1">
            {status === "success" &&
              data.list.map((session) => (
                <SessionNavLink
                  key={session.oid}
                  session={session}
                  to={`/tire-tests?session=${session.oid}`}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const openSidebar = useCallback(() => setIsSidebarOpen(true), []);
  const closeSidebar = useCallback(() => setIsSidebarOpen(false), []);

  return (
    <>
      <SkipNavLink>Skip to content</SkipNavLink>
      {/* Sidebar, navbar and content */}
      <div tw="h-screen flex overflow-hidden bg-white">
        <Sidebar isOpen={isSidebarOpen} onDismiss={closeSidebar} header={<SidebarHeader />}>
          <div tw="space-y-1">
            <SidebarNavLink to="/" Icon={HomeIcon} exact>
              Dashboard
            </SidebarNavLink>
            <SidebarNavLink to="/tire-test-search" Icon={SearchIcon} exact>
              Search
            </SidebarNavLink>
          </div>

          <UpcomingSessionNavLinkList />

          <PastSessionNavLinkList />

          <div tw="mt-8">
            <SidebarHeading id="tire-headline">Risks analysis</SidebarHeading>
            <div tw="mt-2 space-y-1" role="group" aria-labelledby="tire-headline">
              <SidebarNavLink to="/risks-analysis" Icon={PresentationChartLineIcon}>
                Analysis
              </SidebarNavLink>
              <SidebarNavLink to="/risk-analysis-search" Icon={SearchIcon} exact>
                Search risk analysis
              </SidebarNavLink>
            </div>
          </div>

          <div tw="mt-8">
            <SidebarHeading id="tire-headline">Tire management</SidebarHeading>
            <div tw="mt-2 space-y-1" role="group" aria-labelledby="tire-headline">
              <SidebarNavLink to="/dtos" Icon={ChartBarIcon}>
                DTOs
              </SidebarNavLink>
              <SidebarNavLink to="/tires" Icon={TireOutlineIcon}>
                Serial numbers
              </SidebarNavLink>
              <SidebarNavLink to="/rims" Icon={RimOutlineIcon}>
                RIMs
              </SidebarNavLink>
            </div>
          </div>

          <div tw="mt-8">
            <SidebarHeading id="repositories-headline">Tire Test Repositories</SidebarHeading>
            <div tw="mt-2 space-y-1" role="group" aria-labelledby="repositories-headline">
              <SidebarNavLink to="/dxps" Icon={DocumentReportIcon}>
                DXP files
              </SidebarNavLink>
              <SidebarNavLink to="/sessions" Icon={CalendarIcon}>
                Sessions
              </SidebarNavLink>
              {repositories.map(
                (repository) =>
                  repository.type === "tireTest" && (
                    <SidebarNavLink
                      key={repository.key}
                      to={`/repositories/${repository.key}`}
                      Icon={repository.Icon}
                    >
                      {repository.name}
                    </SidebarNavLink>
                  )
              )}
              <SidebarNavLink to="/circuits" Icon={LocationMarkerIcon}>
                Circuits
              </SidebarNavLink>
              <SidebarNavLink to="/defects" Icon={XCircleIcon}>
                Defects
              </SidebarNavLink>
            </div>
          </div>

          <div tw="mt-8">
            <SidebarHeading id="tire-headline">Risks analysis Repositories</SidebarHeading>
            <div tw="mt-2 space-y-1" role="group" aria-labelledby="tire-headline">
              <SidebarNavLink to="/types-risk-analysis" Icon={LocationMarkerIcon}>
                Analysis types
              </SidebarNavLink>
              {repositories.map(
                (repository) =>
                  repository.type === "riskAnalysis" && (
                    <SidebarNavLink
                      key={repository.key}
                      to={`/repositories/${repository.key}`}
                      Icon={repository.Icon}
                    >
                      {repository.name}
                    </SidebarNavLink>
                  )
              )}
            </div>
          </div>
        </Sidebar>

        {/* Navbar & content */}
        <div tw="flex flex-col w-0 flex-1 overflow-hidden bg-gray-100">
          <Navbar
            start={<OpenSidebarButton tw="md:hidden" onClick={openSidebar} />}
            center={<GlobalSearchBar />}
            end={<ProfileDropdown />}
          />
          {children}
        </div>
      </div>
    </>
  );
};
