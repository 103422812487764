/** @jsxImportSource @emotion/react */
import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { animated, useTransition } from "react-spring";
import "twin.macro";
import { Button } from "./Button";

const AnimatedDialogContent = animated(DialogContent);

export const Modal = ({ isOpen, onDismiss, children, ...props }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: "translate3d(0, 1rem, 0) scale(0.95)" },
    enter: { opacity: 1, transform: "translate3d(0, 0rem, 0) scale(1.0)" },
    leave: { opacity: 0, transform: "translate3d(0, 1rem, 0) scale(0.95)" },
    config: { tension: 500 },
  });
  return transitions.map(
    ({ item, key, props: styles }) =>
      item && (
        <DialogOverlay
          key={key}
          tw="fixed z-10 inset-0 overflow-y-auto bg-transparent"
          onDismiss={onDismiss}
        >
          <div tw="flex items-end justify-center min-h-screen pt-20 text-center sm:block sm:p-0">
            {/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
            <animated.div tw="fixed inset-0" style={{ opacity: styles.opacity }}>
              <div tw="absolute inset-0 bg-gray-500 opacity-75" />
            </animated.div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" />

            {/* Off-canvas menu, show/hide based on off-canvas menu state. */}
            <AnimatedDialogContent
              tw="inline-block align-bottom bg-white rounded-t-lg sm:rounded-lg px-4 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 m-0 w-full"
              style={styles}
              {...props}
            >
              {children}
            </AnimatedDialogContent>
          </div>
        </DialogOverlay>
      )
  );
};

export const ModalActions = (props) => {
  return (
    <div
      tw="flex flex-col mt-5 space-y-3 sm:(mt-4 flex-row-reverse space-y-0 space-x-3 space-x-reverse)"
      {...props}
    />
  );
};

export const ModalButton = ({ as: Component = Button, ...props }) => {
  return <Component tw="w-full justify-center sm:w-auto" {...props} />;
};
