/** @jsxImportSource @emotion/react */
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { Redirect } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "../shared/Button";
import { FieldHelperText, FormGroup, Input, Label } from "../shared/Form";
import { Panel, PanelContent, PanelFooter } from "../shared/Panel";
import { useFetchRIMs, useUpsertRIMMutation } from "./RimQueries";

const RIMFormFields = (props) => {
  const { register } = useFormContext();
  const fetchRIMs = useFetchRIMs();
  const id = useWatch({ name: "_id" });
  return (
    <div tw="grid grid-cols-1 gap-6" {...props}>
      <input type="hidden" name="_id" ref={register()} />
      <input type="hidden" name="status" ref={register()} defaultValue="active" />
      <FormGroup name="value">
        <Label>RIM</Label>
        <Input
          placeholder="99J99"
          rules={{
            required: "This field is required",
            validate: async (value) => {
              const rims = await fetchRIMs({
                value,
                ...(id && { _id: { $ne: id } }),
              });
              return rims.length === 0 ? null : "This RIM already exists";
            },
          }}
        />
        <FieldHelperText />
      </FormGroup>
    </div>
  );
};

/**
 * RIM creation or update form wrapped within a Panel.
 * If the given rim has an `oid`, the rim will be created, otherwise, it will be updated.
 * @type {React.FC<{ rim?: import("./RimQueries").RIM }>}
 */
export const RIMForm = ({ rim = {} }) => {
  const formProps = useForm({
    defaultValues: rim,
  });
  const { mutateAsync: upsertRIM, status, data } = useUpsertRIMMutation();
  const { handleSubmit } = formProps;

  return (
    <FormProvider {...formProps}>
      {data && <Redirect to="/rims" />}
      <form onSubmit={handleSubmit(upsertRIM)}>
        <Panel>
          <PanelContent>
            <RIMFormFields tw="sm:grid-cols-3" />
          </PanelContent>
          <PanelFooter>
            <PrimaryButton type="submit" disabled={status === "loading"}>
              Save
            </PrimaryButton>
          </PanelFooter>
        </Panel>
      </form>
    </FormProvider>
  );
};
