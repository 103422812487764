import { useController } from "react-hook-form";
import Select from "react-select";
import { useAutocompleteFormStyles } from "./Autocomplete";
import { useFormName } from "./Form";

export const CustomSelect = ({ defaultValue = "", options, onChangeCallback, disabled, rules, onFocus, ...props }) => {
  const name = useFormName();

  const styles = useAutocompleteFormStyles();

  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  const selectedOptions = value ? { value, label: value } : null;

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : null);

    if (onChangeCallback) {
      onChangeCallback(selectedOption ? selectedOption.value : null);
    }
  };
  
  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={selectedOptions}
        isDisabled={disabled}
        onChange={handleChange}
        styles={styles}
        isSearchable={false}
        options={options}
        {...controllerProps}
        {...props}
      />
    </>
  );
};
