import { QueryClient } from "react-query";
import { AppProvider } from "./app-providers/AppProvider";
import "./app.css";
import { NotificationLiveRegion } from "./notifications/NotificationLiveRegion";
import { Routes } from "./Routes";

export const queryClient = new QueryClient({
  defaultConfig: {
    queries: {
      retry: false,
    },
  },
});

const App = () => {
  return (
    <AppProvider>
      <Routes />
      <NotificationLiveRegion />
    </AppProvider>
  );
};

export default App;
