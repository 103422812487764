/** @jsxImportSource @emotion/react */
import { FormProvider, useController, useForm, useFormContext, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { metaDefectsCategories, useDefectsQuery } from "../defects/DefectQueries";
import FileInput from "../files/FileInput";
import { Badge, BadgeDot } from "../shared/Badge";
import { Button, PrimaryButton } from "../shared/Button";
import { DenseDescriptionList } from "../shared/DescriptionList";
import {
  Checkbox,
  CheckboxHelperText,
  CheckboxList,
  FieldHelperText,
  FieldsetLegend,
  FormGroup,
  HelperText,
  InlineFormGroup,
  Label,
  NumberInput,
  OptionalLabel,
  Textarea,
  useFormId,
  useFormName,
} from "../shared/Form";
import { ExternalLinkSolidIcon } from "../shared/Icons";
import { Panel, PanelContent } from "../shared/Panel";
import { Filter } from "../shared/QueryHelpers";
import { Slider, SliderHandle, SliderMarker, SliderTooltip } from "../shared/Slider";
import { Spinner } from "../shared/Spinner";
import {
  TireTestMachinePosition,
  TireTestSerialNumber,
  TireTestStintCompletionStageCount,
} from "./TireTestDescriptionTerms";
import { TIRE_TESTS, useUpsertTireTestMutation } from "./TireTestQueries";
import { empty } from "../risks-analysis/RiskAnalysisDescriptionTerms";
import { UnitFormatter } from "./UnitFormatters";

export const CalculWeightBeforeMinusAfter = ({ tireTest, ...props }) => {
  const weightDifference =
    !tireTest.weightBeforeTest || !tireTest.weightAfterTest
      ? undefined
      : tireTest.weightAfterTest - tireTest.weightBeforeTest;
  return (
    <p {...props}>
      {weightDifference === undefined ? (
        empty
      ) : (
        <UnitFormatter unit="kilogram" maximumFractionDigits={2} minimumFractionDigits={0}>
          {weightDifference}
        </UnitFormatter>
      )}
    </p>
  );
};

const ConditionSlider = ({ label, options }) => {
  const name = useFormName();
  const id = useFormId();

  const { field } = useController({
    name,
    defaultValue: options[0].value,
  });

  const valueLabel = options.find((option) => option.value === field.value)?.label;

  return (
    <>
      <div tw="flex items-center justify-between">
        <Label>{label}</Label>
        <span tw="text-sm text-gray-500">{valueLabel}</span>
      </div>
      <Slider id={id} min={1} max={4} {...field}>
        {options.map((option, index) => (
          <SliderMarker key={index} value={option.value}>
            <span tw="sr-only">{option.label}</span>
          </SliderMarker>
        ))}
        <SliderHandle>
          <SliderTooltip>{valueLabel}</SliderTooltip>
        </SliderHandle>
      </Slider>
    </>
  );
};

// isolate detail because it is link to checked property watched
const TireTestDefectDetail = ({ name, checked }) => {
  let isChecked = useWatch({ name: `${name}.checked` });

  return (
    <section
      tw="bg-gray-50 px-6 py-5 border-t border-gray-200"
      css={isChecked ? tw`block` : tw`hidden`}
    >
      <FormGroup name={`${name}.comments`}>
        <div tw="flex justify-between">
          <Label>Comments</Label>
          <OptionalLabel />
        </div>
        <Textarea rows={3} />
      </FormGroup>

      <FormGroup name={`${name}.files`} tw="mt-6">
        <Label>Files</Label>
        <FileInput />
      </FormGroup>
    </section>
  );
};

const TireTestDefect = ({ name, category, title, description, oid }) => {
  const { register } = useFormContext();

  return (
    <>
      <input type="hidden" name={`${name}.title`} ref={register()} defaultValue={title} />
      <input type="hidden" name={`${name}.oid`} ref={register()} defaultValue={oid} />

      <input
        type="hidden"
        name={`${name}.description`}
        ref={register()}
        defaultValue={description}
      />
      <InlineFormGroup name={`${name}.checked`} tw="py-4 px-4 sm:px-6 border-t border-gray-200">
        <Checkbox />
        <div>
          <Label tw="flex items-center space-x-2">
            <Badge color={metaDefectsCategories[category]}>
              <BadgeDot />
              {category}
            </Badge>
            <span>{title}</span>
            {description && <span tw="font-normal text-gray-400">{description}</span>}
          </Label>
        </div>
      </InlineFormGroup>

      <TireTestDefectDetail name={name} />
    </>
  );
};

/** @type {React.FC<{ tireTest: import("./TireTestQueries").TireTest }>} */
export const TestAnalysisForm = ({ tireTest }) => {
  const { status: statusDefects, data: defects } = useDefectsQuery({
    sort: "index",
    ...Filter.from({ status: "active" }),
  });

  const updatedIndexDefects = [];
  tireTest.defects.forEach((d) => {
    updatedIndexDefects[d.oid] = d;
  });

  const defaultValues = {
    ...tireTest,
    defects: updatedIndexDefects,
  };

  const formProps = useForm({ defaultValues: defaultValues });
  const { handleSubmit, getValues } = formProps;
  const { status, mutateAsync: upsertTireTest } = useUpsertTireTestMutation();

  const history = useHistory();
  const queryClient = useQueryClient();

  const onValid = async (data) => {
    const defectData = data.defects;
    data.defects = [];

    for (let d in defectData) {
      data.defects.push(defectData[d]);
    }
    await upsertTireTest(
      { _id: tireTest._id, ...data, isLocked: true },
      {
        onSuccess: () => {
          queryClient.removeQueries([TIRE_TESTS, tireTest._id]);
        },
      }
    );
    history.push(`/tire-tests/${tireTest._id}/synthesis`);
  };
  const onInvalid = async () => {
    await upsertTireTest({ _id: tireTest._id, ...getValues() });
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onValid, onInvalid)} tw="space-y-6">
        <Panel>
          <DenseDescriptionList>
            <TireTestSerialNumber tireTest={tireTest} />
            <TireTestMachinePosition tireTest={tireTest} />
            <TireTestStintCompletionStageCount tireTest={tireTest} />
          </DenseDescriptionList>
        </Panel>
        <Panel>
          <PanelContent>
            <fieldset>
              <FieldsetLegend>General aspect</FieldsetLegend>
              <HelperText>
                General blister and wear level of the tire after the test and before its dissection.
              </HelperText>

              <div tw="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                <FormGroup name="weightAfterTest">
                  <div tw="flex justify-between">
                    <Label>Weight after test</Label>
                  </div>
                  <NumberInput />
                  <FieldHelperText />
                </FormGroup>
                <div>
                  <p tw="block text-sm font-medium text-gray-700 whitespace-nowrap truncate">
                    Weight difference
                  </p>
                  <CalculWeightBeforeMinusAfter tireTest={tireTest} tw="mt-2 text-sm" />
                </div>
                <FormGroup name="blister">
                  <ConditionSlider
                    label="Blister"
                    options={[
                      { value: 1, label: "None" },
                      { value: 2, label: "Some" },
                      { value: 3, label: "Many" },
                      { value: 4, label: "Plenty" },
                    ]}
                  />
                </FormGroup>
                <FormGroup name="wearOutboard">
                  <ConditionSlider
                    label="Wear: Outboard"
                    options={[
                      { value: 1, label: "None" },
                      { value: 2, label: "Some" },
                      { value: 3, label: "Many" },
                      { value: 4, label: "Plenty" },
                    ]}
                  />
                </FormGroup>
                <FormGroup name="wearCenter">
                  <ConditionSlider
                    label="Wear: Center"
                    options={[
                      { value: 1, label: "None" },
                      { value: 2, label: "Some" },
                      { value: 3, label: "Many" },
                      { value: 4, label: "Plenty" },
                    ]}
                  />
                </FormGroup>
                <FormGroup name="wearInboard">
                  <ConditionSlider
                    label="Wear: Inboard"
                    options={[
                      { value: 1, label: "None" },
                      { value: 2, label: "Some" },
                      { value: 3, label: "Many" },
                      { value: 4, label: "Plenty" },
                    ]}
                  />
                </FormGroup>
              </div>
            </fieldset>
          </PanelContent>
        </Panel>

        <Panel>
          <PanelContent>
            <fieldset>
              <FieldsetLegend>Dissection results</FieldsetLegend>
              <HelperText>General information on the tire dissection.</HelperText>

              <CheckboxList tw="mt-6">
                <InlineFormGroup name="nothingToDeclare">
                  <Checkbox />
                  <div>
                    <Label>RAS</Label>
                    <CheckboxHelperText>No defect to declare</CheckboxHelperText>
                  </div>
                </InlineFormGroup>
              </CheckboxList>

              <FormGroup name="files" tw="mt-6">
                <Label>Files</Label>
                <FileInput />
              </FormGroup>

              <FormGroup name="testAnalysisComments" tw="mt-6">
                <div tw="flex justify-between">
                  <Label>Comments</Label>
                  <OptionalLabel />
                </div>
                <Textarea rows={3} />
              </FormGroup>
            </fieldset>
          </PanelContent>
        </Panel>

        <Panel tw="overflow-hidden">
          <fieldset>
            <header tw="py-4 px-4 sm:px-6 flex items-center justify-between space-x-3">
              <div>
                <FieldsetLegend>Defects</FieldsetLegend>
                <HelperText>Defects detected on the tire after its dissection.</HelperText>
              </div>
              {/* External link to defect documentation */}
              <a
                tw="flex items-center space-x-1 text-gray-500 hocus:text-gray-700"
                href="http://lnx000136.aze.michelin.com/dov/"
                target="_blank"
                rel="noreferrer"
              >
                <span tw="text-sm">DOVE defect documentation</span>
                <ExternalLinkSolidIcon tw="w-5 h-5" />
              </a>
            </header>

            {statusDefects === "success" &&
              defects.list.map((defect, index) => (
                <TireTestDefect
                  key={defect.oid}
                  name={`defects.${defect.oid}`}
                  category={defect.category}
                  title={defect.title}
                  description={defect.description}
                  oid={defect.oid}
                  {...formProps}
                />
              ))}
          </fieldset>
        </Panel>

        <div tw="flex items-center justify-between space-x-3">
          <Button as={Link} to={`/tire-tests/${tireTest._id}/execution`}>
            Previous
          </Button>
          <PrimaryButton type="submit" disabled={status === "loading"}>
            {status === "loading" && <Spinner tw="-ml-1 mr-3 h-5 w-5" />}
            {status === "loading" ? "Saving..." : "Complete"}
          </PrimaryButton>
        </div>
      </form>
    </FormProvider>
  );
};
