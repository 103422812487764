/** @jsxImportSource @emotion/react */
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, DocumentAddIcon } from "@heroicons/react/solid";
import { useId } from "@reach/auto-id";
import { useState } from "react";
import { useMutation } from "react-query";
import "twin.macro";
import { useUpsertWithoutReplaceDTOMutation } from "../dtos/DTOQueries";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MenuItem } from "../shared/Menu";
import { useFetchTireBySerialNumber, useUpsertTireMutation } from "../tires/TireQueries";

const rimDimensions = {
  "20/61-17": "8J17",
  "24/61-17": "9J17",
  "28/64-17": "11J17",
  "27/65-18": "10J18",
  "24/64-18": "9J18",
  "27/68-18": "11J18",
  "28/71-18": "12J18",
  "30/68-18": "12.5J18",
  "31/71-18": "13J18",
  "33/68-18": "12J18",
  "34/71-18": "14J18",
  "36/71-18": "14.5J18",
  "37/71-18": "14.5J18",
};

const getRimByDimension = (dimension, dto) => {
  // particular case: dimension 30/68-18 and dto starts by E419 => 12J18
  if (dimension === "30/68-18" && dto.startsWith("E419")) {
    return "12J18";
  }
  return rimDimensions[dimension];
};

export const ImportTiresFromXSLX = ({ replace }) => {
  const [dimensionFormatError, setDimensionFormatError] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const mustReplace = replace;

  const ExcelJS = require("exceljs");
  const { mutateAsync: upsertTire } = useUpsertTireMutation();
  const { mutateAsync: upsertWithoutReplaceDTO } = useUpsertWithoutReplaceDTOMutation();
  const fetchTireBySerialNumber = useFetchTireBySerialNumber();

  const importTire = async (tire) => {
    try {
      const existingTire = await fetchTireBySerialNumber(tire.serialNumber);
      if (!existingTire) {
        await upsertTire(tire);
        return "CREATED";
      } else {
        return "EXISTING_TIRE";
      }
    } catch (error) {
      console.error(`Failed to insert tire`, tire, error);
      return "FAILED";
    }
  };

  const importOrReplaceTire = async (tire) => {
    try {
      const existingTire = await fetchTireBySerialNumber(tire.serialNumber);
      if (existingTire) {
        await upsertTire({ _id: existingTire._id, ...tire });
        return "REPLACED";
      } else {
        await upsertTire(tire);
        return "CREATED";
      }
    } catch (error) {
      console.error(`Failed to insert/replace tire`, tire, error);
      return "FAILED";
    }
  };

  const { mutateAsync: importFile, status } = useMutation(async (file) => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);
    const worksheet = workbook.worksheets[0]?.id
      ? workbook.getWorksheet(workbook.worksheets[0].id)
      : undefined;

    if (!worksheet) {
      return;
    }
    const rows = [];
    let insertionsCount = 0;
    let replacmentsCount = 0;
    let failedCount = 0;
    let existingCount = 0;
    let incompleteRowsCount = 0;

    worksheet.eachRow({ includeEmpty: true }, async (row, rowNumber) => {
      const tirf = row.getCell(1).value;
      const serialNumber = row.getCell(5).value;
      const dto = row.getCell(5).value?.substr(0, 8);
      const dimension = row.getCell(3).value;
      const rim = getRimByDimension(dimension, dto);

      if (rowNumber > 4 && tirf && serialNumber) {
        rows.push({
          tirf,
          dto,
          dimension,
          serialNumber,
          rim,
        });
      } else if (rowNumber > 4 && (!tirf || !serialNumber)) {
        incompleteRowsCount = incompleteRowsCount + 1;
      }
    });

    for (let row of rows) {
      const { tirf, dto: dtoValue, dimension, serialNumber, rim } = row;
      const dto = {
        value: dtoValue,
        tireDimension: dimension,
        tireRim: rim,
      };
      try {
        await upsertWithoutReplaceDTO(dto);
      } catch (error) {
        if (error.code === "BAD_FORMAT") {
          setDimensionFormatError(`Given : ${dto.tireDimension}`);
          return;
        } else {
          console.error(`Failed to insert DTO`, dto, error);
        }
      }

      const tire = {
        tirf,
        dto: dtoValue,
        serialNumber,
      };

      if (mustReplace) {
        const res = await importOrReplaceTire(tire);
        switch (res) {
          case "CREATED":
            insertionsCount += 1;
            break;
          case "REPLACED":
            replacmentsCount += 1;
            break;
          case "FAILED":
            failedCount += 1;
            break;
          default:
            break;
        }
      } else {
        const res = await importTire(tire);
        switch (res) {
          case "CREATED":
            insertionsCount += 1;
            break;
          case "FAILED":
            failedCount += 1;
            break;
          case "EXISTING_TIRE":
            existingCount += 1;
            break;
          default:
            break;
        }
      }
    }
    return {
      created: insertionsCount,
      replaced: replacmentsCount,
      failed: failedCount,
      existing: existingCount,
      incompleteRows: incompleteRowsCount,
    };
  });

  const id = useId();

  const handleImportFile = async (e) => {
    const file = e.target.files[0];
    const results = await importFile(file);
    setImportResult(results);
  };

  return (
    <>
      <input
        type="file"
        tw="sr-only"
        id={id}
        disabled={status === "loading" || status === "success"}
        onChange={handleImportFile}
      />
      <MenuItem
        as="label"
        tw="cursor-pointer"
        disabled={status === "loading" || status === "success"}
        htmlFor={id}
      >
        {status === "loading" ? (
          <LoadingIndicator />
        ) : dimensionFormatError ? (
          <ExclamationCircleIcon />
        ) : status === "success" ? (
          <CheckCircleIcon />
        ) : (
          <DocumentAddIcon />
        )}
        {dimensionFormatError
          ? "Failed to import tires"
          : status === "success"
          ? "Import completed"
          : "Import tires"}
      </MenuItem>
      {status === "success" && importResult && (
        <div tw="text-xs flex flex-1 flex-col w-full space-y-2 border-t pt-2">
          <p tw="text-sm font-medium text-gray-700">Import Summary:</p>
          {mustReplace ? (
            <ul tw="list-disc pl-4 text-xs text-gray-500">
              <li>Rows with missing TIRF or Serial Number: {importResult.incompleteRows}</li>
              <li>New tires added: {importResult.created}</li>
              <li>Replaced tires: {importResult.replaced}</li>
              <li>Tires failed to import: {importResult.failed}</li>
            </ul>
          ) : (
            <ul tw="list-disc pl-4 text-xs text-gray-500">
              <li>
                Rows with missing TIRF or Serial Number (not imported):{" "}
                {importResult.incompleteRows}
              </li>
              <li>Rows with existing Serial Number (not replaced): {importResult.existing}</li>
              <li>New tires added: {importResult.created}</li>
              <li>Tires failed to import: {importResult.failed}</li>
            </ul>
          )}
        </div>
      )}
      {dimensionFormatError && (
        <p tw="text-xs text-red-600">
          Invalid dimension in file: Expected: XXXX/XX-XX, {dimensionFormatError}
        </p>
      )}
    </>
  );
};
