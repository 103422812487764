// @ts-check

const formatRiskAnalysisTitle = (riskAnalysis) => {
  const parts = [
    riskAnalysis.title,
    riskAnalysis.developer?.name,
  ];
  return parts.map((part) => part || "n.c.").join(" ꞏ ");
};

const formatRiskAnalysisTitleWithoutDevName = (riskAnalysis) => {
  const parts = [
    riskAnalysis.title,
  ];
  return parts.map((part) => part || "n.c.").join(" ꞏ ");
};

const formatRiskAnalysisSubTitle = (riskAnalysis) => {
  const dimensions = riskAnalysis.dimensionsStudies;
  const parts = [];

  dimensions?.forEach((d) => {
    parts.push(`${d.dimension} ${d.spec ? ` ${d.spec}` : ""}`);
  });
  return parts.map((part) => part || "n.c.").join(" ꞏ ");
};

export const useRiskAnalysisTitleWithoutDevName = (riskAnalysis) => {
  return riskAnalysis ? formatRiskAnalysisTitleWithoutDevName(riskAnalysis) : null;
};

export const useRiskAnalysisTitle = (riskAnalysis) => {
  return riskAnalysis ? formatRiskAnalysisTitle(riskAnalysis) : null;
};

export const useRiskAnalysisSubTitle = (riskAnalysis) => {
  return riskAnalysis ? formatRiskAnalysisSubTitle(riskAnalysis) : null;
};
