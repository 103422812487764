/** @jsxImportSource @emotion/react */
import "twin.macro";

export const NotFoundIllustration = (props) => {
  return (
    <svg viewBox="0 0 1121 778" {...props}>
      <circle cx="212.6" cy="103" r="64" tw="fill-current text-indigo-300" />
      <path
        tw="fill-current text-gray-200"
        d="M524 343c0 151-90 204-201 204s-200-53-200-204S323 0 323 0s201 192 201 343z"
      />
      <path
        tw="fill-current text-gray-700"
        d="M316 524l2-127 86-156-85 137v-57l59-113-58 98 1-103 63-90-62 74 1-187-7 248 1-10-64-98 63 118-6 114-1-3-73-104 73 114v15h-1v1l-15 290h21l2-150 73-114-73 103z"
      />
      <path
        tw="fill-current text-gray-200"
        d="M1121 405c0 124-74 167-165 167s-164-43-164-167 164-281 164-281 165 157 165 281z"
      />
      <path
        tw="fill-current text-gray-700"
        d="M950 553l2-104 70-128-70 112 1-46 48-93-48 80 2-83 51-74-51 61 1-154-6 203 1-8-53-81 52 97-5 93v-2l-60-85 60 94-1 11v1l-12 237h16l2-122 61-93-61 84z"
      />
      <ellipse cx="554.6" cy="680.5" tw="fill-current text-gray-700" rx="554.6" ry="28" />
      <ellipse cx="892.4" cy="726.8" tw="fill-current text-gray-700" rx="95" ry="4.8" />
      <ellipse cx="548.7" cy="773.1" tw="fill-current text-gray-700" rx="95" ry="4.8" />
      <ellipse cx="287.9" cy="734.3" tw="fill-current text-gray-700" rx="217" ry="11" />
      <circle cx="97.1" cy="566.3" r="79" tw="fill-current text-gray-800" />
      <path tw="fill-current text-gray-800" d="M61 628h24l-1 43H60zM109 629h24l-1 43h-24z" />
      <ellipse
        cx="119.5"
        cy="732.6"
        tw="fill-current text-gray-800"
        rx="7.5"
        ry="20"
        transform="rotate(-89 69 722)"
      />
      <ellipse
        cx="167.6"
        cy="732.2"
        tw="fill-current text-gray-800"
        rx="7.5"
        ry="20"
        transform="rotate(-89 117 722)"
      />
      <circle cx="99.3" cy="546.3" r="27" tw="fill-current text-white" />
      <circle cx="99.3" cy="546.3" r="9" tw="fill-current text-gray-700" />
      <path
        tw="fill-current text-indigo-500"
        d="M21 492c-6-29 15-57 47-64s62 11 68 40-15 39-47 45-62 8-68-21z"
      />
      <path
        tw="fill-current text-indigo-500"
        d="M218 610c0 55-33 75-74 75h-2l-6-1c-36-2-65-22-65-74 0-53 68-120 73-125s74 70 74 125z"
      />
      <path
        tw="fill-current text-gray-700"
        d="M142 676l27-37-27 41v5l-6-1 3-55v-5l-27-42 27 38v1l3-42-23-43 23 36 2-87v69l23-27-23 32-1 38 21-36-21 41v21l31-50-31 57z"
      />
      <circle cx="712.5" cy="565.4" r="79" tw="fill-current text-gray-800" />
      <path
        tw="fill-current text-gray-800"
        d="M697 635l22-7 13 41-23 7zM743 621l23-7 12 41-23 7z"
      />
      <ellipse
        cx="767.9"
        cy="732"
        tw="fill-current text-gray-800"
        rx="20"
        ry="7.5"
        transform="rotate(-17 545 834)"
      />
      <ellipse
        cx="813.5"
        cy="716.9"
        tw="fill-current text-gray-800"
        rx="20"
        ry="7.5"
        transform="rotate(-17 590 819)"
      />
      <circle cx="708.5" cy="545.7" r="27" tw="fill-current text-white" />
      <circle cx="708.5" cy="545.7" r="9" tw="fill-current text-gray-700" />
      <path
        tw="fill-current text-indigo-500"
        d="M618 518c-15-26-4-59 24-75s63-9 77 17-2 41-30 57-57 26-71 1zM572 600c0 51-30 68-68 68h-7c-34-2-60-21-60-68 0-48 63-110 67-114v-1s68 65 68 115z"
      />
      <path
        tw="fill-current text-gray-700"
        d="M502 661l25-35-25 38v4h-5l2-50v-1l1-5-25-38 25 35v1l2-38-21-40 21 33 2-79v-1 63l21-25-21 31-1 34 20-33-20 38v19l28-46-28 52z"
      />
      <path
        tw="fill-current text-indigo-500"
        d="M836 621c0 55-33 75-74 75h-2l-6-1c-36-2-65-22-65-74 0-53 68-120 73-125s74 70 74 125z"
      />
      <path
        tw="fill-current text-gray-700"
        d="M760 687l27-37-27 41v5l-6-1 3-55v-5l-27-42 27 38v1l3-42-23-43 23 36 2-87v69l23-27-23 32-1 38 21-36-21 41v21l31-50-31 57z"
      />
      <ellipse
        cx="721.5"
        cy="656.8"
        tw="fill-current text-gray-800"
        rx="12.4"
        ry="39.5"
        transform="rotate(-65 653 658)"
      />
      <ellipse
        cx="112.5"
        cy="651.8"
        tw="fill-current text-gray-800"
        rx="12.4"
        ry="39.5"
        transform="rotate(-68 48 651)"
      />
    </svg>
  );
};

export const ServerDownIllustration = (props) => {
  return (
    <svg viewBox="0 0 1119.6 699" {...props}>
      <circle cx="292.6" cy="213" r="213" tw="fill-current text-gray-200" />
      <path tw="fill-current text-gray-800" d="M0 51.1c0 77.5 48.6 140.3 108.7 140.3" />
      <path
        tw="fill-current text-indigo-500"
        d="M108.7 191.4C108.7 113 163 49.6 230 49.6M39.4 58.2c0 73.6 31 133.2 69.3 133.2"
      />
      <path tw="fill-current text-gray-800" d="M108.7 191.4c0-100.2 62.7-181.2 140.2-181.2" />
      <path
        d="M136.8 211.3c-17.4.1-40.4-2.7-45-5.6-3.6-2.1-5-9.8-5.5-13.4h-.5s1 12.5 5.6 15.3 27.7 5.7 45.1 5.5c5 0 6.8-1.8 6.7-4.4-.7 1.6-2.6 2.6-6.4 2.6z"
        opacity=".2"
      />
      <ellipse cx="198.6" cy="424.5" tw="fill-current text-gray-700" rx="187" ry="25.4" />
      <ellipse cx="198.6" cy="424.5" opacity=".1" rx="157" ry="21.4" />
      <ellipse cx="836.6" cy="660.5" tw="fill-current text-gray-700" rx="283" ry="38.5" />
      <ellipse cx="310.6" cy="645.5" tw="fill-current text-gray-700" rx="170" ry="23.1" />
      <path
        fill="none"
        tw="stroke-current text-gray-800"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M462.6 626c90 23 263-30 282-90M309.6 259s130-36 138 80-107 149-17 172M184 537.3s39-10.8 41.5 24-32.2 44.8-5.1 51.7"
      />
      <path
        tw="fill-current text-gray-800"
        d="M778.7 563.2l-7.9 50.3s-38.8 20.6-11.5 21.2 155.7 0 155.7 0 24.9 0-14.5-21.8l-7.9-52.7z"
      />
      <path
        d="M753.8 634.2c6.2-5.5 17-11.3 17-11.3l7.9-50.2h114l7.8 49.6c9.2 5.1 14.9 9 18.2 12 5-1.1 10.6-5.4-18.2-21.4l-7.9-52.7-113.9 3-7.9 50.3s-32.6 17.3-17 20.7z"
        opacity=".1"
      />
      <rect
        width="513.3"
        height="357.5"
        x="578.4"
        y="212.7"
        tw="fill-current text-gray-800"
        rx="18"
      />
      <path tw="fill-current text-gray-700" d="M595.7 231.8h478.7v267.8H595.7z" />
      <circle cx="835.1" cy="223.3" r="3" tw="fill-current text-gray-200" />
      <path
        tw="fill-current text-gray-800"
        d="M1091.7 520.8v31.4a18 18 0 01-18 18H596.4a18 18 0 01-18-18v-31.4zM969 667.5v6H643v-4.8l.4-1.2 8.1-21.8h310.8l6.7 21.8zM1094.4 661.5c-.6 2.6-2.8 5.3-7.9 7.8-18.1 9-55.1-2.4-55.1-2.4s-28.5-4.9-28.5-17.6a22.7 22.7 0 012.5-1.5c7.7-4 33-14 78 .4a18.7 18.7 0 018.5 5.6c1.8 2.2 3.2 4.9 2.5 7.7z"
      />
      <path
        d="M1094.4 661.5c-22.2 8.6-42 9.2-62.4-5a44.7 44.7 0 00-26.6-8.7c7.7-4 33-14 78 .4a18.7 18.7 0 018.5 5.6c1.8 2.2 3.2 4.9 2.5 7.7z"
        opacity=".1"
      />
      <ellipse cx="1066.5" cy="654.1" tw="fill-current text-gray-200" rx="7.9" ry="2.4" />
      <circle cx="835.1" cy="545.7" r="11.5" tw="fill-current text-gray-200" />
      <path d="M969 667.5v6H643v-4.8l.4-1.2H969z" opacity=".1" />
      <path tw="fill-current text-gray-800" d="M108.6 159h208v242h-208z" />
      <path
        tw="fill-current text-gray-700"
        d="M87.6 135h250v86h-250zM87.6 237h250v86h-250zM87.6 339h250v86h-250z"
      />
      <path tw="fill-current text-indigo-500" d="M271.6 150h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-indigo-500" d="M294.6 150h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-indigo-500" d="M317.6 150h16v16h-16z" />
      <path tw="fill-current text-indigo-500" d="M271.6 251h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-indigo-500" d="M294.6 251h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-indigo-500" d="M317.6 251h16v16h-16z" />
      <path tw="fill-current text-indigo-500" d="M271.6 352h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-indigo-500" d="M294.6 352h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-indigo-500" d="M317.6 352h16v16h-16z" />
      <circle cx="316.6" cy="538" r="79" tw="fill-current text-gray-800" />
      <path tw="fill-current text-gray-800" d="M280.6 600h24v43h-24zM328.6 600h24v43h-24z" />
      <ellipse cx="300.6" cy="643.5" tw="fill-current text-gray-800" rx="20" ry="7.5" />
      <ellipse cx="348.6" cy="642.5" tw="fill-current text-gray-800" rx="20" ry="7.5" />
      <circle cx="318.6" cy="518" r="27" tw="fill-current text-white" />
      <circle cx="318.6" cy="518" r="9" tw="fill-current text-gray-700" />
      <path
        tw="fill-current text-indigo-500"
        d="M240 464.5c-6.4-28.5 14-57.4 45.5-64.4s62.3 10.4 68.7 39-14.6 39-46 46-61.8 8-68.2-20.6z"
      />
      <ellipse
        cx="417.2"
        cy="611.3"
        tw="fill-current text-gray-800"
        rx="39.5"
        ry="12.4"
        transform="rotate(-23.2 156.4 637.7)"
      />
      <ellipse
        cx="269.2"
        cy="664.3"
        tw="fill-current text-gray-800"
        rx="39.5"
        ry="12.4"
        transform="rotate(-23.2 8.4 690.7)"
      />
      <path
        tw="fill-current text-white"
        d="M362.6 561c0 7.7-19.9 23-42 23s-43-14.3-43-22 21-6 43-6 42-2.7 42 5z"
      />
    </svg>
  );
};
