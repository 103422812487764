/** @jsxImportSource @emotion/react */
import { ChevronUpIcon } from "@heroicons/react/outline";
import { useId } from "@reach/auto-id";
import { createContext, forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { get, useController, useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import "twin.macro";
import tw from "twin.macro";
import { ChevronDownSolidIcon, ExclamationCircleSolidIcon } from "./Icons";
import useOnclickOutside from "react-cool-onclickoutside";

const FormGroupContext = createContext(null);

/**
 * Form group component exposing a unique `id` using context to its child components.
 * This will also wrap children in a `div` element.
 * Child components can use the `useFormId()` hook to access the unique id value.
 * See `Label` and `Input` for usage example.
 * @type {React.FC<{ name: string } & React.HTMLAttributes<HTMLDivElement>>}
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Select field</Label>
 *   <Select name="selectField">
 *     <option>Option 1</option>
 *     <option>Option 2</option>
 *   </Select>
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <TextArea name="textareaField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Text field with helper text</Label>
 *   <Input name="textField" />
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 */
export const FormGroup = ({ name, children, ...props }) => {
  const id = useId();
  const context = useMemo(() => ({ id, name }), [id, name]);
  if (!name) {
    throw new Error(`FormGroup name prop is required`);
  }
  return (
    <FormGroupContext.Provider value={context}>
      <div tw="space-y-1" {...props}>
        {children}
      </div>
    </FormGroupContext.Provider>
  );
};

/**
 * Inline variant of `FormGroup` primarily used for checkbox and radio fields.
 * @type {typeof FormGroup}
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <Label>Checkbox field</Label>
 * </InlineFormGroup>
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <div>
 *     <Label>Checkbox field</Label>
 *     <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *   </div>
 * </InlineFormGroup>
 */
export const InlineFormGroup = (props) => {
  return <FormGroup tw="flex items-start space-y-0 space-x-3" {...props} />;
};

/**
 * Return the unique id associated to the current `FormGroup` or `InlineFormGroup`.
 * This value can be used to assign a `label` `htmlFor` prop or an `input` `id`.
 * @type {() => string}
 */
export const useFormId = () => {
  const context = useContext(FormGroupContext);
  if (context === null) {
    throw new Error("`useFormId` should be wrapped withing a `FormGroup`");
  }
  return context.id;
};

/**
 * Return the field name associated to the current `FormGroup` or `InlineFormGroup`.
 * This value can be used to retrieve the react hook form associated with the current field or
 * assign the field name attribute.
 * @type {() => string}
 */
export const useFormName = () => {
  const context = useContext(FormGroupContext);
  if (context === null) {
    throw new Error("`useFormName` should be wrapped withing a `FormGroup`");
  }
  return context.name;
};

/**
 * Retrieve the react hook form validation error for the current field.
 */
export const useFormError = () => {
  const { errors } = useFormContext();
  const name = useFormName();
  const error = get(errors, name);
  return error;
};

/**
 * A form label component. Automatically assigns the `htmlFor` prop using the id of the current `FormGroup`.
 * @type {React.FC<React.LabelHTMLAttributes<HTMLLabelElement>>}
 * @example
 * <FormGroup>
 *   <Label>A label</Label>
 *   <Input name="textField" />
 * </FormGroup>
 */
export const Label = (props) => {
  const id = useFormId();
  return (
    <label
      htmlFor={id}
      tw="block text-sm font-medium text-gray-700 whitespace-nowrap truncate"
      {...props}
    />
  );
};

/**
 * A form label component. Automatically assigns the `htmlFor` prop using the id of the current `FormGroup`.
 * @type {React.FC<React.LabelHTMLAttributes<HTMLLabelElement>>}
 * @example
 * <FormGroup>
 *   <Label>A label</Label>
 *   <Input name="textField" />
 * </FormGroup>
 */
const InputAddon = (props) => {
  return (
    <span
      tw="inline-flex items-center px-3 border border-gray-300 bg-gray-50 text-gray-500 text-sm whitespace-nowrap"
      {...props}
    />
  );
};

export const InputLeadingAddon = (props) => {
  return <InputAddon tw="rounded-l-md border-r-0" {...props} />;
};

export const InputTrailingAddon = (props) => {
  return <InputAddon tw="rounded-r-md border-l-0" {...props} />;
};

/**
 * Input component with optional trailing or leading addon.
 * @type {React.FC<React.InputHTMLAttributes<HTMLInputElement> & { leading: React.ReactNode, trailing: React.ReactNode  }>}
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" />
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" leading={<InputLeadingAddon>https://</InputLeadingAddon>} />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" trailing={<InputTrailingAddon>km / h</InputTrailingAddon>} />
 * </FormGroup>
 */
const InputBase = forwardRef(({ leading, trailing, ...props }, ref) => {
  const id = useFormId();
  const name = useFormName();
  const error = useFormError();
  return (
    <div tw="flex rounded-md shadow-sm">
      {leading}
      <div tw="relative w-full flex">
        <input
          type="text"
          ref={ref}
          id={id}
          name={name}
          tw="flex-1 block w-full text-sm z-0 focus:z-10 border-gray-300 focus:(ring-indigo-500 border-indigo-500) disabled:(bg-gray-50 text-gray-500)"
          css={[
            tw`rounded-none`,
            !leading && tw`rounded-l-md`,
            !trailing && tw`rounded-r-md`,
            error &&
              tw`pr-10 border-red-300 text-red-900 placeholder-red-300 focus:(outline-none ring-red-500 border-red-500)`,
          ]}
          aria-invalid={error ? "true" : "false"}
          {...props}
        />
        {error && (
          <div tw="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-10">
            <ExclamationCircleSolidIcon tw="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
      {trailing}
    </div>
  );
});

export const Input = ({ rules, ...props }) => {
  const { register } = useFormContext();
  return <InputBase ref={register(rules)} {...props} />;
};

export const DateInput = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();
  // Format ISO8601 date time (e.g.: 2020-01-01T00:00:00.000Z) as local date (e.g.: 2020-01-01)
  // This will allow <Input type="date" /> to correctly display the date if it receives a ISO8601 date

  const {
    field: { value, onChange, ...controllerProps },
  } = useController({ name, defaultValue, rules, onFocus });
  return (
    <InputBase
      type="date"
      value={value ? value.split("T")[0] : value}
      onChange={(e) => {
        const value = e.target.value;
        if (!value) {
          return onChange(value);
        }
        const date = new Date(value);
        // Check if the date is an invalid date
        if (isNaN(date)) {
          return onChange(value);
        }
        return onChange(date.toISOString());
      }}
      {...controllerProps}
      {...props}
    />
  );
};

export const NumberInput = ({ rules, ...props }) => {
  return (
    <Input
      type="number"
      step="any"
      rules={{ valueAsNumber: true, ...rules }}
      onWheel={(e) => {
        e.target.blur();
      }}
      {...props}
    />
  );
};
/**
 * Textarea component.
 * @type {React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>>}
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <Textarea name="textareaField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <Textarea name="textareaField" />
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 */
export const Textarea = ({ rules, ...props }) => {
  const id = useFormId();
  const name = useFormName();
  const error = useFormError();
  const { register } = useFormContext();
  return (
    <textarea
      ref={register(rules)}
      id={id}
      name={name}
      tw="shadow-sm block w-full focus:(ring-indigo-500 border-indigo-500) text-sm border-gray-300 rounded-md disabled:(bg-gray-50 text-gray-500)"
      css={[
        error &&
          tw`pr-10 border-red-300 text-red-900 placeholder-red-300 focus:(outline-none ring-red-500 border-red-500)`,
      ]}
      aria-invalid={error ? "true" : "false"}
      {...props}
    />
  );
};

/**
 * Select component.
 * @type {React.FC<React.SelectHTMLAttributes<HTMLSelectElement>>}
 * @example
 * <FormGroup>
 *   <Label>Select field</Label>
 *   <Select name="selectField">
 *     <option>Option 1</option>
 *     <option>Option 2</option>
 *   </Select>
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Select field</Label>
 *   <Select name="selectField">
 *     <option>Option 1</option>
 *     <option>Option 2</option>
 *   </Select>
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 */
export const Select = ({ rules, ...props }) => {
  const id = useFormId();
  const name = useFormName();
  const error = useFormError();
  const { register } = useFormContext();
  return (
    <select
      ref={register(rules)}
      id={id}
      name={name}
      tw="block focus:(ring-indigo-500 border-indigo-500) w-full shadow-sm text-sm border-gray-300 rounded-md"
      css={[
        error &&
          tw`pr-10 border-red-300 text-red-900 placeholder-red-300 focus:(outline-none ring-red-500 border-red-500)`,
      ]}
      aria-invalid={error ? "true" : "false"}
      {...props}
    />
  );
};

export const MultiSelect = ({ rules, ...props }) => {
  const { options } = props;
  const [optionsDisplayed, setOptionsDisplayed] = useState(options);
  const [displayDropDown, setDisplayDropDown] = useState(false);
  const ref = useOnclickOutside(() => {
    if (displayDropDown) {
      setDisplayDropDown(false);
    }
  });

  useEffect(() => {
    if (optionsDisplayed === undefined || optionsDisplayed?.length === 0) {
      setOptionsDisplayed(options);
    }
  }, [options, optionsDisplayed]);

  return (
    <div tw="w-full flex flex-col items-center mx-auto" ref={ref}>
      <div tw="w-full">
        <div tw="flex flex-col items-center relative">
          <div tw="w-full">
            <div
              tw="p-0.5 flex border border-gray-200 bg-white rounded shadow-sm"
              onClick={(e) => {
                setDisplayDropDown(!displayDropDown);
              }}
            >
              <div tw="flex flex-auto flex-wrap max-height[102px] overflow-y-auto">
                {optionsDisplayed
                  ?.filter((opt) => opt.selected)
                  .map((opt) => {
                    return (
                      <div tw="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-gray-700 bg-gray-100 border border-gray-300 ">
                        <div tw="text-xs font-normal leading-none max-w-full flex-initial">
                          {opt.label}
                        </div>
                        <div tw="flex flex-auto flex-row-reverse">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              const updateArr = [...optionsDisplayed];
                              const idx = options.findIndex((o) => o.user_id === opt.user_id);
                              updateArr[idx].selected = false;
                              setOptionsDisplayed(updateArr);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="100%"
                              height="100%"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              tw=" cursor-pointer hover:text-gray-400 rounded-full w-4 h-4 ml-2"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div tw="text-gray-300 w-8 py-1 px-2  border-l flex items-center border-gray-200">
                <button
                  type="button"
                  tw="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
                >
                  {displayDropDown ? (
                    <ChevronUpIcon tw="w-5 h-5 text-gray-400" />
                  ) : (
                    <ChevronDownSolidIcon tw="w-6 h-6 text-gray-400" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {displayDropDown && (
            <div tw="absolute top[104%] bg-white z-40 w-full left-0 rounded max-height[300px] overflow-y-auto border[0.5px solid] border-gray-200 shadow-lg">
              <div tw="flex flex-col w-full">
                {optionsDisplayed?.map((opt, idx) => {
                  return (
                    <div
                      tw="cursor-pointer w-full border-gray-100 rounded-t border-b hover:(bg-indigo-100)"
                      onClick={() => {
                        const updateArr = [...optionsDisplayed];
                        updateArr[idx].selected = !updateArr[idx].selected;
                        setOptionsDisplayed(updateArr);
                      }}
                    >
                      <div
                        tw="flex w-full items-center p-2 pl-2 border-transparent border-l-4 relative"
                        css={[opt.selected && tw`border-indigo-600`]}
                      >
                        <div tw="w-full items-center flex">
                          <div tw="mx-2 text-sm leading-6">{opt.label}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Legend component that can be used within a `fieldset`.
 * @type {React.FC<React.HTMLAttributes<HTMLLegendElement>>}
 * @example
 * <fieldset>
 *   <FieldsetLegend>The fieldset legend</FieldsetLegend>
 * </fieldset>
 */
export const FieldsetLegend = (props) => {
  return <legend tw="text-lg leading-6 font-medium text-gray-900" {...props} />;
};

/**
 * Helper text that can be used with a `FieldsetLegend`.
 * @type {React.FC<React.HTMLAttributes<HTMLParagraphElement>>}
 * @example
 * <fieldset>
 *   <FieldsetLegend>The fieldset legend</FieldsetLegend>
 *   <HelperText>Some more description</HelperText>
 * </fieldset>
 */
export const HelperText = (props) => {
  return <p tw="mt-1 max-w-2xl text-sm text-gray-500" {...props} />;
};

/**
 * Helper text that can be used with a field in a `FormGroup`.
 * If the current field has an error, will display this error instead.
 * If you only want to display the validation error, you can use it without a `children` prop (e.g.: `<FieldHelperText />`).
 * @type {typeof HelperText}
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <Textarea name="textareaField" />
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <Textarea name="textareaField" />
 *   <FieldHelperText />
 * </FormGroup>
 */
export const FieldHelperText = ({ children, ...props }) => {
  const error = useFormError();
  return (
    <>
      {error && (
        <HelperText tw="text-red-600" role="alert" {...props}>
          {error.message}
        </HelperText>
      )}
      {!error && children && <HelperText {...props}>{children}</HelperText>}
    </>
  );
};

/**
 * Checkbox component.
 * @type {React.FC<React.InputHTMLAttributes<HTMLInputElement>>}
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <Label>Checkbox field</Label>
 * </InlineFormGroup>
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <div>
 *     <Label>Checkbox field</Label>
 *     <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *   </div>
 * </InlineFormGroup>
 */
export const Checkbox = ({ rules, ...props }) => {
  const id = useFormId();
  const name = useFormName();
  const error = useFormError();
  const { register } = useFormContext();
  return (
    <div tw="flex items-center h-5">
      <input
        ref={register(rules)}
        id={id}
        name={name}
        type="checkbox"
        tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        aria-invalid={error ? "true" : "false"}
        {...props}
      />
    </div>
  );
};

/**
 * Checkbox list component that can be used when displaying a list of checkbox fields.
 * @type {React.FC<React.HTMLAttributes<HTMLDivElement>>}
 * @example
 * <CheckboxList>
 *   <InlineFormGroup>
 *     <Checkbox name="checkboxField1" />
 *     <div>
 *       <Label>Checkbox field 1</Label>
 *       <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *     </div>
 *   </InlineFormGroup>
 *   <InlineFormGroup>
 *     <Checkbox name="checkboxField2" />
 *     <div>
 *       <Label>Checkbox field 2</Label>
 *       <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *     </div>
 *   </InlineFormGroup>
 * <CheckboxList>
 */
export const CheckboxList = (props) => {
  return <div tw="space-y-4" {...props} />;
};

/**
 * Checkbox helper text to provide additional information on the checkbox usage.
 * @type {typeof HelperText}
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <div>
 *     <Label>Checkbox field</Label>
 *     <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *   </div>
 * </InlineFormGroup>
 */
export const CheckboxHelperText = (props) => {
  return <p tw="text-sm text-gray-500 max-w-xl" {...props} />;
};

/** @typedef {React.InputHTMLAttributes<HTMLInputElement> & import("react-input-mask").Props & import("react-hook-form").ControllerProps<HTMLInputElement>} MaskedInputProps */
/**
 * Masked input component based on [https://www.npmjs.com/package/react-input-mask].
 * FIXME: This library is not ideal as it causes the following error: "Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of InputElement which is inside StrictMode."
 * @type {React.FC<MaskedInputProps>}
 */
export const MaskedInput = ({
  defaultValue = "",
  onFocus,
  rules,
  mask,
  maskChar,
  formatChars,
  alwaysShowMask,
  beforeMaskedValueChange,
  ...props
}) => {
  const name = useFormName();
  const { field } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });
  return (
    <ReactInputMask
      mask={mask}
      maskChar={maskChar}
      formatChars={formatChars}
      alwaysShowMask={alwaysShowMask}
      beforeMaskedValueChange={beforeMaskedValueChange}
      {...field}
    >
      {(inputProps) => <InputBase {...props} {...inputProps} />}
    </ReactInputMask>
  );
};

export const OptionalLabel = () => {
  return <span tw="text-sm text-gray-500 truncate">Optional</span>;
};
