// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApi } from "../shared/useApi";

/** @typedef {("active" | "archived")} RIMStatus */

/**
 * @typedef RIMProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {RIMStatus} status
 * @property {string} value
 * @property {string} label
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & RIMProps} RIM */

export const RIMS = "rims";

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<RIM>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<RIM>>} */
export const useRIMsQuery = (searchParams = {}) => {
  const api = useApi();
  return useQuery([RIMS, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "value", ...query } = searchParams;
    const response = await api.get(`v1/rims`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {(rimId: string) => import("react-query").UseQueryResult<RIM>} */
export const useRIMByIdQuery = (rimId) => {
  const api = useApi();
  return useQuery([RIMS, rimId], async () => {
    return api.get(`v1/rims/${rimId}`).json();
  });
};

export const useFetchRIMs = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/rims`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {(value: string) => RIM} */
export const createRIM = (value) => {
  const sanitizedValue = value.trim();
  return {
    value: sanitizedValue,
    label: sanitizedValue,
    status: "active",
  };
};

/** @type {() => import("react-query").UseMutationResult<RIM[], unknown, RIM[]>} */
export const useUpsertManyRIMMutation = () => {
  const { mutateAsync: upsertRIM } = useUpsertRIMMutation();
  return useMutation(async (rims) => {
    return Promise.all(rims.map((rim) => upsertRIM(rim)));
  });
};

/** @type {() => import("react-query").UseMutationResult<RIM, unknown, RIM>} */
export const useUpsertRIMMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (rim) => {
      if (rim._id) {
        return api.patch(`v1/rims/${rim._id}`, { json: rim }).json();
      }
      return api.post(`v1/rims`, { json: rim }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RIMS);
      },
    }
  );
};
