/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import "twin.macro";
import { ImportDestroyedTiresFromXLSX } from "../import/ImportDestroyedTiresFromXLSX";
import { ImportForceAndMomentTiresFromXLSX } from "../import/ImportForceAndMomentTiresFromXLSX";
import { ImportSendBackTiresFromXLSX } from "../import/ImportSendBackTiresFromXLSX";
import { ImportTiresFromXSLX } from "../import/ImportTiresFromXSLX";
import {
  Checkbox,
  CheckboxHelperText,
  FormGroup,
  InlineFormGroup,
  Label,
  Select,
} from "../shared/Form";
import { Menu } from "../shared/Menu";
import { Modal } from "../shared/Modal";

const importOptions = [
  { value: "basic", label: "Import tires" },
  { value: "destroyed", label: "Import destroyed tires" },
  { value: "force-and-moment", label: "Import force and moments" },
  { value: "sent-back", label: "Import sent back tires" },
];

export const ImportTiresModal = ({ isOpen, onDismiss }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [mustReplace, setMustReplace] = useState(false);

  const formProps = useForm();

  const dismissModal = () => {
    setSelectedType(null);
    setMustReplace(false);
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={dismissModal} aria-label="Import tires modal">
      <FormProvider {...formProps}>
        <form onSubmit={() => {}} tw="space-y-6">
          <FormGroup name="importType">
            <div tw="flex justify-between">
              <Label>Import type</Label>
            </div>
            <Select rules={{ required: true }} onChange={(e) => setSelectedType(e.target.value)}>
              <option></option>
              {importOptions.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormGroup>

          {selectedType === "basic" && (
            <InlineFormGroup name="mustReplaceExisitingTires" tw="col-span-1 sm:col-span-full">
              <Checkbox
                onClick={() => (mustReplace ? setMustReplace(false) : setMustReplace(true))}
              />
              <div>
                <Label>Replace existing serial numbers</Label>
                <CheckboxHelperText>
                  Already existing serial number will be replaced by the ones given in the file.s
                </CheckboxHelperText>
              </div>
            </InlineFormGroup>
          )}

          <div tw="flex items-center justify-end space-x-3 mt-6">
            <Menu tw="rounded-md overflow-hidden flex flex-col flex-1 items-end justify-center">
              {selectedType === "destroyed" && <ImportDestroyedTiresFromXLSX />}
              {selectedType === "force-and-moment" && <ImportForceAndMomentTiresFromXLSX />}
              {selectedType === "sent-back" && <ImportSendBackTiresFromXLSX />}
              {selectedType === "basic" && <ImportTiresFromXSLX replace={mustReplace} />}
            </Menu>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
