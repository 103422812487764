/** @jsxImportSource @emotion/react */
import "twin.macro";
import { Container } from "./Container";
import { Page } from "./Page";

const Nothing = () => null;

export const EmptyStateIllustration = ({ as: Component = Nothing, ...props }) => {
  return <Component tw="mt-16 sm:mt-24 md:mt-32 max-w-sm mx-auto flex-shrink-0" {...props} />;
};

export const EmptyStateTitle = ({ as: Component = "h1", ...props }) => {
  return <Component tw="text-xl font-medium text-gray-900 text-center mt-16" {...props} />;
};

export const EmptyStateDescription = ({ as: Component = "p", ...props }) => {
  return <Component tw="max-w-lg text-sm text-gray-500 text-center mt-2" {...props} />;
};

export const EmptyState = ({ children }) => {
  return (
    <Page>
      <Container tw="py-6 flex flex-col items-center">{children}</Container>
    </Page>
  );
};
