/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "../shared/Button";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "../shared/EmptyState";
import { NotFoundIllustration } from "../shared/Illustrations";
import { SEO } from "../shared/SEO";

const NotFound = () => {
  return (
    <>
      <SEO title="404: Page not found" />
      <EmptyState>
        <EmptyStateIllustration as={NotFoundIllustration} />
        <EmptyStateTitle>404: Page not found</EmptyStateTitle>
        <EmptyStateDescription>
          The page you were trying to access cannot be found or you might not have permissions to
          see this page.
        </EmptyStateDescription>
        <PrimaryButton as={Link} to="/" tw="mt-8">
          Go back to dashboard
        </PrimaryButton>
      </EmptyState>
    </>
  );
};

export default NotFound;
