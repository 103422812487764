/** @jsxImportSource @emotion/react */
import "twin.macro";

// The empty placeholder if a number is not defined
const empty = <span tw="text-gray-300 text-sm font-normal">n.c.</span>;

/** @type {React.FC<Intl.NumberFormatOptions & { fallback?: JSX.Element, children: number }>} */
export const NumberFormatter = ({ children, fallback = empty, ...props }) => {
  const value = children;
  // Check that the value is a valid number
  if (value === undefined || value === null || Number.isNaN(value) || typeof value !== "number") {
    return fallback;
  }
  if (props.style === "unit" && ["newton", "percent", "bar"].includes(props.unit)) {
    // Handle unsupported units
    const { unit, style, ...options } = props;
    const formattedValue = new Intl.NumberFormat("fr", options).format(value);
    switch (unit) {
      case "newton":
        return `${formattedValue} N`;
      case "percent":
        return `${formattedValue}%`;
      default:
        return `${formattedValue} ${unit}`;
    }
  }
  return new Intl.NumberFormat("fr", props).format(value);
};

/** @type {typeof NumberFormatter} */
// eslint-disable-next-line react/style-prop-object
export const UnitFormatter = (props) => <NumberFormatter style="unit" {...props} />;

/** @type {typeof NumberFormatter} */
export const CamberFormatter = (props) => (
  <UnitFormatter maximumFractionDigits={2} minimumFractionDigits={2} unit="degree" {...props} />
);

/** @type {typeof NumberFormatter} */
export const VelocityFormatter = (props) => (
  <UnitFormatter
    maximumFractionDigits={0}
    minimumFractionDigits={0}
    unit="kilometer-per-hour"
    {...props}
  />
);

/** @type {typeof NumberFormatter} */
export const DistanceFormatter = (props) => <UnitFormatter unit="kilometer" {...props} />;

/** @type {typeof NumberFormatter} */
export const ForceFormatter = (props) => (
  <UnitFormatter maximumFractionDigits={0} minimumFractionDigits={0} unit="newton" {...props} />
);

/** @type {typeof NumberFormatter} */
export const PercentageFormatter = (props) => <UnitFormatter unit="percent" {...props} />;

export const PressureFormatter = (props) => (
  <UnitFormatter maximumFractionDigits={2} minimumFractionDigits={2} unit="bar" {...props} />
);
