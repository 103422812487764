/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";
import "twin.macro";
import NotFound from "../not-found/NotFound";
import { ErrorPage } from "../shared/ErrorPage";
import {
    Page,
    PageContent,
    PageHeader,
    PageSkeleton,
    PageTitle
} from "../shared/Page";
import { SEO } from "../shared/SEO";
import { RIMForm } from "./RIMForm";
import { useRIMByIdQuery } from "./RimQueries";

const RIMDetails = () => {
  const { rimId } = useParams();
  const { status, data: rim, error } = useRIMByIdQuery(rimId);

  return (
    <>
      {status === "loading" && <PageSkeleton />}
      {status === "error" && <ErrorPage error={error} />}
      {status === "success" && !rim && <NotFound />}
      {status === "success" && rim && (
        <Page>
          <SEO title={rim.value} />
          <PageHeader title={<PageTitle>{rim.value}</PageTitle>} />
          <PageContent>
            <RIMForm rim={rim} />
          </PageContent>
        </Page>
      )}
    </>
  );
};

export default RIMDetails;
