import { useState } from "react";
import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { CircuitCreationModal } from "../circuits/CircuitForm";
import { useCircuitsQuery } from "../circuits/CircuitQueries";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import { Filter } from "../shared/QueryHelpers";
import { useDisclosure } from "../shared/useDisclosure";

export const CircuitAutocomplete = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();
  // Load entries from the circuit collection
  const { isLoading, data } = useCircuitsQuery({ ...Filter.from({ status: "active" }) });

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  // Resolve option even when entries are not loaded from the circuit collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = data ? data.list.map((circuit) => ({ ...circuit, label: circuit.label })) : [];

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.label : null);

  // Circuit value passed to the creation modal
  const [createdCircuitValue, setCreatedCircuitValue] = useState("");
  // Modal state helper
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  // Pass the input value to the modal and open it
  const onCreateOption = (value) => {
    setCreatedCircuitValue(value);
    onOpen();
  };

  // Retrieve the value of the circuit created in the modal and close it
  const onOptionCreated = (circuit) => {
    onChange(circuit.label);
    onClose();
  };

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <>
      <BaseCreatableSelect
        inputId={inputId}
        value={selectedOption}
        onChange={handleChange}
        styles={styles}
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        onCreateOption={onCreateOption}
        {...controllerProps}
        {...props}
      />
      <CircuitCreationModal
        isOpen={isOpen}
        circuit={{ value: createdCircuitValue }}
        onDismiss={onClose}
        onCreated={onOptionCreated}
      />
    </>
  );
};
