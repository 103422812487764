/** @jsxImportSource @emotion/react */
import { Link, useLocation } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";

export const Tabs = ({ options, onClickCallback }) => {
  const location = useLocation();
  const currentValue = `${location.pathname}${location.search}`;

  return (
    <div tw="border-b border-gray-200 overflow-x-auto overflow-y-hidden">
      <nav tw="flex -mb-px space-x-8">
        {options.map((option) => (
          <Link
            key={option.to}
            onClick={() => {
              if (onClickCallback) {
                onClickCallback(option);
              }
            }}
            to={option.to}
            tw="whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm text-gray-500 hocus:text-gray-700 focus:outline-none"
            css={
              currentValue === option.to
                ? tw`border-indigo-500 hocus:border-indigo-500`
                : tw`border-transparent hocus:border-gray-300`
            }
          >
            <span tw="flex items-center space-x-2">
              <span>{option.label}</span>
              {option.count}
            </span>
          </Link>
        ))}
      </nav>
    </div>
  );
};
