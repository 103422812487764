import { useController } from "react-hook-form";
import BaseSelect from "react-select";
import tw from "twin.macro";
import { useFormError, useFormId, useFormName } from "./Form";

export const useAutocompleteFormStyles = (error) => {
  return {
    clearIndicator: (provided) => ({
      ...provided,
      ...tw`text-gray-400 hover:text-gray-500`,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...tw`text-gray-400 hover:text-gray-500`,
    }),
    menu: (provided) => ({
      ...provided,
      ...tw`mt-1 rounded-md bg-white shadow-lg`,
    }),
    menuList: (provided) => ({
      ...provided,
      ...tw`rounded-md py-1 ring-1 ring-black ring-opacity-5 text-sm`,
    }),
    option: (provided, state) => ({
      ...provided,
      ...tw`text-gray-900`,
      ...(state.isSelected && tw`text-white bg-indigo-600`),
    }),
    container: (provided) => ({
      ...provided,
      ...tw`rounded-md shadow-sm`,
    }),
    control: (provided, state) => ({
      ...provided,
      ...tw`rounded-md border border-gray-300 hover:border-gray-300 bg-white text-sm`,
      ...(state.isFocused &&
        tw`outline-none ring-1 ring-indigo-500 border-indigo-500 hover:border-indigo-500`),
      ...(error && tw`border-red-300 text-red-900 hover:border-red-300`),
      ...(error &&
        state.isFocused &&
        tw`outline-none ring-1 ring-red-500 border-red-500 hover:border-red-500`),
    }),
    placeholder: (provided) => ({
      ...provided,
      ...tw`text-gray-400`,
      ...(error && tw`text-red-300`),
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...tw`py-2 px-3 space-x-1`,
    }),
    input: (provided) => ({
      ...provided,
      ...tw`py-0 m-0`,
      "& > div > input": tw`focus:(ring-0 shadow-none outline-none ring-transparent) text-sm`,
    }),
    multiValue: (provided) => ({
      ...provided,
      ...tw`m-0`,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      ...tw`py-0 pl-2 pr-1`,
    }),
  };
};

export const useAutocompleteStyles = () => {
  const error = useFormError();
  return useAutocompleteFormStyles(error);
};

export const Autocomplete = ({ defaultValue = "", rules, onFocus, options, ...props }) => {
  const name = useFormName();
  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  // Resolve option
  const selectedOption = value ? options.find((option) => option.value === value) : null;

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.value : null);

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <BaseSelect
      inputId={inputId}
      name={name}
      value={selectedOption}
      onChange={handleChange}
      styles={styles}
      isClearable
      options={options}
      {...controllerProps}
      {...props}
    />
  );
};
