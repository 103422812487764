/** @jsxImportSource @emotion/react */
import { XIcon } from "@heroicons/react/outline";
import "twin.macro";

const SlideOver = ({ title, subtitle, closeCallback, ...props }) => {

    return (
    <div tw="fixed right-0 top-0 bottom-0 z-index[10] shadow-2xl width[25rem] min-width[18.5rem] h-full max-w-3xl border-r border-r-2 border-gray-200 bg-gray-50  overflow-x-hidden overflow-y-auto">
      <div tw="flex justify-between items-center bg-indigo-600">
        <div tw="ml-4 text-lg leading-6 font-medium text-gray-50">{title}</div>
        <div tw="ml-4 p-4 cursor-pointer" onClick={() => {
            if (closeCallback) {
              closeCallback();
            }
        }}>
            <XIcon tw="text-gray-100 w-6 h-6 hover:text-white"/>
        </div>
      </div>
      <div tw="pt-5 px-6 text-sm text-gray-600">{subtitle}</div>
      <div tw="p-6">
        {props.children}
      </div>
      
    </div>
  );
};

export default SlideOver;
