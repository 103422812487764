/* eslint-disable array-callback-return */
/** @jsxImportSource @emotion/react */
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import tw from "twin.macro";
import {
  DenseDescription,
  DenseDescriptionLabel,
  DenseDescriptionValue,
} from "../shared/DescriptionList";
import { formatDate } from "../shared/formatDate";
import {
  Table,
  TableBody,
  TableHead,
  TableHeading,
  TableRow,
  TableRowLabel,
} from "../shared/Table";
import { DimensionsTargets } from "./RiskContextForm";
import { FeedbackNotation } from "./RiskResultsForm";

// The empty placeholder if a number is not defined
export const empty = <span tw="text-gray-300 text-sm font-normal">n.c.</span>;

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisDeveloper = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Developer</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.developer?.name || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisProcessingLevel = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Processing level</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.level || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisExperts = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Experts</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {riskAnalysis.experts?.length > 0 ? (
          riskAnalysis.experts.map((expert, index) => (
            <span key={index}>
              {expert.name || empty}
              {index < riskAnalysis.experts.length - 1 && ", "}
            </span>
          ))
        ) : (
          <span>{empty}</span>
        )}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisDate = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Date</DenseDescriptionLabel>
      <DenseDescriptionValue>
        {riskAnalysis.date ? formatDate(riskAnalysis.date) : empty}
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisType = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Type</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.type || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisCategory = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Category</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.category || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisAnalysisCategory = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Analysis category</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.analysisCategory || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisAnalysisType = ({ riskAnalysis }) => {
  return (
    <DenseDescription>
      <DenseDescriptionLabel css={tw`mr-2`}>Analysis Type</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.analysisType || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisAnalysisComments = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Analysis comments</DenseDescriptionLabel>
      {riskAnalysis.analysisComments ? (
        <DenseDescriptionValue
          className="styledElement"
          css={tw`w-full`}
          dangerouslySetInnerHTML={{ __html: riskAnalysis.analysisComments }}
        ></DenseDescriptionValue>
      ) : (
        <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
          {empty}
        </DenseDescriptionValue>
      )}
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisDimensionsStudies = ({ riskAnalysis, ...props }) => {
  return (
    <>
      <DenseDescriptionLabel css={tw`mr-2`}>Dimensions to analyse</DenseDescriptionLabel>
      <div tw="col-span-2">
        <DimensionsTargets
          customCls="dimensionsTargetsSynthesis"
          riskAnalysisContextFormContext={riskAnalysis}
          customNoDimensionsMsg="There isn't any tire dimensions to analyse."
        />
      </div>
    </>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisTargets = ({ riskAnalysis, ...props }) => {
  return (
    <DenseDescription {...props}>
      <DenseDescriptionLabel css={tw`mr-2`}>Targets</DenseDescriptionLabel>
      <DenseDescriptionValue>
        <Table>
          <TableHead>
            <TableRow tw="bg-gray-50 block md:(display[table-row])">
              <TableHeading tw="w-1/5">Dimension</TableHeading>
              <TableHeading tw="w-1/5">Circuit</TableHeading>
              <TableHeading tw="w-1/5">Usage type</TableHeading>
              <TableHeading tw="w-1/5">Pressure</TableHeading>
              <TableHeading tw="w-1/5">Camber</TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {riskAnalysis.targets?.length > 0
              ? riskAnalysis.targets.map((target) => {
                  return (
                    <TableRow tw="block md:(display[table-row])">
                      <TableRowLabel>{target.dimension}</TableRowLabel>
                      <TableRowLabel>{target.circuit}</TableRowLabel>
                      <TableRowLabel>{target.usageType}</TableRowLabel>
                    </TableRow>
                  );
                })
              : { empty }}
          </TableBody>
        </Table>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisDimensionsReferences = ({ riskAnalysis }) => {
  const formatTitle = (title) => {
    switch (title) {
      case "externalGeometry":
        return "External Geometry";
      case "casing":
        return "Casing";
      case "rawMaterial":
        return "Raw Material";
      case "regulation":
        return "Regulation";
      case "process":
        return "Process";
      case "curing":
        return "Curing";
      case "other":
        return "Other";

      default:
        return "";
    }
  };
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Reference dimension</DenseDescriptionLabel>
      <DenseDescriptionValue css={tw`flex w-full`}>
        <span tw="bg-gray-100 rounded-md p-2">
          <span tw="w-full grid grid-cols-5 items-center" style={{ fontWeight: 600 }}>
            <span>{riskAnalysis.dimensionReference?.dimension || empty}</span>
            <span>{riskAnalysis.dimensionReference?.dto || empty}</span>
            <span>{riskAnalysis.dimensionReference?.cai || empty}</span>
            <span>{riskAnalysis.dimensionReference?.factory || empty}</span>
            <div tw="flex flex-col">
              {riskAnalysis.dimensionReference?.runReferences?.length > 0
                ? riskAnalysis.dimensionReference.runReferences.map((runRef, index) => {
                    return (
                      <span key={`runRef-${index}`} tw="overflow-x-hidden overflow-ellipsis">
                        {runRef.value}
                      </span>
                    );
                  })
                : empty}
            </div>
          </span>
          <div tw="px-2 py-0.5">
            {riskAnalysis.dimensionReference?.refDifferences.map((difference, indexDiff) => {
              if (difference.ref || difference.diff || difference.performanceImpacted) {
                return (
                  <div key={`${difference}-${indexDiff}`}>
                    <div tw="mt-2 rounded-md py-0.5 px-2 bg-gray-200" style={{ fontWeight: 600 }}>
                      {difference.title ? formatTitle(difference.title) : empty}
                    </div>
                    <div tw="px-1 pt-1">
                      <div>
                        <span style={{ fontWeight: 600 }} tw="text-indigo-700">
                          Ref:
                        </span>{" "}
                        {difference.ref || empty}
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }} tw="text-indigo-500">
                          Diff:
                        </span>{" "}
                        {difference.diff || empty}{" "}
                      </div>
                    </div>
                    <div tw="px-1">
                      <span style={{ fontWeight: 600 }} tw="text-indigo-700">
                        Perf:
                      </span>{" "}
                      {difference.performanceImpacted || empty}{" "}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </span>
      </DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisInitialSituation = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Initial situation</DenseDescriptionLabel>
      {riskAnalysis.initialSituation ? (
        <DenseDescriptionValue
          className="styledElement"
          css={tw`w-full`}
          dangerouslySetInnerHTML={{ __html: riskAnalysis.initialSituation }}
        ></DenseDescriptionValue>
      ) : (
        <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
          {empty}
        </DenseDescriptionValue>
      )}
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisRecommendations = ({ riskAnalysis }) => {
  return (
    <>
      <DenseDescriptionLabel css={tw`mr-2`}>Recommendations</DenseDescriptionLabel>
      <div css={tw`col-span-2 grid grid-cols-2 justify-between gap-4 w-full text-sm font-medium`}>
        {riskAnalysis.dimensionsStudies?.map((ds, index) => {
          return ds.targets?.map((t, indexTarget) => {
            return (
              <span
                tw="flex justify-between bg-gray-100 my-1 rounded-lg"
                key={`refDiff-${index}-${indexTarget}`}
              >
                <div tw="p-2 grid grid-cols-5 gap-2 w-full">
                  <div>Dim.</div>
                  <div>Circuit</div>
                  <div>Stints</div>
                  <div>Pressure</div>
                  <div>Camber</div>
                  <div tw="font-normal">{ds.dimension || empty}</div>
                  <div tw="font-normal overflow-x-hidden overflow-ellipsis">
                    {t.circuit || empty}
                  </div>
                  <div tw="font-normal">{t.stintNumber || empty}</div>
                  <div tw="font-normal">
                    {t.pressureRecommendation ? `${t.pressureRecommendation}` : empty}
                  </div>
                  <div tw="font-normal">
                    {t.camberRecommendation ? `${t.camberRecommendation}°` : empty}
                  </div>
                </div>
                <span tw="flex items-center justify-center text-xs text-white pr-2">
                  <span
                    tw="inline-flex items-center justify-center px-2 py-1 rounded-lg"
                    css={t.targetValidated ? tw`bg-green-800` : tw`bg-red-800`}
                  >
                    Target
                    {t.targetValidated ? (
                      <CheckIcon tw="rounded-full border[2px solid white] ml-1.5 padding[0.5px] h-5 w-5 text-white" />
                    ) : (
                      <XIcon tw="rounded-full border[2px solid white] ml-1 padding[0.5px] h-5 w-5 text-white" />
                    )}
                  </span>
                </span>
              </span>
            );
          });
        })}
      </div>
    </>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisDecision = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Decision comments</DenseDescriptionLabel>
      {riskAnalysis.decision ? (
        <DenseDescriptionValue
          className="styledElement"
          css={tw`w-full`}
          dangerouslySetInnerHTML={{ __html: riskAnalysis.decision }}
        ></DenseDescriptionValue>
      ) : (
        <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
          {empty}
        </DenseDescriptionValue>
      )}
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisActionsToEngage = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start mt-2`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Actions to engage</DenseDescriptionLabel>
      {riskAnalysis.actionsToEngage ? (
        <DenseDescriptionValue
          className="styledElement"
          css={tw`w-full`}
          dangerouslySetInnerHTML={{ __html: riskAnalysis.actionsToEngage }}
        ></DenseDescriptionValue>
      ) : (
        <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
          {empty}
        </DenseDescriptionValue>
      )}
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisIsRequestBlockage = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Request Blockage MSP/Q</DenseDescriptionLabel>
      <DenseDescriptionValue>{riskAnalysis.isRequestBlockage || empty}</DenseDescriptionValue>
    </DenseDescription>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisResultActions = ({ riskAnalysis }) => {
  return (
    <>
      <DenseDescription css={tw`justify-start`}>
        <DenseDescriptionLabel css={tw`mr-5`}>Notation</DenseDescriptionLabel>
        <DenseDescriptionValue>
          {riskAnalysis.feedbackNotation ? (
            <FeedbackNotation
              notation={riskAnalysis.feedbackNotation}
              selectedNotation={riskAnalysis.feedbackNotation}
              tw="h-4 w-4 pointer-events-none"
            />
          ) : (
            empty
          )}
        </DenseDescriptionValue>
      </DenseDescription>
      <DenseDescription css={tw`justify-between`}>
        <DenseDescriptionLabel css={tw`mr-2`}>Date</DenseDescriptionLabel>
        <DenseDescriptionValue>
          {riskAnalysis.feedbackDate ? formatDate(riskAnalysis.feedbackDate) : empty}
        </DenseDescriptionValue>
      </DenseDescription>
      <DenseDescription css={tw`col-span-2 justify-start`}>
        <DenseDescriptionLabel css={tw`mr-2`}>Feedback</DenseDescriptionLabel>
        {riskAnalysis.resultActions ? (
          <DenseDescriptionValue
            className="styledElement"
            css={tw`w-full`}
            dangerouslySetInnerHTML={{ __html: riskAnalysis.resultActions }}
          ></DenseDescriptionValue>
        ) : (
          <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
            {empty}
          </DenseDescriptionValue>
        )}
      </DenseDescription>
    </>
  );
};

/** @type {React.FC<{ riskAnalysis: import("./RiskAnalysisQueries").RiskAnalysis }>} */
export const RiskAnalysisMspActions = ({ riskAnalysis }) => {
  return (
    <DenseDescription css={tw`col-span-2 justify-start`}>
      <DenseDescriptionLabel css={tw`mr-2`}>Actions MSP/Q</DenseDescriptionLabel>
      {riskAnalysis.mspActions ? (
        <DenseDescriptionValue
          className="styledElement"
          css={tw`w-full`}
          dangerouslySetInnerHTML={{ __html: riskAnalysis.mspActions }}
        ></DenseDescriptionValue>
      ) : (
        <DenseDescriptionValue className="styledElement" css={tw`w-full`}>
          {empty}
        </DenseDescriptionValue>
      )}
    </DenseDescription>
  );
};
